import { SessionState } from "./state";

export enum AppMode { Normal = "Normal", ReadOnly = "ReadOnly" }

export enum Feature {
    Maps = "Maps",
    M1Editor = "M1Editor",
    Assets = "Assets",
    POIs = "POIs",
    Traffic = "Traffic",
    Licenses = "Licenses",
    Support = "Support",
    Settings = "Settings",
    DataSources = "DataSources",

    ShowMap = "ShowMap",
    ShowMapDetails = "ShowMapDetails",
    EditMap = "EditMap",
    DeleteMap = "DeleteMap",
    PublishMap = "PublishMap",

    UploadAssets = "UploadAssets",
    DeleteAssets = "DeleteAssets",
    UploadGlobalAssets = "UploadGlobalAssets",
    DeleteGlobalAssets = "DeleteGlobalAssets",

    LicenseAdmin = "LicenseAdmin",

    ShowCustomColorRanges = "ShowCustomColorRanges",
    ShowCustomLayers = "ShowCustomLayers",
}

type FeatureAllowDenyListMap = {
    [key: string]: {
        allow: Feature[];
        deny: Feature[];
    }
};

export class FeatureRoleManager {

    static MinimalFeaturesRole = "MINIMAL_FEATURES_FOR_ANY_USER";
    private static sessionState: SessionState;

    /**
     * User Role Based Access Parameters
     */
    static UserRole2Features: FeatureAllowDenyListMap = {
        /* --------------------------------------------------------------- */
        [FeatureRoleManager.MinimalFeaturesRole]: {
            allow: [
                Feature.Maps,
                Feature.Assets,
                Feature.Licenses,
                Feature.Support,

                Feature.ShowMap,
            ],
            deny: []
        },
        "PortalView": {
            // Allow all by default - deny features to not demo.
            allow: [
                Feature.Maps,
                Feature.M1Editor,
                Feature.Assets,
                Feature.POIs,
                Feature.Traffic,
                Feature.Licenses,
                Feature.Support,
                Feature.Settings,
                Feature.DataSources,

                Feature.ShowMap,
            ],
            deny: []
        },
        "PortalEdit": {
            allow: [
                Feature.Maps,
                Feature.M1Editor,
                Feature.Assets,
                Feature.Licenses,
                Feature.Support,

                Feature.ShowMap,
                Feature.ShowMapDetails,
                Feature.EditMap,
                Feature.DeleteMap,
                Feature.PublishMap,

                Feature.UploadAssets,
                Feature.DeleteAssets,
            ],
            deny: []
        },
        "PortalAdministrator": {
            // Allow all by default - deny features to not demo.
            allow: Object.values(Feature),
            deny: []
        },
    };

    /**
     * Appication Mode Based Access Parameters
     */
    static FeaturesAccessibleByMode: FeatureAllowDenyListMap = {
        [AppMode.ReadOnly]: {
            allow: [],
            deny: [
            ]
        },
        [AppMode.Normal]: {
            allow: [],
            deny: []
        }
    };

    static getFeaturesForRoles(userRoles: string[]): Feature[] {
        return this._getFeaturesForRoles(this.UserRole2Features, userRoles);
    }

    static _getFeaturesForRoles(featuresForGroupStructure: FeatureAllowDenyListMap, userRoles: string[]): Feature[] {

        // Always give minimal features 
        if (userRoles) {
            userRoles.push(this.MinimalFeaturesRole);
        } else {
            userRoles = [this.MinimalFeaturesRole];
        }

        // Construct feature set from groups memberships
        let featureSet: Feature[] = [];
        // phase 1: all allows
        userRoles && userRoles.forEach((gr, idx) => {
            let featuresforgroup = featuresForGroupStructure[gr];
            if (featuresforgroup) {
                featuresforgroup.allow.forEach((ft) => {
                    featureSet = this.addFeature(featureSet, ft);
                });
            }
        });
        // phase 2: all denys
        userRoles && userRoles.forEach((gr, idx) => {
            let featuresforgroup = featuresForGroupStructure[gr];
            if (featuresforgroup) {
                // remove any items in deny list
                featuresforgroup.deny && featuresforgroup.deny.forEach(element => {
                    featureSet = this.removeFeature(featureSet, element);
                });
            }
        });
        return featureSet;
    }

    static removeFeature(features: Feature[], removeFt: Feature): Feature[] {
        return features.filter((ft, idx) => {
            return ft !== removeFt;
        });
    }

    static addFeature(features: Feature[], addFt: Feature): Feature[] {
        // test for undefined to ensure that '0' values are also matched
        if (features.find((val) => val === addFt) === undefined) {
            features.push(addFt);
        }
        return features;
    }

    /**
     * Access check based on AppMode first - then user's role.
     * @param sessionInfo 
     * @param ft 
     */
    static shouldFeatureBeAvailable(ft: Feature): boolean {
        return this.userHasAccessToFeature(ft);
    }

    /**
     * Access Check Based on User's roles (only)
     * @param sessionInfo 
     * @param ft 
     */
    static userHasAccessToFeature(ft: Feature): boolean {
        let us = this.sessionState;
        if (us && us.featureSet) {
            for (var i = 0; i < us.featureSet.length; i++) {
                if (us.featureSet[i] === ft) { return true; }
            }
        }
        return false;
    }

    static setStaticSessionState(sessionState: SessionState) {
        this.sessionState = sessionState;
    }
}