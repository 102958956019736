import { useContext } from "react";
import * as React from "react";
import { useState } from "react";
import { Button, Modal, ModalTitle } from "react-bootstrap";

import { Localization } from '../managers/Localization';
import { SingleFeature } from "./SingleFeatureHelp";
import { SessionState } from "../states/sessionState/state";
import { UserHelpContext, newUserHelpViewed, releaseUserHelpViewed} from "../states/UserHelpState";

export type Props = {
  sessionInfo:SessionState
};

export function FeatureViewer(props:Props) {
  let {state, dispatch} = useContext(UserHelpContext);
  let [show, setShow] = useState(state.showFirstTimeHelp || state.showNewFeatureHelp);

  let [release, setRelease] = useState<number>(0);
  let [feature, setFeature] = useState<number>(0);

  function closeHandler() {

    if (state.showFirstTimeHelp) {
      dispatch(newUserHelpViewed());
      if (state.showNewFeatureHelp) {
        setRelease(0);
        setFeature(0);
        return;
      }
    }
    if (state.showNewFeatureHelp && state.releaseLog) {
      dispatch(releaseUserHelpViewed(state.releaseLog[0].releaseId));
    }
    setShow(false);
  }

  let viewLog = state.showFirstTimeHelp ? (state.helpLog || []) : state.releaseLog;

  function checkNextFeature() {
    let nFeature = feature + 1;
    if (nFeature >= viewLog[release].featureInfo.length) {
      return false;
    }
    return true;
  }

  function nextFeature() {
    let nFeature = feature + 1;
    if (nFeature >= viewLog[release].featureInfo.length) {
      if (release - 1 < 0) {
        closeHandler();
      }
      setRelease((rl) => rl-1);
      setFeature(0);
    } else {
      setFeature((ft) => ft+1);
    }
  }

  function checkPrevFeature() {
    let nFeature = feature - 1;
    if (nFeature < 0) {
      return false;
    }
    return true;
  }

  function prevFeature() {
    let nFeature = feature - 1;
    if (nFeature < 0) {
      closeHandler();
    } else {
      setFeature((ft) => ft-1);
    }
  }


  if ((release < 0) || (feature < 0)) {
    return (<></>);
  }
  if (show === false) {
    return (<></>);
  }

  let ShownRelease = viewLog[release];
  let ShownFeature = viewLog[release].featureInfo[feature];
 
  return (
    <div id="mit-featureviewer" className="small feature-dialog">
      <Modal show={true} style={{borderRadius: "0px", margin:"0px"}}>
        <Modal.Header>
          <ModalTitle>
            {state.showFirstTimeHelp ? Localization.getText("Welcome to Hvorlangterder") : Localization.getText("New Features")}
          </ModalTitle>
          {state.showFirstTimeHelp ? "" : <span>({ShownRelease.releaseId})</span>}
        </Modal.Header>
        <Modal.Body style={{textAlign:"left", minHeight:"60vh", maxHeight:"60vh", overflowY:"auto"}}>
          <SingleFeature 
            help={true}
            shownFeature={ShownFeature}
            sessionInfo={props.sessionInfo}
            maxImageHeight="40vh"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-default" size="sm" style={{marginRight:"auto"}} onClick={(e) => closeHandler()} >{Localization.getText("Skip")}</Button>
          {checkPrevFeature() ? <Button style={{minWidth:"101px"}} className="btn-default" size="sm" onClick={(e) => prevFeature()}>{Localization.getText("Previous Feature")}</Button>: null}
          <Button style={{minWidth:"101px"}} className="btn-primary" size="sm" onClick={(e) => nextFeature()}>
            {checkNextFeature() ? Localization.getText("Next Feature") : Localization.getText("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}