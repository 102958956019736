import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { SingleFeature, } from "./SingleFeatureHelp";
import { FeaturetoAccess, SessionInfo, SortFeatureInfoFunc } from "../managers/FeatureHelpManager";
import { Cookies } from '../managers/Cookies';
import { Localization } from '../managers/Localization';
import * as React from 'react';
import { UserHelpContext } from "../states/UserHelpState";

export type Props = {
  sessionInfo:SessionInfo,
  onClose: ()=>void,
  show: boolean,
  gettingStarted: boolean,
  expandRelease: (a:string | boolean) => void
};

export function ReleaseViewer(props:Props) {
  let {state, dispatch} = useContext(UserHelpContext);
  
  let [viewHelp, setViewHelp] = useState(false);
  
  let [release, setRelease] = useState<number>(0);
  let [feature, setFeature] = useState<number>(0);
   
  useEffect(() => {
    for (let i = 0; i < state.releaseLog.length; i++) {
      state.releaseLog[i].featureInfo = state.releaseLog[i].featureInfo
      .sort((x:any,y:any) => SortFeatureInfoFunc(props.sessionInfo, x,y));
    }
    
    let cookie = Cookies.getCookieBoolean("HelpToNewUserViewed");
    if (!cookie) {
      setViewHelp(true);
    }
  },
            []
  );
  
  function closeHandler() {
    let cookie = Cookies.getCookieBoolean("HelpToNewUserViewed");
    if (!cookie) {
      Cookies.setCookieBoolean("HelpToNewUserViewed",true);
    }
    if (props.onClose) {
      props.onClose();
    } 
  }
  
  function checkNextFeature() {
    let nFeature = feature + 1;
    if (nFeature >= state.releaseLog[release].featureInfo.length) {
      if (release - 1 < 0) {
        return false;
      }
    }
    return true;
  }
  
  function nextFeature() {
    let nFeature = feature + 1;
    if (nFeature >= state.releaseLog[release].featureInfo.length) {
      if (release - 1 < 0) {
        closeHandler();
      }
      setRelease((rl) => rl-1);
      setFeature(0);
    } else {
      setFeature((ft) => ft+1);
    }
  }
  
  function checkPrevFeature() {
    let nFeature = feature - 1;
    if (nFeature < 0) {
      if (release + 1 >= state.releaseLog.length) {
        return false;
      }
    }
    return true;
  }
  
  function prevFeature() {
    let nFeature = feature - 1;
    if (nFeature < 0) {
      if (release + 1 >= state.releaseLog.length) {
        closeHandler();
      }
      setFeature(state.releaseLog[release + 1].featureInfo.length-1);
      setRelease((rl) => rl+1);
    } else {
      setFeature((ft) => ft-1);
    }
  }  
  
  if ((release < 0) || (feature < 0)) {
    return (<></>);
  }
  if (props.show === false) {
    return (<></>);
  }
  
  let viewLog = props.gettingStarted ? state.helpLog : state.releaseLog;
  let access = 0;
  if (state.releaseLog[release].featureInfo[feature].licenseFeature) {
    access = 1;
    let licenseFeature =  state.releaseLog[release].featureInfo[feature].licenseFeature || null;
    if (licenseFeature && FeaturetoAccess(props.sessionInfo, licenseFeature)) {
      access = 0;
    }
  }
  
  
  return (<>
    <Modal show={true} style={{borderRadius: "0px", margin:"0px"}}>
    <Modal.Header>
    <Modal.Title>
    {props.gettingStarted ? Localization.getText("Getting Started") : Localization.getText("Releases")}
    {/* {state.releaseLog[release].featureInfo[feature].dk.title} */}
    </Modal.Title>
    </Modal.Header>
    <Modal.Body style={{textAlign:"left", maxHeight:"80vh", overflowY:"auto", paddingTop:"0px"}} >
      {viewLog.map((a:{releaseId:any}):any => {
        return (
          <div key={a.releaseId}>
            <SingleRelease
              help={props.gettingStarted}
              ExpandRelease={props.expandRelease}
              ShowedRelease={a}
              sessionInfo={props.sessionInfo}
            />
          </div>
        );})}
    </Modal.Body>
    <Modal.Footer>
    <Button className="btn-primary" size="sm" onClick={() => closeHandler()} >{Localization.getText("Close")}</Button>
    </Modal.Footer>
    </Modal>
    </>
    );
  }




function SingleRelease(props:any) {

  function detailHandler() {
    if (props.ExpandRelease) {
      props.ExpandRelease(props.ShowedRelease.releaseId);
    }
  }

  let listItems = props.ShowedRelease.featureInfo.map((a:any) => {
    return (
      <>
        <div key={a.releaseId} style={{paddingBlockEnd:"2em", borderBottom:"3px solid #f2f2f2"}}>
          <SingleFeature 
            help={true}
            shownFeature={a}
            sessionInfo={props.sessionInfo}
            maxImageHeight="25vh"
          />
        </div>
      </>
      );
    });

  return (
    <>
    <h3>
      {props.help? null : "Release "+props.ShowedRelease.releaseId}
      {true? null : <span onClick={(e) => detailHandler()} style={{fontSize:"14px",cursor:"pointer"}}>{Localization.getText("See details")}</span>}
    </h3>
      {true? listItems: (<ul>{listItems}</ul>)}
    </>
  );
}

