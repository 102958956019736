import { CognitoUserSession } from "amazon-cognito-identity-js";
import { SessionActions } from "./actions";
import { AppMode, Feature } from "./features";
import { CustomerRef, UserRef } from "../../managers/ViamapPersistenceLayer";
import { Cookies } from '../../managers/Cookies';
import { SettingsManager} from '../../managers/SettingsManager';

export enum LoginDialogMode {
    NotDisplayed="NotDisplayed",
    Login = "Login",
    Signup = "Signup",
    SignupEnterCode = "SignupEnterCode",
    ForgotPassword = "ForgotPassword",
    ForgotPasswordEnterCode = "ForgotPasswordEnterCode",
    ChangePassword = "ChangePassword"
}

export enum MessageType { 
    Error="Error",
    Success="Success"
}
  
export interface SessionState {
    message:string,
    messageType:MessageType,
    activeTransactions:{[guid:string]:SessionActions},
    loginMode: LoginDialogMode,
    userSession?: CognitoUserSession,
    userRoles:string[],
    featureSet:Feature[],
    appMode:AppMode,
    customerRef: CustomerRef,
    userRef: UserRef,
    userName: string,
    languageCode: string
}

export const initialSessionState: SessionState = {
    activeTransactions:{},
    message:"",
    messageType:MessageType.Error,
    loginMode: LoginDialogMode.NotDisplayed,
    customerRef: "",
    userRef: "",
    userName: "",
    userRoles: [],
    featureSet: [],
    appMode: AppMode.Normal,
    languageCode: Cookies.getCookie("DefaultLanguage") || SettingsManager.getSystemSetting("defaultLanguage", "da")
};

