import * as JSCookies from 'js-cookie';

export enum MitCookies {
   HideWelcomePage="HideWelcomePage",
   // Strings below contains dates. 
   // This is to allow changing the date when a new version of the acceptance requires re-accept
   // The strings below are keys in the Localization system. 
   // So if changed new localized text must be created
   RememberMapLinkGDPRAccept="RememberMapLinkGDPRAcceptVer20190507",
   RememberCookieAccept="RememberCookieAcceptVer20191022",
   RememberSaveCustomLayerGDPRAccept="RememberSaveCustomLayerGDPRAcceptVer20201222",
   RememberDeleteLayer="RememberDeleteLayerAcceptVer20210201",
}
 
/**
 * Cookies used by Mapit
 */
export class Cookies {
   static MitCookiePrefix="Mit";
   static ExpirationDays=365;

   static getCookie(cookie:MitCookies|string):string|undefined {
      let res=JSCookies.get(Cookies.MitCookiePrefix+cookie);
      return res;
   }

   static setCookie(cookie:MitCookies|string, value:string) {
      const cookieOptions =  { 
         expires: Cookies.ExpirationDays, // days
         path:''
      };
      JSCookies.set(Cookies.MitCookiePrefix+cookie, value, cookieOptions);
   }

   static getCookieBoolean(cookie:MitCookies|string):boolean {
      let val = this.getCookie(cookie);
      let res = val === "false" ? false : Boolean(val);
      return res;
   }

   static setCookieBoolean(cookie:MitCookies|string, value:boolean) {
      this.setCookie(cookie, value.toString());
   }

}