/*
{"query": "Valdemar Holmers Gade 10 2100", 
"result": {"quality": "dawa_matched", "latlng": [55.70992317, 12.55895664], 
"washed": "Valdemar Holmers Gade 10, 2100 K\u00f8benhavn \u00d8"}, "querytype": "wash"}
*/
// import {DawaQuality, DawaAddressResult, DawaAddressResponse} from '../common/managers/Types';
// import {Utils} from '../common/managers/Utils';
// import {Logger} from '../common/managers/Logger';

/* Autogenerated DawaResponse type 
    from example response to  https://dawa.aws.dk/datavask/adresser?betegnelse=xxx
    Created using: https://jvilk.com/MakeTypes/
*/

export enum DawaQuality {   
  unwashable="unwashable",
  dawaMatched="dawaMatched",
  fuzzyWashed="fuzzyWashed",
  dawaError="dawaError",
  dawaWashed="dawaWashed"
}

export type DawaAddressResponse = {
query:string,
result: DawaAddressResult
};

export type DawaAddressResult = {
quality: DawaQuality,
distance: number, // Levenshtein distance for result 
latlng?: number[], 
washed?: string,
errormessage?:string
};

export type DawaAutoCompleteResult = {
tekst:string, // the address
data: {
href:string, // HREF to lookup adress information
id:string, // The id
vejnavn:string,
husnr:string,
postnr:string,
postnrnavn:string
}
};

export type DawaAddressLookupResult = {
adgangspunkt: {
koordinater: number[],
},
ejerlav: {
kode:number,
navn:string
},
kommune: {
kode:string,
navn:string
},
region: {
kode:string,
navn:string
},
matrikelnr:string
};

export interface DawaResponse {
  kategori: string;
  resultater?: (ResultaterEntity)[] | null;
}
export interface ResultaterEntity {
  adresse: AdresseOrAktueladresse;
  aktueladresse: AdresseOrAktueladresse;
  vaskeresultat: Vaskeresultat;
}
export interface AdresseOrAktueladresse {
  id: string;
  vejnavn: string;
  adresseringsvejnavn: string;
  husnr: string;
  supplerendebynavn?: null;
  postnr: string;
  postnrnavn: string;
  status: number;
  virkningstart: string;
  virkningslut?: null;
  adgangsadresseid: string;
  etage?: string | null;
  dør?: null;
  href: string;
}
export interface Vaskeresultat {
  variant: Variant;
  afstand: number;
  forskelle: Forskelle;
  parsetadresse: Parsetadresse;
  ukendtetokens?: (null)[] | null;
  anvendtstormodtagerpostnummer?: null;
}
export interface Variant {
  vejnavn: string;
  husnr: string;
  etage?: string | null;
  dør?: null;
  supplerendebynavn?: null;
  postnr: string;
  postnrnavn: string;
}
export interface Forskelle {
  vejnavn: number;
  husnr: number;
  postnr: number;
  postnrnavn: number;
  etage?: number | null;
}
export interface Parsetadresse {
  vejnavn: string;
  husnr: string;
  postnr: string;
  postnrnavn: string;
  etage?: string | null;
}
// ----------------------------

export class AddressInterface {
  private static testAdresses:string[] = [
  "Skovhaven 95, 5320 Agedrup",
  "Fogedmarken 10, 2200 Copenhagen",
  "Sorøvej 12",
  "Søndergade, otterup"];

  private static serviceUrl:string;

  // Dawa Service limits the number of concurrent requests
  private static noActiveDAWARequests=0;
  private static DAWALimitActiveRequests=9;

  // DAWA wash consists of two phases. Limit Phase 1 active count to allow Phase 2 to run simultaneously and complete wash
  private static DAWALimitActiveRequestsPhase1=AddressInterface.DAWALimitActiveRequests*0.5;
  private static DAWALimitActiveRequestsPhase2=AddressInterface.DAWALimitActiveRequests;
  private static waitMilliSeconds=250;


  /**
   * Tests if string is likely an address.
   * Looks for words like 'gade', 'vej', 'alle', 'vang'
   * @param text A string
   * @returns True if the string is likely an adress
   */
  static addressTest(text:string):boolean {
      const addressElements=['vej','gade','alle','allé','vang','brygge','vænge'];
      let textLower = text.toLowerCase();
      let found = addressElements.find((val) => { return textLower.includes(val); } );
      return (found ? true : false);
  }

  static addressLookupDummy(address:string):DawaAddressResult {
      if (AddressInterface.testAdresses.indexOf(address) >= 0) {
          return {
              quality: DawaQuality.dawaMatched, 
              distance: 0,
              latlng: [55.92628626, 12.29564757], 
              washed: "P. Mogensensvej 31B, 3400 Hiller\u00f8d"
          };
      } else {
          return {
              quality: DawaQuality.unwashable, 
              distance: 100,
          };
      }
  }

  /**
   * Removes é from string and replaces with e etc.
   */
  static removeAccents(input:string):string {
      return input.split("é").join("e");
  }

  static async searchSingle(addr:string):Promise<DawaAddressResponse> {
      let serviceUrl="https://viamap.net/address/";
      let address = encodeURIComponent(addr);
      let query = serviceUrl+"?wash="+address;
      try {
          const response = await fetch(query);
          if (response.status !== 200) {
              throw new Error("Http Error status code received: "+response.status);
          }
          const data = await response.json();
          return data;
      } catch (err : any) {
          return {query:addr, result:{quality: DawaQuality.dawaError, distance:0 , errormessage: err.toString()}};
      }
  }

  // todo: find bedste adressematch hvis der er flere med den mindste Levenshtein afstand.
  // vi vil gerne ramme: bogstaver 12A, 12B typer og ranges 11-13 
  static selectBestDawaMatch(data:DawaResponse):ResultaterEntity {
      // function isBetterMatch(candidate:ResultaterEntity, currentMatch:ResultaterEntity):boolean {
      //     // todo: implement
      //     return false;
      // }
      
      // let found:ResultaterEntity;
      // console.assert(data.resultater && data.resultater.length > 1);
      // let mindsteAfstand=data.resultater![0].vaskeresultat.afstand;
      // found = data.resultater![0];
      // // resultater er sorteret efter 'afstand'. Kun resultater med mindste afstand behøver checkes.
      // data.resultater && data.resultater.forEach((res:ResultaterEntity, idx:number) => {
      //     if (res.vaskeresultat.afstand <= mindsteAfstand) {
      //         if (isBetterMatch(res,found)) {
      //             found = res;
      //         }
      //     }
      // });
      return data.resultater![0];
  }

  // static async searchSingleDawaDirect(addr:string):Promise<DawaAddressResponse> {

  //     async function wait(ms:number) {
  //         return new Promise(resolve => {
  //           setTimeout(resolve, ms);
  //         });
  //       }

  //     let serviceUrl=Utils.getSystemSetting("addressServiceURLDawaWash");
  //     let address = encodeURIComponent(addr);
  //     let query = serviceUrl+"?betegnelse="+address;
  //     try {
  //         while (AddressInterface.noActiveDAWARequests >= AddressInterface.DAWALimitActiveRequestsPhase1) {
  //             // wait;
  //             let dummy = await wait(AddressInterface.waitMilliSeconds);
  //         }
  //         AddressInterface.noActiveDAWARequests++;
  //         const response = await fetch(query);
  //         AddressInterface.noActiveDAWARequests--;
  //         if (response.status !== 200) {
  //             throw new Error("Http Error status code received: "+response.status);
  //         }
  //         const data = await response.json();

  //         let quality:DawaQuality;
  //         let latlng:any[];
  //         let washed:string;
  //         latlng=[];
  //         let found;
  //         if (data.resultater.length > 1) {
  //             if ((data as DawaResponse).resultater![0].vaskeresultat.afstand === 0) {
  //                 // exact match found in first response
  //                 found = data.resultater[0];
  //             } else {
  //                 found = AddressInterface.selectBestDawaMatch(data);
  //             }
  //         } else {
  //             found = data.resultater[0];
  //         }
  //         if (found && found.vaskeresultat.afstand > 0 && found.vaskeresultat.parsetadresse.postnrnavn === "" && found.vaskeresultat.forskelle.postnrnavn > 0) {
  //             found.vaskeresultat.afstand -= found.vaskeresultat.forskelle.postnrnavn;
  //         }
  //         let levenshteinDistanceString=Utils.formatString("[{afstand}]",{afstand:found.vaskeresultat.afstand});

  //         switch (data.kategori) {
  //             case "A": 
  //                 quality=DawaQuality.dawa_matched;
  //                 break;
  //             case "B":
  //                 quality= found.vaskeresultat.afstand > 0 ? DawaQuality.dawa_washed : DawaQuality.dawa_matched;
  //                 break;
  //             case "C":
  //                 quality=DawaQuality.fuzzy_washed;
  //                 break;
  //             default:
  //                 quality=DawaQuality.unwashable;
  //         }

  //         let adresseRec = found.aktueladresse || found.adresse;
  //         washed=Utils.formatString(
  //             "{vej} {husnr}, {postnr} {postnavn}", 
  //             {
  //             vej:adresseRec.vejnavn, 
  //             husnr:adresseRec.husnr, 
  //             postnr:adresseRec.postnr, 
  //             postnavn:adresseRec.postnrnavn
  //             }
  //         );
          
  //         // Get coordinates by id
  //         let PosServiceUrl=Utils.getSystemSetting("addressServiceURLDawaById"); 
  //         let id = adresseRec.id;
  //         let query2 = PosServiceUrl+"/"+id;
  //         query2 += "?struktur=nestet";
  //         if (adresseRec.status !== 1) {
  //             query2 += "&medtagnedlagte";
  //         }
  //         try {
  //             while (AddressInterface.noActiveDAWARequests >= AddressInterface.DAWALimitActiveRequestsPhase2) {
  //                 // wait;
  //                 let dummy = await wait(AddressInterface.waitMilliSeconds);
  //             }
  //             AddressInterface.noActiveDAWARequests++;
  //             const response2 = await fetch(query2);
  //             AddressInterface.noActiveDAWARequests--;
  //             if (response2.status !== 200) {
  //                 let err = "got status code:"+response2.status+" quality:"+quality+" differences:"+found.vaskeresultat.afstand;
  //                 return {query:addr, result:{quality: DawaQuality.dawa_error, distance:found.vaskeresultat.afstand, errormessage: ""}};
  //             }
  //             const data2 = await response2.json();
  //             let coord = data2.adgangspunkt.koordinater;
  //             latlng=[coord[1],coord[0]];
  //             return {query:addr, result:{quality: quality, distance:found.vaskeresultat.afstand, latlng:latlng, washed:washed}};
  //         } catch (err) {
  //             return {query:addr, result:{quality: DawaQuality.dawa_error, distance:0, errormessage: err.toString()}};
  //         }
  //     } catch (err) {
  //         return {query:addr, result:{quality: DawaQuality.dawa_error, distance:0, errormessage: err.toString()}};
  //     }
  // }
  
  static geocode(
      addr:string[], callback:(result:DawaAddressResult[])=>void,
      statusStarted:(noGeoCodeStarted:number) => void, statusCompleted: (noGeoCodeCompleted:number, stats:any) => void) {
      let completed = 0;
      let stats = {
          [DawaQuality.dawaMatched]:0,
          [DawaQuality.dawaWashed]:0,
          [DawaQuality.fuzzyWashed]:0,
          [DawaQuality.unwashable]:0,
          [DawaQuality.dawaError]:0,
      };
    //   let results:DawaAddressResult[] = [];
      let queryToIndexMap= {};
      for (let i = 0; i < addr.length; i++) {
          let address =  addr[i];
          address = AddressInterface.removeAccents(address);

          // make sure all address strings are unique. Pad with whitespace
          let pads=0;
          const maxPads=10;
          while(pads < maxPads && Object.keys(queryToIndexMap).indexOf(address)>=0) {
              address += " ";
              pads++;
          }
          if (pads === maxPads) {
              // // Logger.logWarning("AddressInterface","geocode","Maximum identical adresses exceeded: "+pads+ " Address"+address);
          }

          // Save query to match again when response is received
          // queryToIndexMap[address]=i;

          statusStarted(i+1);

          let washFuncToUse:(addr:string) => Promise<DawaAddressResponse> ;
          // if ((false)) {
          //     // Call DAWA wash function directly
          //     // washFuncToUse = AddressInterface.searchSingleDawaDirect;
          // } else {
          //     // Call Viamap wash API (which in turn calls DAWA)
          // }
          washFuncToUse = AddressInterface.searchSingle;

          // eslint-disable-next-line no-loop-func
          washFuncToUse(address).then((response) => {
//            AddressInterface.searchSingle(address).then((response) => {
              completed += 1;
              const q = response.result.quality;
              stats = {...stats, [q]:stats[q]+1};
              statusCompleted(completed, stats);

              // if (response.result.quality !== DawaQuality.dawa_matched) {
              //     // Logger.logInfo("AddressInterface","geocode","Not dawa_match: "+JSON.stringify(response));
              // }

              // Match to index of the query
            //   let rq = response.query;
              // let index = queryToIndexMap[rq];
              // if (!index && index!==0) {
              //     throw Utils.createErrorEventObject("Could not match response "+JSON.stringify(response));
              // }
              // // Store result in the right index
              // results[index]=response.result;

              // // Done?
              // if (completed === addr.length) {
              //     // // Logger.logInfo("AddressInterface","geocode","Completed. Stats: "+JSON.stringify(stats));
              //     callback(results);
              // }
          });
      }
  }
}