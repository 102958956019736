import * as React from "react";
import { Cookies } from '../managers/Cookies';
import { FeatureHelpFile } from "../managers/FeatureHelpManager";

export enum MitCookies {HelpToNewUserViewed, HelpLatestReleaseViewed};

export interface UserHelpState {
   showFirstTimeHelp:boolean;
   showNewFeatureHelp:boolean;
   showReleases: boolean;
   showUserGuide: boolean;
   releaseLog: FeatureHelpFile;
   helpLog: FeatureHelpFile;
}

export function initialUserHelpState(): UserHelpState {

   return {
      showFirstTimeHelp: false,
      showNewFeatureHelp: false,
      releaseLog: [],
      helpLog: [],
      showReleases: false,
      showUserGuide: false,
   };
}

export function initializeUserHelpState(newUserInformation:FeatureHelpFile, releaseInformation:FeatureHelpFile): UserHelpState {
   let helpCookie = Cookies.getCookieBoolean("HelpToNewUserViewed");
   let showFirstTimeHelp = !helpCookie;
   let releaseCookie = Cookies.getCookie("HelpLatestReleaseViewed") || "";
   let mostRecentReleaseId = releaseInformation[0] && releaseInformation[0].releaseId;
   let showNewFeatureHelp = releaseCookie !== mostRecentReleaseId;

   return {
      showFirstTimeHelp: showFirstTimeHelp,
      showNewFeatureHelp: showNewFeatureHelp,
      releaseLog: releaseInformation,
      helpLog: newUserInformation,
      showReleases: false,
      showUserGuide: false,
   };
}

export enum UserHelpActionType {
   Initialize,
   NewUserHelpViewed,
   ReleseUserHelpViewed,
}

export interface Initialize {
   type: UserHelpActionType.Initialize;
   payload: {
      newUserInformation:FeatureHelpFile;
      releaseInformation:FeatureHelpFile;
   }
}

export interface NewUserHelpViewed {
   type: UserHelpActionType.NewUserHelpViewed;
}
export interface ReleseUserHelpViewed {
   type: UserHelpActionType.ReleseUserHelpViewed;
   payload: { releaseId: string };
}

// quick functions
export const initialize = (newUserInformation:FeatureHelpFile, releaseInformation:FeatureHelpFile): Initialize => ({
   type: UserHelpActionType.Initialize,
   payload: {  newUserInformation, releaseInformation }
});
export const newUserHelpViewed = (): NewUserHelpViewed => ({
   type: UserHelpActionType.NewUserHelpViewed
});
export const releaseUserHelpViewed = (releaseId:string): ReleseUserHelpViewed => ({
   type: UserHelpActionType.ReleseUserHelpViewed,
   payload: { releaseId }
});

export type UserHelpActionsTransActional = NewUserHelpViewed | ReleseUserHelpViewed;
export type UserHelpActionsNonTransActional = NewUserHelpViewed | Initialize;
export type UserHelpActions = UserHelpActionsTransActional | UserHelpActionsNonTransActional;

export const UserHelpContext = React.createContext<{
    state: UserHelpState;
    dispatch: React.Dispatch<UserHelpActions>;
}>({
    state: initialUserHelpState(),
    dispatch: () => undefined,
});


export function transactionalUserHelpReducer(action: UserHelpActionsTransActional, dispatch: any) {

   switch (action.type) {
      
      default:
         // proceed directly to non-transational reducer for other actions
         dispatch(action);
      }
}

export function UserHelpReducer(state: UserHelpState, action: UserHelpActions): UserHelpState {
   
   switch (action.type) {
      case UserHelpActionType.Initialize: {
            return initializeUserHelpState(action.payload.newUserInformation, action.payload.releaseInformation);
           break;
      }
      case UserHelpActionType.NewUserHelpViewed: {
           Cookies.setCookieBoolean("HelpToNewUserViewed", true);
           return { ...state, showFirstTimeHelp:false, showNewFeatureHelp:true };
           break;
      }
      case UserHelpActionType.ReleseUserHelpViewed: {
           Cookies.setCookie("HelpLatestReleaseViewed", action.payload.releaseId);
           return { ...state, showNewFeatureHelp:false };
           break;
      }
      
      default:
           throw new Error("Unknown action " + (action as UserHelpActions).type);
   }
}
