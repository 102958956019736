import { Feature, AppMode, AppCommand } from "./MapitTypes";
/**
 * Global type definitions
 */
 export enum DeploymentMode { 
    Unknown="Unknown", 
    Development="Development", 
    Test="Test", // Unit Test Enviromment
    Staging="Staging", 
    Testing="Testing",
    Demo="Demo", // Demo / Regression Test Environment
    Production="Production"
}
    
export type UserSession = {
        id: string,
        featureSet?: Feature[],
        userName: string,
        name: string,
        email: string,
        userRoles: string[] ,
        authRoles?: string[] ,
        isAnonymousSession: boolean
        jwtToken: string|null,
        customerProfileCode?:string,
        customerProfileName?:string,
        deploymentMode: DeploymentMode
};

export type SessionInfo = {
    language?:string;
    appMode:AppMode;
    userSession?: UserSession;
    mapReferenceData?:{};
    commandList?: AppCommand[];
};

