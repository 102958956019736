import {Utils} from './Utils';
import {Logger} from './Logger';
import {UserSession, SessionInfo} from './Types';
import {AppMode, Feature} from './MapitTypes';

/* ToDo: Needs major rewrite. Cognito part is not used for login as Cognito login happens using AWS GUI and managed in App.tsx. Cognito part is only used to store UserSession.
*/

export enum AccessManagerMode { Undefined, anonymousSession, DevelopmentMode, AuthenticationNeeded }

export interface AccessManagerImpl {
    userHasAccessToFeature(sessionInfo:SessionInfo, ft:Feature):boolean ;
    shouldFeatureBeAvailable(sessionInfo:SessionInfo, ft:Feature):boolean ;
    login(email:string, password:string):UserSession|null ;
    logout(us:UserSession);
    getUserSession():UserSession ;
    setUserSession(us:UserSession);
}

export type FeatureAllowDenyListMap = { 
    [key:string]: { 
        allow:Feature[];
        deny:Feature[];
    }
};

export const MinimalFeaturesRole = "MINIMAL_FEATURES_FOR_ANY_USER";

export class AccessManagerCore {

    /**
     * User Role Based Access Parameters
     */
    UserRole2Features:FeatureAllowDenyListMap = {
        [MinimalFeaturesRole]: {
            allow: [],
            deny:  []
        }
    };

        /**
     * Appication Mode Based Access Parameters
     */
         FeaturesAccessibleByMode:FeatureAllowDenyListMap = {
            [AppMode.Embedded]: {
                allow: [],
                deny: []
            },
            [AppMode.ReadOnly]: {
                allow: [],
                deny: []
            },
            [AppMode.Normal]: {
                allow: [],
                deny: []
            }
        };

    removeFeature(features:Feature[], removeFt:Feature):Feature[] {
        return features.filter((ft,idx) => {
            return ft !== removeFt;
        });
    }

    addFeature(features:Feature[], addFt:Feature):Feature[] {
        // test for undefined to ensure that '0' values are also matched
        if (features.find((val) => val === addFt) === undefined) {
            features.push(addFt);
        }
        return features;
    }

    getFeaturesForRoles(userRoles:string[]):Feature[] {
        return this._getFeaturesForRoles(this.UserRole2Features, userRoles);
    }

    _getFeaturesForRoles(featuresForGroupStructure:FeatureAllowDenyListMap, userRoles:string[]):Feature[] {

        // Always give minimal features 
        if (userRoles) {
            userRoles.push(MinimalFeaturesRole);
        } else {
            userRoles = [MinimalFeaturesRole];
        }

        // Construct feature set from groups memberships
        let featureSet:Feature[] = [];
        // phase 1: all allows
        userRoles && userRoles.forEach((gr,idx) => {
            let featuresforgroup = featuresForGroupStructure[gr];
            if (featuresforgroup) {
                featuresforgroup.allow.forEach((ft) => {
                    featureSet = this.addFeature(featureSet, ft);
                });
            }
        });
        // phase 2: all denys
        userRoles && userRoles.forEach((gr,idx) => {
            let featuresforgroup = featuresForGroupStructure[gr];
            if (featuresforgroup) {
                // remove any items in deny list
                featuresforgroup.deny && featuresforgroup.deny.forEach(element => {
                    featureSet = this.removeFeature(featureSet, element);
                });
            }
        });
        return featureSet;
    }

    /**
     * Access check based on AppMode first - then user's role.
     * @param sessionInfo 
     * @param ft 
     */
    shouldFeatureBeAvailable(sessionInfo:SessionInfo, ft:Feature):boolean {
        let denyList:Feature[] = this.FeaturesAccessibleByMode[sessionInfo.appMode] && this.FeaturesAccessibleByMode[sessionInfo.appMode].deny;
        if (denyList && denyList.indexOf(ft) >= 0) {
            return false;
        } else {
            return this.userHasAccessToFeature(sessionInfo, ft);
        }
    }

    /**
     * Access Check Based on User's roles (only)
     * @param sessionInfo 
     * @param ft 
     */
    userHasAccessToFeature(sessionInfo:SessionInfo, ft:Feature):boolean {
        let us = sessionInfo.userSession;
        if (us && us.featureSet) {
                for(var i=0; i<us.featureSet.length; i++) {
            if (us.featureSet[i] === ft) { return true; }
            }
        }
        return false;
    }

} 

export class AccessManagerDebug extends AccessManagerCore implements AccessManagerImpl {
    state:string;
    us:UserSession;

    users = [
        {
            email:"klaus@viamap.net",
            password: "kaffebar",
            userRoles:["Basic"]
        },
        {
            email:"thomas@viamap.net",
            password: "kaffebar",
            userRoles:["Basic"]
        },
        {
            email:"trial@viamap.net",
            password: "kaffebar",
            userRoles:["Basic", "GeodataReport"]
        },
        {
            email:"trialpro@viamap.net",
            password: "kaffebar",
            userRoles:["Basic", "Demo", "360North"]
            },
    ];

    constructor() {
        super();
        this.state="";
    }

    // userHasAccessToFeature(sessionInfo:SessionInfo, ft:Feature):boolean {
    //     if (sessionInfo.userSession) {
    //         let us = sessionInfo.userSession;
    //         for(var i=0; i<us.featureSet.length; i++) {
    //         if (us.featureSet[i] === ft) { return true; }
    //         }
    //     }
    //     return false;
    // }

    login(email:string, password:string):UserSession|null {
        for(var i=0; i<this.users.length; i++) {
            if (this.users[i].email === email && 
            this.users[i].password === password) {
                let featureSet:Feature[] = this.getFeaturesForRoles(this.users[i].userRoles);

                let us:UserSession = {
                    id:"ss",
                    email:email, 
                    featureSet:featureSet, 
                    userName:"userName", 
                    name:"name", 
                    userRoles:this.users[i].userRoles, 
                    isAnonymousSession:false,
                    jwtToken:null,
                    deploymentMode: Utils.getDeploymentMode()
                };
                this.us = us;
                return (us);
            }
        }
        throw Utils.createErrorEventObject("Email or Password could not be authenticated!");
    }

    logout(us:UserSession) {
        // do nothing
    }

    getUserSession():UserSession {
        return(this.us);
    }

    setUserSession(us:UserSession) {
        this.us = us;     
    }
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------
export class AccessManageranonymous extends AccessManagerCore implements AccessManagerImpl {
    state:string;
    us:UserSession;

    constructor() {
        super();
        this.state="";
    }

    login(email:string, password:string):UserSession|null {
        throw Utils.createErrorEventObject("Unexpected login attempt for anonymous user!");
    }

    logout(us:UserSession) {
        // do nothing
    }

    getUserSession():UserSession {
        return(this.us);
    }

    setUserSession(us:UserSession) {
        if (!us.featureSet) {
            // Construct feature set from groups memberships
            let rolesForAnonymousUser=us.userRoles;
            let featureSet:Feature[] = this.getFeaturesForRoles(rolesForAnonymousUser);
            us.featureSet = featureSet;
        }
        this.us = us;
    }
}

// =================== COGNITO AUTH =========================

import * as AmazonCognitoIdentity from 'amazon-cognito-auth-js';
import {CognitoAuthOptions} from 'amazon-cognito-auth-js';
import { FeatureLayer } from 'esri-leaflet';

export class AccessManagerCognito extends AccessManagerCore implements AccessManagerImpl {
    auth:AmazonCognitoIdentity.CognitoAuth;
    us:UserSession;



    constructor() {
        super();
        // this.auth = this.awsAuthSetup();
        // let curUrl = window.location.href;
        // this.auth.parseCognitoWebResponse(curUrl);
    }
                 
    // userHasAccessToFeature(sessionInfo:SessionInfo, ft:Feature):boolean {
    //     if (sessionInfo.userSession) {
    //         let us = sessionInfo.userSession;
    //         for(var i=0; i<us.featureSet.length; i++) {
    //         if (us.featureSet[i] === ft) { return true; }
    //         }
    //     }
    //     return false;
    // }

    login(email:string, password:string):UserSession|null {
        // ToDo: Authenticate using own ui and use email/pw

        // For now: use Amazon Cognito UI
        this.auth.getSession();

        // ToDo: extract roles based auth from Cognito
        let us:UserSession = {
            id:"julemand", 
            featureSet:[Feature.BasicMap], 
            userName:"userName", 
            name:"name", 
            email:"email", 
            userRoles:[], 
            isAnonymousSession:false, 
            jwtToken:null,
            deploymentMode: Utils.getDeploymentMode()
        };
        return us;

        //        throw Utils.createErrorEventObject("Email or Password could not be authenticated!");
    }

    logout(us:UserSession) {
        if (!this.auth) { throw Utils.createErrorEventObject("Auth unexpectedly null on signout"); }
        this.auth.signOut();
    }

    getUserSession():UserSession {
        return(this.us);
    }

    setUserSession(us:UserSession) {
        // Cognito groups control the accessible features.
        // Cashe the resulting feature set on session.

        // Construct feature set from Cognito groups memberships
        let featureSet:Feature[] = this.getFeaturesForRoles(us.userRoles);
        us.featureSet = featureSet;
        this.us = us;
    }

}

export class AccessManager {
    static impl:AccessManagerImpl;
    static mode:AccessManagerMode = AccessManagerMode.Undefined;

    static setMode(mode:AccessManagerMode) {
        this.mode = mode;
    }
  
    static getImpl():AccessManagerImpl {
        if (!AccessManager.impl) {
            switch (this.mode) {
                case AccessManagerMode.anonymousSession:
                AccessManager.impl = new AccessManageranonymous();
                break;
                case AccessManagerMode.DevelopmentMode:
                AccessManager.impl = new AccessManagerDebug();
                break;
                case AccessManagerMode.AuthenticationNeeded:
                AccessManager.impl = new AccessManagerCognito();
                break;
                case AccessManagerMode.Undefined:
                default:
                throw Utils.createErrorEventObject("mode not set when initializing access manager");
            }
        }
        return AccessManager.impl;
    }

    static userHasAccessToFeature(sessionInfo:SessionInfo, ft:Feature):boolean {
        return AccessManager.getImpl().userHasAccessToFeature(sessionInfo, ft);
    }

    static shouldFeatureBeAvailable(sessionInfo:SessionInfo, ft:Feature):boolean {
        return AccessManager.getImpl().shouldFeatureBeAvailable(sessionInfo, ft);
    }

    static login(email:string, password:string):UserSession|null {
        return AccessManager.getImpl().login(email, password);
    }

    static logout(us:UserSession) {
        AccessManager.getImpl().logout(us);        
    }

    static getUserSession():UserSession {
        return AccessManager.getImpl().getUserSession();        
    }

    static setUserSession(us:UserSession) {
        AccessManager.getImpl().setUserSession(us);        
        Logger.logAction("Access Manager","New user session", us);
    }

    /**
     * Splits an email adress on the "@" and returns the string thereafter.
     * @param email A valid email address
     * @returns The domain of the email address
     */
    static getUserDomain(email: string): string | undefined {
        if (email) {
            let splitEmail = email.split('@');
            let domainName = (splitEmail.length > 1) && splitEmail[1];
            return domainName ? domainName : undefined;
        } else {
            return undefined;
        }
    }
}
