import * as React from "react";

// ---------------------------------------------------- STATE --------------------------------------------------------------

export interface AppMessagesState {
   loadingMessage?:string,
   errorMessage?:string,
   infoMessage?:string,
   progressMessage?:string,
   progressPercentComplete?:number
}

// ---------------------------------------------------- INITIAL STATE --------------------------------------------------------------

export function initialAppMessagesState(): AppMessagesState {
   return {
      loadingMessage:undefined,
      errorMessage:undefined,
      infoMessage:undefined,
      progressMessage:undefined,
      progressPercentComplete:undefined
   };
}

// ---------------------------------------------------- ACTIONS --------------------------------------------------------------
export enum AppMessagesActionType {
   setErrorMessage,
   setInfoMessage,
   setProgressMessage,
   clearErrorMessage,
   clearInfoMessage,
   clearProgressMessage
}

export interface setErrorMessage {
   type: AppMessagesActionType.setErrorMessage;
   payload: { message: string};
}

export interface setInfoMessage {
   type: AppMessagesActionType.setInfoMessage;
   payload: { message: string};
}

export interface setProgressMessage {
   type: AppMessagesActionType.setProgressMessage;
   payload: { message: string, percentComplete: number};
}

export interface clearErrorMessage {
   type: AppMessagesActionType.clearErrorMessage;
}

export interface clearInfoMessage {
   type: AppMessagesActionType.clearInfoMessage;
}

export interface clearProgressMessage {
   type: AppMessagesActionType.clearProgressMessage;
}

// -------------------- utility functions to create an Action object ---------------------------------

export const actionSetErrorMessage = (message: string): setErrorMessage => ({
   type: AppMessagesActionType.setErrorMessage,
   payload: { message }
});

export const actionSetInfoMessage = (message: string): setInfoMessage => ({
   type: AppMessagesActionType.setInfoMessage,
   payload: { message }
});

export const actionSetProgressMessage = (message: string, percentComplete: number): setProgressMessage => ({
   type: AppMessagesActionType.setProgressMessage,
   payload: { message, percentComplete }
});

export const actionClearErrorMessage = (): clearErrorMessage => ({
   type: AppMessagesActionType.clearErrorMessage,
});

export const actionClearInfoMessage = (): clearInfoMessage => ({
   type: AppMessagesActionType.clearInfoMessage,
});

export const actionClearProgressMessage = (): clearProgressMessage => ({
   type: AppMessagesActionType.clearProgressMessage,
});

export type AppMessagesActionsTransActional = setErrorMessage | setInfoMessage | setProgressMessage | clearErrorMessage | clearInfoMessage | clearProgressMessage;
export type AppMessagesActionsNonTransActional = setErrorMessage;
export type AppMessagesActions = AppMessagesActionsTransActional | AppMessagesActionsNonTransActional;


// ---------------------------------------------------- TRANSACTIONAL REDUCER --------------------------------------------------------------

export function transactionalAppMessagesReducer(action: AppMessagesActionsTransActional, dispatch: any) {

   switch (action.type) {

      default:
         // proceed directly to non-transational reducer for other actions
         dispatch(action);
      }
}

// ---------------------------------------------------- REDUCER --------------------------------------------------------------

export function AppMessagesReducer(state: AppMessagesState, action: AppMessagesActions): AppMessagesState {

   switch (action.type) {

      case AppMessagesActionType.setErrorMessage: {
         return { ...state, errorMessage:action.payload.message };
      }
      case AppMessagesActionType.setInfoMessage: {
         return { ...state, infoMessage:action.payload.message };
      }
      case AppMessagesActionType.setProgressMessage: {
         return { ...state, progressMessage:action.payload.message, progressPercentComplete:action.payload.percentComplete };
      }
      case AppMessagesActionType.clearErrorMessage: {
         return { ...state, errorMessage:undefined };
      }
      case AppMessagesActionType.clearInfoMessage: {
         return { ...state, infoMessage:undefined };
      }
      case AppMessagesActionType.clearProgressMessage: {
         return { ...state, progressMessage:undefined, progressPercentComplete:undefined };
      }  
      default:
           throw console.log("Unknown action " + (action as AppMessagesActions).type);
   }
}

// ---------------------------------------------------- generic stuff --------------------------------------------------------------

export const AppMessagesContext = React.createContext<{
   state: AppMessagesState;
   dispatch: React.Dispatch<AppMessagesActions>;
}>({
   state: initialAppMessagesState(),
   dispatch: () => console.log("Dispacthc Outside of scope"),
});

