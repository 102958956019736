import { Utils } from '../managers/Utils';
import { Localization } from '../managers/Localization';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import * as React from 'react';

export const PasswordPolicyDisplay = (passwordPolicy: CognitoIdentityServiceProvider.PasswordPolicyType|undefined, password: string) => {

    const showPolicyStatus = (text: string, compliant: boolean) => {
        const coloring = compliant ? "text-success" : "text-danger";
        return (
            <tr><div className={coloring}>{text}</div></tr>
        );
    };

    const minimumLength = (minLength: number | undefined, password: string) => {
        if (minLength && minLength > 0) {
            const text = Utils.formatString(Localization.getText("Minimum length: {minLength}"), { minLength });
            const ok = Boolean(password && password.length >= minLength);
            return showPolicyStatus(text, ok);
        }
        else {
            return null;
        }
    };

    const requireLowercase = (req:boolean|undefined, password: string) => {
        if (req) {
            const text = Localization.getText("Lowercase letter required");
            const ok = Boolean(password && password.toUpperCase() !== password);
            return showPolicyStatus(text, ok);
        }
        else {
            return null;
        }
    };

    const requireUppercase = (req:boolean|undefined, password: string) => {
        if (req) {
            const text = Localization.getText("Uppercase letter required");
            const ok = Boolean(password && password.toLowerCase() !== password);
            return showPolicyStatus(text, ok);
        }
        else {
            return null;
        }
    };

    const requireSymbols = (req:boolean|undefined, password: string) => {
        if (req) {
            const text = Localization.getText("Symbol required");
            const ok = Boolean(password && password.match(/.*[\½\§\!\"\#\¤\%\&\/\(\)\=\?\+\´\`\¨\^\'\*\,\;\.\:\-\_\<\>\@\£\$\{\[\]\}\|\~\\].*/));
            return showPolicyStatus(text, ok);
        }
        else {
            return null;
        }
    };

    const requireNumbers = (req:boolean|undefined, password: string) => {
        if (req) {
            const text = Localization.getText("Number required");
            const ok = Boolean(password && password.match(/.*[0-9].*/));
            return showPolicyStatus(text, ok);
        }
        else {
            return null;
        }
    };

    return passwordPolicy ? (
        <>
            <table className="viamap2-password-policy-table">
                <tbody>
                    {minimumLength(passwordPolicy.MinimumLength, password)}
                    {requireLowercase(passwordPolicy.RequireLowercase, password)}
                    {requireNumbers(passwordPolicy.RequireNumbers, password)}
                    {requireSymbols(passwordPolicy.RequireSymbols, password)}
                    {requireUppercase(passwordPolicy.RequireUppercase, password)}
                </tbody>
            </table>
        </>
    ) : null;
};