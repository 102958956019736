import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Credentials } from 'aws-sdk';
import * as AWS from 'aws-sdk';
import { AWSCloudWatchLogger } from './ASWCloudWatchLogger';

export type RequiredAuthData = {
  "ClientId" : string;
  "UserPoolId" : string;
  "IdentityPoolId" : string;
  "Region": string;
};

export class AWSAuthenticationInterface {
  private static USER_POOL_ID:string;
  private static APP_CLIENT_ID:string; // Must be created _without_ 'generate client secret' (I.e. selected OFF)
  private static REGION:string;
  private static IDENTITY_POOL:string;
  private static isInitialized:boolean=false;

  static checkInitialized(text?:string) {
    if (!this.isInitialized) {
      throw new Error("AWS function called before initialized: "+text);
    }
  }
  static initialize(authData:RequiredAuthData) {
    if (authData
      && authData.UserPoolId
      && authData.ClientId
      && authData.IdentityPoolId
      && authData.Region) {
        AWSAuthenticationInterface.USER_POOL_ID = authData.UserPoolId;
        AWSAuthenticationInterface.APP_CLIENT_ID = authData.ClientId;
        AWSAuthenticationInterface.IDENTITY_POOL = authData.IdentityPoolId;
        AWSAuthenticationInterface.REGION = authData.Region;    
        this.isInitialized=true;
    } else {
      throw new Error("Missing configuration data for AWS Cognito Authorization");
    }
  }

  static isInterfaceInitialized():boolean { return this.isInitialized;}
  
  /**
   * 
   * @param userName 
   * @param password 
   * @returns 
   */
  static async login(userName: string, password: string) {
    this.checkInitialized("");
    const userPool = new CognitoUserPool({
      UserPoolId: this.USER_POOL_ID,
      ClientId: this.APP_CLIENT_ID
    });
    const user = new CognitoUser({
      Username: userName,
      Pool: userPool
    });
    const authenticationData = {
      Username: userName,
      Password: password,
    };
    
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    return new Promise<CognitoUserSession>((resolve, reject) =>
      user.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          var accessToken = result.getAccessToken().getJwtToken();
          // Region needs to be set if not already set previously elsewhere.
          AWS.config.region = this.REGION;
          AWSAuthenticationInterface.MapCurrentUser(result);
          // (AWS.config.credentials as any).IdentityPoolId = this.IDENTITY_POOL;
          // (AWS.config.credentials as any).Logins = (AWS.config.credentials as any).Logins || {};
          // (AWS.config.credentials as any).Logins['cognito-idp.' + this.REGION + '.amazonaws.com/' + this.USER_POOL_ID] = result.getIdToken().getJwtToken();
          (AWS.config.credentials as Credentials).expired = true;
          
          // console.log(AWS.config.credentials);
          // AccessManager.setUserSession({
          //   id: ,
          //   userName: ,
          //   name: ,
          //   email: ,
          //   userRoles : , 
          //   isAnonymousSession : , 
          //   jwtToken : , 
          //   deploymentMode : 
          // });

          // (AWS.config.credentials as any).WebIdentityToken = result.getAccessToken();

          // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          //   IdentityPoolId: this.IDENTITY_POOL,
          //   Logins: {
          //     ['cognito-idp.' + this.REGION + '.amazonaws.com/' + this.USER_POOL_ID]: result
          //       .getIdToken()
          //       .getJwtToken(),
          //   }
          // });
/*
function userLoggedIn(providerName, token) {
    creds.params.Logins = creds.params.Logins || {};
    creds.params.Logins[providerName] = token;

    // Expire credentials to refresh them on the next request
    creds.expired = true;
}*/
          (AWS.config.credentials as Credentials).get((error2:any) => {
            if (error2) {
              console.error(error2);
              reject(error2);
            } else {
              // refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
              (AWS.config.credentials as Credentials).refresh(error => {
                if (error) {
                  console.error(error);
                  reject(error);
                } else {
                  console.log('Successfully logged in!');
                  AWSCloudWatchLogger.switchOnAWSLogging(new AWS.CloudWatchLogs())
                  // Logger.logAction("Authentication", "Login", "User logged in")
                  resolve(result);
                }
              });
            }
          });

        },
        onFailure: err => reject(err)
      })
    );
  }

  /**
   * 
   * @param userName 
   * @returns 
   */
  static async logoff(userName: string) {
    this.checkInitialized("");
    const userPool = new CognitoUserPool({
      UserPoolId: this.USER_POOL_ID,
      ClientId: this.APP_CLIENT_ID
    });
    const user = new CognitoUser({
      Username: userName,
      Pool: userPool
    });

    return new Promise<void>((resolve, reject) => {
      user.signOut(() => {
        // Clear localstorage. Required to clear credentials - otherwise we are still logged in after a browser refresh.
        localStorage.clear();
        resolve();
      });
    });
  }

  /**
   * 
   * @param userName 
   * @returns 
   */
  static async forgotPassword(userName: string) {
    this.checkInitialized("");
    const userPool = new CognitoUserPool({
      UserPoolId: this.USER_POOL_ID,
      ClientId: this.APP_CLIENT_ID
    });
    const user = new CognitoUser({
      Username: userName,
      Pool: userPool
    });

    return new Promise<void>((resolve, reject) => {
      user.forgotPassword({
        onSuccess: result => {
          resolve(result);
        },
        onFailure: err => reject(err)
      });
    });
  }

  static UnAuth() {
    AWS.config.region = this.REGION;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId:this.IDENTITY_POOL,
    });
  }

  static async InitAuth(session : CognitoUserSession) {
    AWS.config.region = this.REGION;
    let x = new AWS.CognitoIdentityServiceProvider();
    return new Promise<void>((resolve, reject) => {
      x.initiateAuth({
        ClientId: this.APP_CLIENT_ID,
        AuthFlow: "REFRESH_TOKEN_AUTH",
        AuthParameters: {REFRESH_TOKEN: session.getRefreshToken().getToken()}
      },(err, data) => {
        if (err) {
          reject(err)
        }
        if (data && data.AuthenticationResult && data.AuthenticationResult.IdToken) {
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId:this.IDENTITY_POOL,
            Logins : {['cognito-idp.' + this.REGION + '.amazonaws.com/' + this.USER_POOL_ID] : data.AuthenticationResult?.IdToken || ""}
          });
          // AWSCloudWatchLogger.switchOnAWSLogging(new AWS.CloudWatchLogs())
          // Logger.logAction("Authentication", "Login", "User Already Logged in");
          resolve()
        }
        reject()
      })
    });
  }

  /**
   * 
   * @param userName 
   * @param password 
   * @returns 
   */
  static async changePassword(userName: string, oldPassword: string, newPassword: string) {
    this.checkInitialized("");
    const userPool = new CognitoUserPool({
      UserPoolId: this.USER_POOL_ID,
      ClientId: this.APP_CLIENT_ID
    });

    return new Promise<void>((resolve, reject) => {
      const currentUser = userPool.getCurrentUser();
      if (currentUser) {

        currentUser.getSession(function (err: any, session: any) {
          if (err) {
            reject(err);
          }
          currentUser.changePassword(oldPassword, newPassword, (error, result) => {
            if (error) {
              reject(error);
            }
            resolve();
          });
        });
      } else {
        reject('user not authenticated');
      }

    });
  }

  static getCurrentUser() {
    this.checkInitialized("");
    const userPool = new CognitoUserPool({
      UserPoolId: this.USER_POOL_ID,
      ClientId: this.APP_CLIENT_ID
    });

    return userPool.getCurrentUser();
  }

  static MapCurrentUser(result: any) {
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId:this.IDENTITY_POOL,
      Logins : {['cognito-idp.' + this.REGION + '.amazonaws.com/' + this.USER_POOL_ID] : result.getIdToken().getJwtToken()}
    });
    // (AWS.config.credentials as Credentials).expired = true;
  }

  static async getCurrentUserSession() {
    this.checkInitialized("");
    const userPool = new CognitoUserPool({
      UserPoolId: this.USER_POOL_ID,
      ClientId: this.APP_CLIENT_ID
    });

    return new Promise<CognitoUserSession>((resolve, reject) => {
      const currentUser = userPool.getCurrentUser();
      if (currentUser) {

        currentUser.getSession(function (err: any, session: any) {
          let isValid=session.isValid();
          
          if (err) {
            reject(err);
          }
            resolve(session);
        });
      } else {
        reject('user not authenticated');
      }

    });
  }

  /**
 * 
 * @param userName 
 * @param password 
 * @returns 
 */
  static async confirmForgotPassword(userName: string, verificationCode: string, newPassword: string) {
    this.checkInitialized("");
    const userPool = new CognitoUserPool({
      UserPoolId: this.USER_POOL_ID,
      ClientId: this.APP_CLIENT_ID
    });
    const user = new CognitoUser({
      Username: userName,
      Pool: userPool
    });

    return new Promise<void>((resolve, reject) => {
      user.confirmPassword(verificationCode, newPassword, {
        onSuccess: result => {
          resolve();
        },
        onFailure: err => reject(err)
      });
    });
  }

  static async confirmRegistration(userName: string, code: string, clientMetaData?:{ [key: string]: string }) {
    this.checkInitialized("");
    const userPool = new CognitoUserPool({
      UserPoolId: this.USER_POOL_ID,
      ClientId: this.APP_CLIENT_ID
    });
    const user = new CognitoUser({
      Username: userName,
      Pool: userPool
    });

    return new Promise<void>((resolve, reject) => {
      user.confirmRegistration(code, true, (error, result) => {
        if (error) {
          reject(error);
        }
        resolve();
      },
      clientMetaData);
    });
  }

  static async resendConfirmationCode(userName: string) {
    this.checkInitialized("");
    const userPool = new CognitoUserPool({
      UserPoolId: this.USER_POOL_ID,
      ClientId: this.APP_CLIENT_ID
    });
    const user = new CognitoUser({
      Username: userName,
      Pool: userPool
    });

    return new Promise<void>((resolve, reject) => {
      user.resendConfirmationCode((error, result) => {
        if (error) {
          reject(error);
        }
        resolve();
      });
    });
  }

  static async signup(userName: string, password: string, userAttributes: any) {
    this.checkInitialized("");
    const userPool = new CognitoUserPool({
      UserPoolId: this.USER_POOL_ID,
      ClientId: this.APP_CLIENT_ID
    });

    let attributeList = Object.keys(userAttributes).map((key) => {
      let val = userAttributes[key];
      return new CognitoUserAttribute({ Name: key, Value: val });
    });

    return new Promise<void>((resolve, reject) => {
      userPool.signUp(userName, password, attributeList, [], (error, result) => {
        if (error) {
          reject(error);
        }
        resolve();
      });
    });
  }

  static addUserToGroup(userId: string, groupName: string): Promise<void> {
    this.checkInitialized("");
    return new Promise((resolve, reject) => {
      AWS.config.region = this.REGION; // Region
      // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      //   IdentityPoolId: this.IDENTITY_POOL
      // });
  
      const identityServiceProvider = new AWS.CognitoIdentityServiceProvider({
        region: AWSAuthenticationInterface.REGION
      });
  
      let params = {
        GroupName: groupName,
        UserPoolId: AWSAuthenticationInterface.USER_POOL_ID, /* required */
        Username: userId, /* required */
      };
      identityServiceProvider.adminAddUserToGroup(params, function (err: any, data: any) {
        if (err) {
          // an error occurred
          console.log(err, err.stack);
          reject(err.message);
        } else {
          resolve();
        }
      });
    });
  }

  static removeUserFromGroup(userId: string, groupName: string): Promise<void> {
    this.checkInitialized("");
    let result;
    let userPoolId = this.USER_POOL_ID;
    let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    let params = {
      GroupName: groupName,
      UserPoolId: userPoolId, /* required */
      Username: userId, /* required */
    };

    // Region needs to be set if not already set previously elsewhere.
    AWS.config.region = this.REGION;

    return new Promise((resolve, reject) => {
      cognitoidentityserviceprovider.adminRemoveUserFromGroup(params, function (err: any, data: any) {
        if (err) {
          // an error occurred
          console.log(err, err.stack);
          reject(err.message);
        } else {
          resolve();
        }
      });
    });
  }

  /*
  Retrieve the password policy of the Cognito user pool.
  */
  static getPasswordPolicy() {
    this.checkInitialized("");
    return new Promise<AWS.CognitoIdentityServiceProvider.PasswordPolicyType>((resolve, reject) => {

      AWS.config.region = this.REGION; // Region

      const identityServiceProvider = new AWS.CognitoIdentityServiceProvider();
      identityServiceProvider.describeUserPool({
        UserPoolId: AWSAuthenticationInterface.USER_POOL_ID,
      }, (error, data) => {
        if (error) {
          reject(error);
        } else {
          if (data && data.UserPool && data.UserPool.Policies && data.UserPool.Policies.PasswordPolicy) {
            resolve(data.UserPool.Policies.PasswordPolicy);
          }
          else {
            reject(new Error('Unable to fetch password policy'));
          }
        }
      });

      });
  }

}