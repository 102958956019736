// import { AccessManager } from "src/common/managers/AccessManager";
// import { Feature, SessionInfo } from "src/common/managers/Types";

export type SessionInfo = any;


export function FeaturetoAccess(session:SessionInfo,a:string):boolean {
   // check that any licensefeature is valid
   // let keys = Object.keys(Feature);
   // let testStatement= keys.includes(a);
   // if (!testStatement) {
   //   throw new Error("Unknown license feature: "+a);
   // }
   // return AccessManager.shouldFeatureBeAvailable(session, Feature[a]);
   return true;
 }

export function SortFeatureInfoFunc(sessionInfo:SessionInfo, x:FeatureHelpRecord, y:FeatureHelpRecord) {
   return (getAccessType(sessionInfo,x.licenseFeature) - getAccessType(sessionInfo,y.licenseFeature));
}

export type FeatureHelpFile = FeatureHelpRelease[];

export type FeatureHelpRelease = {
   releaseId:string,
   comment?:string,
   featureInfo: FeatureHelpRecord[]
   minorInfo?: MinorInfoRecord[]
};

export type MinorInfoRecord = {
   languageTable:{[languageCode:string]:{
      title:string,
      description:string | string[],
      imageId?:string,
   }}
};

export type FeatureHelpRecord = {
      licenseFeature?: string,
      languageTable:{[languageCode:string]:{
         title:string,
         description:string | string[],
         imageId:string,
         links: {
            title:string,
            url:string,
            type:string
         }[]
      }}
};



export enum accessType { noSpecialAcccessRequired=0, userHasAccess=1, userHasNoAccess=2}

export function getAccessType(sessionInfo:SessionInfo, licenseFeature?:string) {
   let access: accessType = accessType.noSpecialAcccessRequired;
   if (licenseFeature) {
     access = accessType.userHasNoAccess;
     if (FeaturetoAccess(sessionInfo, licenseFeature)) {
       access = accessType.userHasAccess;
     }
   }
   return access;
}
