import { Localization } from '../managers/Localization';

import {Alert, Modal} from 'react-bootstrap';
import * as React from 'react';

/**
 * Bootstrap 2/5.x implementation of ErrorMessage
 * @param message 
 * @param callbackOnClose 
 * @returns 
 */
export const ErrorMessage = ({message, callbackOnDismiss}:{message?:string, callbackOnDismiss?:()=>void}) => {
  return ( 
    message ? (
      <Modal show={(message && message.length > 0) || false} id="mit-error">
          <Modal.Header>
              {Localization.getTextSafeMode("Something went wrong...")}
          </Modal.Header>
          <Modal.Body>
              <h4 style={{width:"100%"}}>
                <Alert variant="warning" onClose={callbackOnDismiss} dismissible>
                {message}
                </Alert>
            </h4>      
          </Modal.Body>
        </Modal>
        ) : null
  );
    
};

