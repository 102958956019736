import { MouseEvent, useContext, useEffect, useMemo, useState } from "react";
import { POIS, PoiManager } from "../managers/PoiManager";
import { LicensingContext, shouldFeatureBeAvailable } from "@viamap/viamap2-common";
import { BsSortNumericDown, BsSortAlphaDown } from "react-icons/bs"
import { Cookies, Localization, Utils } from "@viamap/viamap2-common";
import { Button } from "react-bootstrap";

export enum TEventType {
  MouseMove ="MouseMove",
  MouseLeave ="MouseLeave",
  MouseClick ="MouseClick"
}

export enum SortKeys {
  Type = "Type",
  Dist = "Dist"
}

export type TMouseEventCallbackParams = {
  event: TEventType,
  pos: {x:number, y:number},
  type: string
}

type TDistancesParams = {
  callBack: (a:TMouseEventCallbackParams) => {}
}

export function Distances(props: TDistancesParams) {
  let [sort, setSort] = useState(SortKeys.Dist)
  let [subscription, setSubscription] = useState(0);
  let [customFilter, setCustomFilter] = useState<string[]>(JSON.parse(Cookies.getCookie("pFilter")||"[]")||[]);
  let [editView, setEditView] = useState(false)
  let [hasSearched, setHasSearched] = useState(false)
  let {state} = useContext(LicensingContext)

  function AddToFilter(ev:any,type:string) {
    ev.stopPropagation();
    setCustomFilter([...customFilter, type])
  }

  function RemoveFromFilter(ev:any,type:string) {
    ev.stopPropagation();
    setCustomFilter([...customFilter.filter((a) => a !== type)])
  }

  useEffect(() => {
    PoiManager.instance().subscribe("POS",setSubscription);
    PoiManager.instance().subscribe("POI",setSubscription);
  }, []);

  useEffect(() => {
    Cookies.setCookie("pFilter",JSON.stringify(customFilter))
  }, [customFilter])

  useEffect(() => {
    setEditView(false)
  }, [subscription])

  let PoiCards = useMemo(() => {
    function handleMouseMove(e : MouseEvent,type:string) {
      let {clientX : x, clientY : y} = e;
      props.callBack({type: type, pos: {x:x,y:y}, event: TEventType.MouseMove})
    }
  
    function handleMouseLeave(e : MouseEvent,type:string) {
      let {clientX : x, clientY : y} = e;
      props.callBack({type: type, pos: {x:x,y:y}, event: TEventType.MouseLeave})
    }
  
    function handleMouseClick(e : MouseEvent,type:string) {
      let {clientX : x, clientY : y} = e;
      props.callBack({type: type, pos: {x:x,y:y}, event: TEventType.MouseClick})
    }

    let sortFunc = (a:any,b:any) => a.routedmeters-b.routedmeters
    switch (sort) {
      case SortKeys.Dist:
        break;
      case SortKeys.Type:
        sortFunc = (a:any,b:any) => a[Localization.getlanguage()] > b[Localization.getlanguage()] ? 1 : -1;
    }

    let pTypes = PoiManager.instance().getTypes().filter((a) => a);
    let pTypeAccess = pTypes.filter((e) => {
      if (POIS[e].license) {
        return shouldFeatureBeAvailable((POIS[e].license as string), state)
      } return false;
    })
    let currentPos = PoiManager.instance().getCurrentPos()
    let pObjec = pTypeAccess.filter((e) => !customFilter.includes(e)).map((e) => PoiManager.instance().getPoi(e)).filter((e) => e.routedmeters).sort(sortFunc);
    return pObjec.map((elm) => { 
      if (elm === undefined) {
        return null
      }
      function distanceFormat(x:number):string {
        if (x > 1000) {
          return Math.round(x / 100)/10 + " km"
        }
        return Math.round(x) + " meter"
      } 
      if (elm.routedmeters === "" || elm.routedmeters === undefined || currentPos === undefined) {
        return (null)
      }
      let mouseOnMoveHandler = (ev:any) => handleMouseMove(ev,elm.type as string);
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        mouseOnMoveHandler = (ev:any) => {return};
      }
        // console.log(elm.routedmeters)
      return (
        <li key={elm.type as string} onMouseMove={mouseOnMoveHandler} onClick={(ev) => handleMouseClick(ev,elm.type as string)} onMouseLeave={(ev) => handleMouseLeave(ev,elm.type as string)} className={"distance clickable "+(elm.variant ? elm.variant:"")}>
          <div style={{position:"relative",bottom:"0px",height:"100%", width:"100%"}}>
            {/* <button style={{position:"absolute", color:"red", right:"0px", top:"0px"}} ><AiFillMinusCircle /></button> */}
            <h2 className="type">{(elm[Localization.getlanguage()] as string).replace(" og",' & ')}</h2>
            <p className="name">{elm.name || ""}</p>
            <div className="icon2btm">
              <img src={"/"+elm.icon+".png" as string || ""} alt="" />
              <p className="result">{distanceFormat(elm.routedmeters as number)}</p></div>
            </div>
        </li>
      );
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription, sort, state, customFilter, Localization.getlanguage()])

  function handleSortButton (a: SortKeys) {
    setSort(a);
  }

  let FakeCards:any = []
  let pTypes = PoiManager.instance().getTypes().filter((a) => a);
  let pObjec = pTypes.map((e) => {return {...POIS[e], 'type':e}});
  FakeCards = pObjec.map((elm : {[attr: string]: string | number | any[] | number[] | number[][]}) => { 
    let hasPoiAccess = shouldFeatureBeAvailable((elm.license as string), state) && "NoAccess";
    let isPoiDisabled = customFilter.includes(elm.type as string) && "Disabled";
    
    let action = (ev:any) => {alert("Køb adgang til følgende Poi: "+ elm[Localization.getlanguage()] )}
    if (hasPoiAccess) {
      if (isPoiDisabled) {
        action = (ev:any) => RemoveFromFilter(ev,elm.type as string)
      } else {
        action = (ev:any) => AddToFilter(ev,elm.type as string)
      }
    }
    return (
      <li key={elm.type as string} onClick={action} data-status={""} className={"distance fake "+hasPoiAccess+" "+isPoiDisabled+" clickable "}>
      <div style={{position:"relative",bottom:"0px",height:"100%", width:"100%"}}>
        {/* <div className="aktionKeeper">
          {hasPoiAccess ? (isPoiDisabled ? <AiFillPlusCircle onClick={} style={{color:"green"}} /> : <AiFillMinusCircle onClick={(ev) => AddToFilter(ev,elm.type as string)} style={{color:"red"}} /> ) : <></>}
        </div> */}
        <h2 className="type">{(elm[Localization.getlanguage()] as string).replace(" og",' & ')}</h2>
        <p className="name">{elm.name || ""}</p>
        <div className="icon2btm">
          <img src={"/"+elm.icon+".png" as string || ""} alt="" />
        </div>
      </div>
    </li>
    )
  })

  if (!hasSearched && PoiCards.length > 0) {
    setHasSearched(true)
  }

  return (
    <>
    <div className="wrapWidthWrapper">
    <div className="wrapWidth" >
      <div>

      {hasSearched ?
        <div style={{right:"0px", position:"absolute", fontSize:"16px"}}><div className="filterButton" onClick={() => setEditView(!editView)}>{Utils.formatString(Localization.getText("Show {a} of {b}"),{a:PoiCards.length, b:Object.keys(POIS).length})}</div></div>
        : null
      }
      { PoiCards.length > 0 && !editView ?
            <div className="SortButtons">
            <div className={"bi-sort-alpha-down "+(sort === SortKeys.Type ? "ActiveSort":"")} onClick={() => handleSortButton(SortKeys.Dist)}><BsSortAlphaDown />{Localization.getText("Category")}</div>
            <div className={"bi bi-sort-numeric-down "+(sort === SortKeys.Dist ? "ActiveSort":"")} onClick={() => handleSortButton(SortKeys.Type)}><BsSortNumericDown />{Localization.getText("Distance")}</div>
            </div>
            : null
          }
      </div>
    </div>
    </div>
    <div className="wrapper" style={{position:"relative"}}>
      {PoiCards.length > 0 && !editView ?
      <>
      {/* <div className="Exclusions">
        {EcludedCards}
      </div> */}

    <div className="TopInfo"><div>{Localization.getText("Distances")}</div><Button onClick={() => setEditView(!editView)} >{Localization.getText("Filter")}</Button></div>
    <ul className="distances">
      {PoiCards}
    </ul>
    </>:
    <>
    <div className="TopInfo"><div>{Localization.getText("Text:WhenEditingSeletingKategories")}</div>
    {hasSearched ? <Button onClick={() => setEditView(!editView)} >{Localization.getText("Finish")}</Button> :
        <></>
    }
    </div>
    <ul className="distances">
      {FakeCards}
     </ul>
    </>
     }
    </div>
    </>
  );
}