import { Localization } from '../managers/Localization';
import * as React from 'react';

import { useContext } from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import { actionLogoff } from "../states/sessionState/actions";
import { SessionContext } from "../states/sessionState/context";
import { LicensingContext, addLicense, ViamapProduct, LicenseType, daysToExpiration, initialize } from "../states/ViamapLicensingState";
import './LicenseStateView.css';
import { MainApplicationFrame } from "./MainApplicationFrame";
import { actionSetInfoMessage, AppMessagesContext } from "../states/AppMessagesState";

export const LicenseStateCompact = () => {
   let { state: licensingState, dispatch: licensingDispatch } = useContext(LicensingContext);

   if (licensingState.currentlicense) {
      return (
         <div>
            <span className="productVariant">{licensingState.currentlicense.productVariant}</span>
            {licensingState.currentlicense.expires && licensingState.currentlicense.expires < new Date() ?
               (<><br /><h2>{Localization.getText("Expired!")}</h2></>) :
               licensingState.currentlicense.expires && licensingState.currentlicense.licenseType === LicenseType.Trial ?
                  (<><br /><div className="text-muted">{Localization.getFormattedText("Expires in {days} days", { days: daysToExpiration(licensingState.currentlicense) || 0 })}</div></>) : null}
         </div>
      )
   } else {
      return (
         <div>
            {Localization.getText("No license!")}
         </div>
      );
   }
}

export const LicenseStateView = (props: { productTeaser: any }) => {
   let { state: licensingState, dispatch: licensingDispatch } = useContext(LicensingContext);
   let { state: sessionState, dispatch: sessionDispatch } = useContext(SessionContext);
   let { state: appMessageState, dispatch: appMessageDispatch } = useContext(AppMessagesContext);

   function handleTrial(product: ViamapProduct, durationDays:number) {
      const expiration = new Date((new Date()).setDate((new Date()).getDate() + durationDays));
      let newTrial = {
         apiVersion: "1.0",
         product: ViamapProduct.HvorLangtErDer,
         productVariant: "Trial",
         active: true,
         expires: expiration,
         licenseType: LicenseType.Trial,
         note: "Self Signup from Hvorlangt er der"
      };
      licensingDispatch(addLicense(sessionState.userRef, newTrial, ()=>{
         appMessageDispatch(actionSetInfoMessage(Localization.getText("Trial Started")));
      }));
   }

   function handleCreateLicense(product: ViamapProduct, variant:string) {
      // one year default
      const expiration = new Date((new Date()).setFullYear((new Date()).getFullYear() + 1));
      let newLicense = {
         apiVersion: "1.0",
         product: product,
         productVariant: variant,
         active: true,
         expires: expiration,
         licenseType: LicenseType.Subscription,
         note: "Test Subscription Signup from Hvorlangt er der"
      };
      licensingDispatch(addLicense(sessionState.userRef, newLicense, ()=>{
         appMessageDispatch(actionSetInfoMessage(Localization.getText("Test Subscription Started")))
      }));
   }

   function handleBuy() {

   }

   function handleLogout() {
      sessionDispatch(actionLogoff(sessionState.userRef));
   }

   const LicenseContents = () => {
      if (licensingState.currentlicense) {
         return (
            <>
               <h2>User: {sessionState.userName}</h2>
               <h2>Organization: {sessionState.customerRef}</h2>
               <hr />
               <h3>Produkt {licensingState.currentlicense.product}</h3>
               <h3>ProduktVariant {licensingState.currentlicense.productVariant}</h3>
               <h3>Type {licensingState.currentlicense.licenseType}</h3>
               <h3>Note {licensingState.currentlicense.note}</h3>
               <h4>Udlober {licensingState.currentlicense.expires.toLocaleDateString()}</h4>
               {licensingState.currentlicense.expires && licensingState.currentlicense.expires < new Date() ? (<h2>Din licens er udløbet</h2>) : null}
            </>
         )
      } else {
         return (
            <>
               {props.productTeaser || null}
               {/* <hr /> */}
               <div style={{display:"flex", justifyContent:"center"}}>
               <Button style={{}} onClick={()=>handleTrial(ViamapProduct.HvorLangtErDer, 14)}>Start 14 dages gratis prøvelicens</Button>
               </div>
               {/* <h1>Kontakt viamap...</h1>
               <hr />
               <Button onClick={handleBuy}>Køb licens</Button>
               <Button onClick={()=>handleCreateLicense(ViamapProduct.HvorLangtErDer, "Basic")}>Opret Basic Licens</Button>
               <Button onClick={()=>handleCreateLicense(ViamapProduct.HvorLangtErDer, "Pro")}>Opret Pro Licens</Button>
               <Button onClick={handleBuy}>Køb licens</Button>
               <Button onClick={handleLogout}>Logout</Button> */}
            </>
         )
      }
   }

   return (
      <>
         <MainApplicationFrame
            applicationName={"Hvorlangterder"}
            children={[]} 
            productNameAndVersion={""} 
            newUserInformation={[]}
            releaseInformation={[]}
         />
         <hr />
         <LicenseContents/>
         <hr/>
      </>
   );
}



export const DebugModalLicense = (props:{callbackClose: () => void}) => {
   let { state: licensingState, dispatch: licensingDispatch } = useContext(LicensingContext);
   let { state: sessionState, dispatch: sessionDispatch } = useContext(SessionContext);
   let { state: appMessageState, dispatch: appMessageDispatch } = useContext(AppMessagesContext);

   function handleTrial(durationDays:number) {
      const expiration = new Date((new Date()).setDate((new Date()).getDate() + durationDays));
      let newTrial = {
         apiVersion: "1.0",
         product: ViamapProduct.HvorLangtErDer,
         productVariant: "Trial",
         active: true,
         expires: expiration,
         licenseType: LicenseType.Trial,
         note: "Self Signup from Hvorlangt er der"
      };
      licensingDispatch(addLicense(sessionState.userRef, newTrial, ()=>{appMessageDispatch(actionSetInfoMessage(Localization.getText("Trial Started")))}));
   }

   function handleCreateLicense(variant:string) {
      // one year default
      const expiration = new Date((new Date()).setFullYear((new Date()).getFullYear() + 1));
      let newLicense = {
         apiVersion: "1.0",
         product: ViamapProduct.HvorLangtErDer,
         productVariant: variant,
         active: true,
         expires: expiration,
         licenseType: LicenseType.Subscription,
         note: "Test Subscription Signup from Hvorlangt er der"
      };
      licensingDispatch(addLicense(sessionState.userRef, newLicense, ()=>{appMessageDispatch(actionSetInfoMessage(Localization.getText("Test Subscription Started")))}));
   }

   function handleBuy() {

   }

   function handleLogout() {
      sessionDispatch(actionLogoff(sessionState.userRef));
   }

   const LicenseContents = () => {
         return (
            <>
            <Modal show={true}>
               <h1>DEBUG MENU</h1>
               <Button onClick={()=>handleTrial(14)}>Start 14 dages gratis prøvelicens</Button>
               <Button onClick={()=>handleCreateLicense("Basic")}>Opret Basic Licens</Button>
               <Button onClick={()=>handleCreateLicense("Pro")}>Opret Pro Licens</Button>
               <Button onClick={handleLogout}>Logout</Button>
               <Button onClick={() => props.callbackClose()} >CLOSE</Button>
            </Modal>
            </>
         )
   }

   return (
      <>
         <LicenseContents/>
      </>
   );
}