import { Localization } from '../managers/Localization';
import { SettingsManager} from '../managers/SettingsManager';
import { Utils } from '../managers/Utils';

import * as React from "react";
import { LicenseManager } from "../managers/LicenseManager";
import { Chance } from 'chance';

export type ViamapLicenseRegistry = ViamapLicense[];

export enum MessageType {
   Error = "Error",
   Success = "Success"
}

export enum ViamapProduct {
   Mapit = "Mapit",
   Portal = "Portal",
   MapitAdmin = "MapitAdmin",
   POIAdmin = "POIAdmin",
   HvorLangtErDer = "HvorLangtErDer"
}

// export const Product2AccessRole: {[product:string]:string|undefined} = {
//    [ViamapProduct.Mapit]: undefined,
//    [ViamapProduct.Portal]: "Portal",
//    [ViamapProduct.MapitAdmin]: "Administrator",
//    [ViamapProduct.POIAdmin]: "POIAdministrator",
//    [ViamapProduct.HvorLangtErDer]: "HvorLangtErDer"
// }

export enum LicenseType {
   Trial = "Trial",
   Subscription = "Subscription",
   OneOff = "OneOff"
}

export type ViamapLicense = {
   apiVersion: string,
   product: ViamapProduct,
   productVariant: string,
   active: boolean,
   expires: Date,
   licenseType: LicenseType,
   note: string
}

// ---------------------------------------------------- STATE --------------------------------------------------------------

export interface ViamapLicensingState {
   product: ViamapProduct,
   currentlicense?: ViamapLicense,
   licenseRegistry?: ViamapLicenseRegistry,
   hasValidLicense: boolean,
   activeTransactions: { [guid: string]: ViamapLicensingActions },
   message: string,
   messageType: MessageType
}

function invariant(licenseRegistry: ViamapLicenseRegistry):boolean {
   // Only one active License per Product
   // todo: implement
   return false;
}

// ---------------------------------------------------- UTILITY FUNCTIONS --------------------------------------------------------------

/** Returns undefined if license is perpetual. Negative number is returned if already expired */
export function daysToExpiration(license:ViamapLicense): number|undefined {
   let now = new Date();
   let differenceMilliseconds = license.expires.getTime() - now.getTime();
   let inDays = Math.ceil(differenceMilliseconds / (1000 * 3600 * 24));
   return inDays;
}

export function shouldFeatureBeAvailable(feature:string, licensingState:ViamapLicensingState): boolean {
   if (!licensingState.hasValidLicense) {
      // No license. No access
      return false;
   }
   const appSettings = SettingsManager.getSystemSetting("applicationSettings",undefined);
   if (!appSettings) {
      throw new Error("'applicationSettings' not loaded");
   }
   if (!Object.keys(appSettings.features).includes(feature)) {
      // not a known feature kode
      throw new Error("Unknown Feature code: "+feature);
   }
   const variant = licensingState.currentlicense!.productVariant;
   const featuresInVariant = appSettings.licenseVariantToFeatures[variant] || [];
   return featuresInVariant.includes(feature);
}

// ---------------------------------------------------- INITIAL STATE --------------------------------------------------------------

export function initialViamapLicensingState(): ViamapLicensingState {

   return {
      product: "UNKNOWN" as ViamapProduct,
      hasValidLicense: false,
      activeTransactions: {},
      message: "",
      messageType: MessageType.Success
   };
}

// ---------------------------------------------------- ACTIONS --------------------------------------------------------------
export enum ViamapLicensingActionType {
   TxStarted,
   TxCompleted,
   TxFailed,

   initialize,
   addLicense
}

export interface TxStarted {
   type: ViamapLicensingActionType.TxStarted;
   payload: { guid: string, action: ViamapLicensingActions };
}

export interface TxCompleted {
   type: ViamapLicensingActionType.TxCompleted;
   payload: { guid: string, action: ViamapLicensingActions };
}

export interface TxFailed {
   type: ViamapLicensingActionType.TxFailed;
   payload: { guid: string, action: ViamapLicensingActions, error: any };
}

export interface initialize {
   type: ViamapLicensingActionType.initialize;
   payload: { product: ViamapProduct, userEmail: string};
   results?: { registry?: ViamapLicenseRegistry};
}

export interface addLicense {
   type: ViamapLicensingActionType.addLicense;
   payload: { userEmail: string, data: ViamapLicense, onSuccess:()=>void };
   results?: { registry?: ViamapLicenseRegistry};
}

// -------------------- utility functions to create an Action object ---------------------------------

export const actionTxStarted = (guid: string, action: ViamapLicensingActions): TxStarted => ({
   type: ViamapLicensingActionType.TxStarted,
   payload: { guid, action }
});

export const actionTxCompleted = (guid: string, action: ViamapLicensingActions): TxCompleted => ({
   type: ViamapLicensingActionType.TxCompleted,
   payload: { guid, action }
});

export const actionTxFailed = (guid: string, action: ViamapLicensingActions, error: any): TxFailed => ({
   type: ViamapLicensingActionType.TxFailed,
   payload: { guid, action, error }
});

export const initialize = (product: ViamapProduct, userEmail: string): initialize => ({
   type: ViamapLicensingActionType.initialize,
   payload: { product, userEmail }
});

export const addLicense = (userEmail: string, data: ViamapLicense, onSuccess:()=>void): addLicense => ({
   type: ViamapLicensingActionType.addLicense,
   payload: { userEmail, data: data, onSuccess }
});

export type ViamapLicensingActionsTransActional = initialize | addLicense;
export type ViamapLicensingActionsNonTransActional = TxStarted | TxCompleted | TxFailed;
export type ViamapLicensingActions = ViamapLicensingActionsTransActional | ViamapLicensingActionsNonTransActional;

// ---------------------------------------------------- ACTIONS --------------------------------------------------------------


// ---------------------------------------------------- TRANSACTIONAL REDUCER --------------------------------------------------------------

export function transactionalViamapLicensingReducer(action: ViamapLicensingActionsTransActional, dispatch: any) {

   switch (action.type) {
      case ViamapLicensingActionType.initialize: {
         let guid = new Chance().guid();
         dispatch(actionTxStarted(guid, action));
         LicenseManager.getUserLicenses(action.payload.userEmail)
            .then(response => {
               dispatch(actionTxCompleted(guid, action));
               action.results = action.results || {};
               action.results.registry = response as ViamapLicenseRegistry || [];
               dispatch(action);
            })
            .catch((error) => {
               dispatch(actionTxFailed(guid, action, error));
               action.results = action.results || {};
               action.results.registry = [];
               dispatch(action);
            });
      }
         break;

      case ViamapLicensingActionType.addLicense: {
         let guid = new Chance().guid();
         dispatch(actionTxStarted(guid, action));
         LicenseManager.getUserLicenses(action.payload.userEmail)
            .then(response => {
               let newlicense = action.payload.data;
               let newRegistry: ViamapLicenseRegistry = response || [];
               // disable any existing licenses of the same type
               newRegistry = newRegistry.map((lic) => {
                  if (lic.product === newlicense.product) {
                     return { ...lic, active: false };
                  } else {
                     return lic;
                  }
               });
               newRegistry.push(newlicense);
               LicenseManager.persistUserLicenses(action.payload.userEmail, newRegistry)
                  .then(response => {
                     dispatch(actionTxCompleted(guid, action));
                     action.results = action.results || {};
                     action.results.registry = newRegistry;
                     dispatch(action);
                  })
                  .catch((error) => {
                     dispatch(actionTxFailed(guid, action, error));
                  });
            })
            .catch((error) => {
               // Not found assuming no file present.
               let newlicense = action.payload.data;
               let newRegistry: ViamapLicenseRegistry = [];
               // disable any existing licenses of the same type
               newRegistry = newRegistry.map((lic) => {
                  if (lic.product === newlicense.product) {
                     return { ...lic, active: false };
                  } else {
                     return lic;
                  }
               });
               newRegistry.push(newlicense);
               LicenseManager.persistUserLicenses(action.payload.userEmail, newRegistry)
                  .then(response => {
                     dispatch(actionTxCompleted(guid, action));
                     action.results = action.results || {};
                     action.results.registry = newRegistry;
                     dispatch(action);
                  })
                  .catch((error) => {
                     dispatch(actionTxFailed(guid, action, error));
                  });
               // dispatch(actionTxFailed(guid, action, error));
            });
      }
         break;

      default:
         // proceed directly to non-transational reducer for other actions
         dispatch(action);
   }
}

// ---------------------------------------------------- REDUCER --------------------------------------------------------------

export function ViamapLicensingReducer(state: ViamapLicensingState, action: ViamapLicensingActions): ViamapLicensingState {
   function enum2String(type: any, value: number): string {
      return Object.values<string>(type)[value];
   }

   switch (action.type) {

      case ViamapLicensingActionType.TxStarted:
         return {
            ...state,
            activeTransactions: { ...state.activeTransactions, [action.payload.guid]: action }
         };

      case ViamapLicensingActionType.TxFailed: {
         let tmp = { ...state.activeTransactions };
         delete tmp[action.payload.guid];
         console.log(action.payload.error);
         let msg = Utils.formatString(Localization.getTextSafeMode("Error. Transaction {txname} returned with {error}"), {
            txname: Localization.getTextSafeMode(ViamapLicensingActionType[action.payload.action.type]),
            error: Localization.getTextSafeMode(action.payload.error)
         });
         // Logger.logError("viamapLiceningState", ViamapLicensingActionType[action.payload.action.type], msg);
         return {
            ...state,
            activeTransactions: tmp,
            message: msg,
            messageType: MessageType.Error
         };
      }

      case ViamapLicensingActionType.TxCompleted: {
         let tmp = { ...state.activeTransactions };
         delete tmp[action.payload.guid];
         return {
            ...state,
            activeTransactions: tmp,
            message: ""
         };
      }

      case ViamapLicensingActionType.initialize: {
         if (action.results && action.results.registry) {
            let lic = LicenseManager.extractCurrentLicense(action.payload.product, action.results.registry);
            return {
               ...state,
               licenseRegistry: action.results.registry,
               currentlicense: lic,
               hasValidLicense: lic && lic.active,
               product : action.payload.product
            }
         } else {
            return {
               ...state,
               licenseRegistry: [],
               currentlicense: undefined,
               hasValidLicense: false,
               product : action.payload.product
            }
         };
         break;
      }

      case ViamapLicensingActionType.addLicense: {
         action.payload.onSuccess && action.payload.onSuccess();
         if (action.results && action.results.registry) {
            let lic = LicenseManager.extractCurrentLicense(state.product, action.results.registry);
            return {
               ...state,
               licenseRegistry: action.results.registry,
               currentlicense: lic,
               hasValidLicense: lic && lic.active
            }
         } else {
            return {
               ...state,
            };
         }
         break;
      }

      default:
         throw new Error("Unknown action " + (action as ViamapLicensingActions).type);
   }
}

// ---------------------------------------------------- generic stuff --------------------------------------------------------------

export const LicensingContext = React.createContext<{
   state: ViamapLicensingState;
   dispatch: React.Dispatch<ViamapLicensingActions>;
}>({
   state: initialViamapLicensingState(),
   dispatch: () => undefined,
});

