import { SessionActions, SessionActionsTransActional, SessionActionType } from './actions';
import {Chance} from 'chance';
import { actionTxCompleted, actionTxFailed, actionTxStarted } from './actions';
import {AWSAuthenticationInterface} from '../../managers/AWSAutenticationInterface';

export function transactionalSessionReducer(action: SessionActionsTransActional, dispatch: any) {
    switch (action.type) {
        case SessionActionType.Login:
            let guid = new Chance().guid();
            dispatch(actionTxStarted(guid, action));
            AWSAuthenticationInterface.login(action.payload.userName, action.payload.password)
                .then((userSession) => {
                    action.results = { userSession };
                    dispatch(actionTxCompleted(guid, action));
                    dispatch(action);
                })
                .catch((error) => {
                    dispatch(actionTxFailed(guid, action, error));
                });
            break;

            case SessionActionType.Logoff: {
                let guid = new Chance().guid();
                dispatch(actionTxStarted(guid, action));
                AWSAuthenticationInterface.logoff(action.payload.userName)
                .then(() => {
                    dispatch(actionTxCompleted(guid, action));
                    dispatch(action);
                })
                .catch((error) => {
                    dispatch(actionTxFailed(guid, action, error));
                });
            }
            break;

            case SessionActionType.Signup: {
                let guid = new Chance().guid();
                dispatch(actionTxStarted(guid, action));
                AWSAuthenticationInterface.signup(
                    action.payload.userName,
                    action.payload.password, 
                    {
                        "name":action.payload.name,
                        "custom:company":action.payload.company,
                        "custom:phone":action.payload.phone,
                        "custom:mobilnummer":action.payload.phone,
                        "custom:note":"Self signup on",
                        "custom:licenseType":action.payload.licenseType,
                        "custom:expirationDate": action.payload.expiration ? action.payload.expiration.toLocaleDateString() : "",
                        "custom:termsApproved":"true",
                        "custom:environment":action.payload.product,
                    }
                )
                .then(() => {
                    action.payload.userGroup && AWSAuthenticationInterface.addUserToGroup(action.payload.userName, action.payload.userGroup);
                }) 
                .then(() => {
                    dispatch(actionTxCompleted(guid, action));
                    dispatch(action);
                })
                .catch((error) => {
                    dispatch(actionTxFailed(guid, action, error));
                });
            }
            break;

            case SessionActionType.SignupVerification: {
                let guid = new Chance().guid();
                dispatch(actionTxStarted(guid, action));
                AWSAuthenticationInterface.confirmRegistration(
                    action.payload.userName,
                    action.payload.code,
                    action.payload.clientMetaData)
                .then(() => {
                    dispatch(actionTxCompleted(guid, action));
                    dispatch(action);
                })
                .catch((error) => {
                    dispatch(actionTxFailed(guid, action, error));
                });
            }
            break;

            case SessionActionType.SignupResend: {
                let guid = new Chance().guid();
                dispatch(actionTxStarted(guid, action));
                AWSAuthenticationInterface.resendConfirmationCode(
                    action.payload.userName)
                .then(() => {
                    dispatch(actionTxCompleted(guid, action));
                    dispatch(action);
                })
                .catch((error) => {
                    dispatch(actionTxFailed(guid, action, error));
                });
            }
            break;

            case SessionActionType.ChangePassword: {
                let guid = new Chance().guid();
                dispatch(actionTxStarted(guid, action));
                AWSAuthenticationInterface.changePassword(
                    action.payload.userName,
                    action.payload.oldPassword,
                    action.payload.newPassword)
                .then(() => {
                    dispatch(actionTxCompleted(guid, action));
                    dispatch(action);
                })
                .catch((error) => {
                    dispatch(actionTxFailed(guid, action, error));
                });
            }
            break;

            case SessionActionType.ForgotPassword: {
                let guid = new Chance().guid();
                dispatch(actionTxStarted(guid, action));
                AWSAuthenticationInterface.forgotPassword(
                    action.payload.userName)
                .then(() => {
                    dispatch(actionTxCompleted(guid, action));
                    dispatch(action);
                })
                .catch((error) => {
                    dispatch(actionTxFailed(guid, action, error));
                });
            }
            break;

            case SessionActionType.ForgotPasswordVerification: {
                let guid = new Chance().guid();
                dispatch(actionTxStarted(guid, action));
                AWSAuthenticationInterface.confirmForgotPassword(
                    action.payload.userName, action.payload.code, action.payload.newPassword)
                .then(() => {
                    dispatch(actionTxCompleted(guid, action));
                    dispatch(action);
                })
                .catch((error) => {
                    dispatch(actionTxFailed(guid, action, error));
                });
            }
            break;

        default:
            // proceed directly to non-transational reducer for other actions
            dispatch(action);
    }
}
