import { useContext, useEffect } from "react";
import * as React from "react";

import { SessionContext } from "../states/sessionState/context";
import { LicensingContext, ViamapProduct, initialize } from "../states/ViamapLicensingState";
import { InfoMessage } from "./InfoMessage";
import { LicenseStateView } from "./LicenseStateView";
import { LoadingScreen } from "./LoadingScreen";

type Props = {
   product:ViamapProduct,
   productTeaser:any,
   children: any
}

export function LicensingBoundary(props:Props) {
   let { state: sessionState, dispatch: sessionDispatch } = useContext(SessionContext);
   let { state: licensingState, dispatch: licensingDispatch} = useContext(LicensingContext);

   useEffect(() => {
      // Retrieve licenses for current user. Called when active user changes.
      sessionState.userRef && licensingDispatch(initialize(props.product, sessionState.userRef));
   }, [sessionState.userRef])

   if (licensingState.activeTransactions && Object.keys(licensingState.activeTransactions).length > 0) {
      // busy working on licenses
      return (
         // ToDo: translation
         <InfoMessage message="Loading"/>
      );
   }
   if (licensingState.hasValidLicense) {
      return props.children;
   } else {
      return LicenseStateView({productTeaser:props.productTeaser});
   }
}