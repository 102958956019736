import {
    SessionActions, SessionActionType, SetLoginDialogMode, TxFailed, TxCompleted, TxStarted
} from "./actions";
import { LoginDialogMode, MessageType, SessionState } from "./state";
import { FeatureRoleManager } from "./features";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Cookies } from '../../managers/Cookies';
import { Localization } from '../../managers/Localization';
import { Utils } from '../../managers/Utils';

export function sessionReducer(state: SessionState, action: SessionActions): SessionState {

    function getUserSessionStates(userSession:CognitoUserSession):any {
        let payload = userSession.getIdToken().payload;
        let email = payload.email;
        let domain = payload.email.split("@")[1];
        let user = payload.name;
        let groups = payload["cognito:groups"];
        return {
            userRoles: groups,
            featureSet: FeatureRoleManager.getFeaturesForRoles(groups),
            userName: user,
            userRef: email,
            customerRef: domain
        };
    }

    switch (action.type) {

        case SessionActionType.TxStarted:
            return {
                ...state,
                activeTransactions: { ...state.activeTransactions, [action.payload.guid]: action }
            };

        case SessionActionType.TxFailed: {
            let tmp = { ...state.activeTransactions };
            delete tmp[action.payload.guid];
            console.log(action.payload.error);
            let msg = Utils.formatString(Localization.getTextSafeMode("Error. Transaction {txname} returned with {error}"), { txname: Localization.getTextSafeMode(SessionActionType[action.payload.action.type]), error: Localization.getTextSafeMode(action.payload.error)})
            // Logger.logError("SessionState",SessionActionType[action.payload.action.type],msg)
            return {
                ...state,
                activeTransactions: tmp,
                message: msg,
                messageType: MessageType.Error
            };
        }

        case SessionActionType.TxCompleted: {
            let tmp = { ...state.activeTransactions };
            delete tmp[action.payload.guid];
            return {
                ...state,
                activeTransactions: tmp,
                message: ""
            };
        }

        case SessionActionType.Login:
            let payload = action.results!.userSession.getIdToken().payload;
            return {
                ...state,
                userSession: action.results && action.results.userSession,
                loginMode: LoginDialogMode.NotDisplayed,
                message: Utils.formatString(Localization.getText("User {user} logged in"), { user: payload.name }),
                messageType: MessageType.Success,
                // Store user roles and access rights on session
                ...getUserSessionStates(action.results!.userSession)
            };

        case SessionActionType.Logoff:
            return {
                ...state,
                userSession: undefined,
                userRoles: [],
                featureSet: [],
                loginMode: LoginDialogMode.Login,
                message: Localization.getText("User logged out"),
                messageType: MessageType.Success,
                customerRef: "",
                userRef: "",
            };

        case SessionActionType.Signup:
            return {
                ...state,
                loginMode: LoginDialogMode.SignupEnterCode,
                message: Localization.getText("Email sent"),
                messageType: MessageType.Success
            };

        case SessionActionType.SignupVerification:
            return {
                ...state,
                loginMode: LoginDialogMode.Login,
                message: Localization.getText("Useraccount created"),
                messageType: MessageType.Success
            };

        case SessionActionType.SignupResend:
            return {
                ...state,
                loginMode: LoginDialogMode.SignupEnterCode,
                message: Localization.getText("Email re-sent"),
                messageType: MessageType.Success
            };

        case SessionActionType.ChangePassword:
            return {
                ...state,
                loginMode: LoginDialogMode.NotDisplayed,
                message: Localization.getText("Password changed"),
                messageType: MessageType.Success
            };

        case SessionActionType.ForgotPassword:
            return {
                ...state,
                loginMode: LoginDialogMode.ForgotPasswordEnterCode,
                message: Localization.getText("Email sent"),
                messageType: MessageType.Success
            };

        case SessionActionType.ForgotPasswordVerification:
            return {
                ...state,
                loginMode: LoginDialogMode.Login,
                message: "",
            };

        case SessionActionType.SetLoginDialogMode:
            return {
                ...state,
                loginMode: action.payload.mode,
                message: "",
            };

        case SessionActionType.SetUserSession:
            return {
                ...state,
                userSession: action.payload.session,
                message: "setting session",
                // Store user roles and access rights on session
                ...getUserSessionStates(action.payload.session)
            };

        case SessionActionType.SetLanguage:
            Localization.setLanguage(action.payload.languageCode);
            Cookies.setCookie("DefaultLanguage",action.payload.languageCode);
            return {
                ...state,
                languageCode: action.payload.languageCode
            };

        default:
            throw new Error("Unknown action " + (action as SessionActions).type);
    }
}

