import { useContext, useReducer, useState } from 'react';
import * as React from "react";
import { Image, Container, Navbar, NavDropdown } from 'react-bootstrap';
import { FaUserAlt } from 'react-icons/fa'

import { actionLogoff, actionSetLoginDialogMode } from '../states/sessionState/actions';
import { LoginDialogMode } from '../states/sessionState/state';
import { SessionContext } from '../states/sessionState/context';
import { AutomaticUserHelpPopup } from './AutomaticUserHelpPopup';
import { LanguageSelector } from './LanguageSelector';
import { LicensingContext } from '../states/ViamapLicensingState';
import { DebugModalLicense, LicenseStateCompact } from './LicenseStateView';
import './MainApplicationFrame.css';
import { ReleaseViewer } from './ReleaseViewer';
import { Localization } from '../managers/Localization';
import { FeatureHelpFile } from '../managers/FeatureHelpManager';
import { GenericTransactionManager } from '../managers/GenericTransactionManager';
import { UserHelpReducer, initialUserHelpState, UserHelpContext, UserHelpActions, transactionalUserHelpReducer, initialize } from '../states/UserHelpState';

export type Props = {
   applicationName: string,
   productNameAndVersion: string,
   children: any,
   newUserInformation:FeatureHelpFile,
   releaseInformation:FeatureHelpFile,
   hideLicenseInformation?:boolean
}

export type ViamapMenuData = {[key:string]:ViamapMenuItemData};

type ViamapMenuItemData = {
   feature?:string, // required access feature
   onClick?:any,
   title:string,
   sequenceNo?:number
};

export const MainApplicationFrame = (props: Props) => {
   const { state: sessionState, dispatch: sessionDispatch } = useContext(SessionContext);
   const { state: licensingState, dispatch: licencingDispatch } = useContext(LicensingContext);
   let [showReleases, setShowReleases] = useState(false);
   let [showFeatures, setShowFeatures] = useState(false);
   let [showLicense, setShowLicense] = useState(false);
   const [userHelpState, userHelpDispatch] = useReducer(UserHelpReducer, initialUserHelpState());

   React.useEffect(() => {
      if (props.releaseInformation && props.newUserInformation) {
         userHelpDispatch(initialize(props.newUserInformation, props.releaseInformation));
      }
   },[])


   function handleCloseFeatureViewr() {
      setShowFeatures(false);
      setShowReleases(false);
   }

   function handleOpenGettingstarted(a:boolean) {
      setShowFeatures(true);
      setShowReleases(a)
   }

   function handleLogout() {
      sessionDispatch(actionLogoff(sessionState.userRef));
   }

   function handleChangePassword() {
      sessionDispatch(actionSetLoginDialogMode(LoginDialogMode.ChangePassword));
   }

   function closeLicense() {
      setShowLicense(false)
   }

   
   
   function handleLicenses() {
   //  setActivePopup("licenses");
   }

   return (
            <UserHelpContext.Provider 
         value={{ 
            state: userHelpState, 
            dispatch: GenericTransactionManager.dispatchMiddleware<UserHelpActions>(userHelpDispatch, transactionalUserHelpReducer) 
         }}
      >

         <Navbar style={{ backgroundColor: "rgb(58,142,135" }} bg="light" expand="md">
            <Container>
               <Navbar.Brand style={{ float: "left" }} href="#home"><Image height="50px" src={"viamapLogo.png"} /></Navbar.Brand>
               <div style={{ fontSize: "36pt" }}>{props.applicationName}</div>
               {props.hideLicenseInformation ? null : (
                  <div><LicenseStateCompact/></div>
               )}
               <NavDropdown style={{ float: "right" }} id="basic-nav-dropdown"
                  title={
                     <div className="pull-left">
                        <FaUserAlt size={20} />
                        {sessionState.userName}
                     </div>
                  } >
                  <NavDropdown.Item onClick={handleLogout}>{Localization.getText("Logout")}</NavDropdown.Item>
                  <NavDropdown.Item onClick={handleChangePassword}>{Localization.getText("Change Password")}</NavDropdown.Item>
                  {props.hideLicenseInformation ? null : (
                  <NavDropdown.Item onClick={() => setShowLicense(true)}>{Localization.getText("Licenses")}DEBUG</NavDropdown.Item>
                  )}
                  <NavDropdown.Item onClick={() => handleOpenGettingstarted(true)}>{Localization.getText("Usage Guide")}</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleOpenGettingstarted(false)}>{Localization.getText("Releases")}</NavDropdown.Item>
               </NavDropdown>
               <LanguageSelector/>
            </Container>
         </Navbar>
         
         {props.children}
         <AutomaticUserHelpPopup
                sessionInfo={sessionState}
                />
         <ReleaseViewer 
            sessionInfo={sessionState}
            onClose={() => handleCloseFeatureViewr()}
            show={showFeatures}
            gettingStarted={showReleases}
            expandRelease={(a) => {return}}
            />
         {
            showLicense ?
            <DebugModalLicense 
            callbackClose={() => closeLicense()}
            />
            : null
         }
   </UserHelpContext.Provider>
   )
} 