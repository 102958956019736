import * as React from 'react';

type Props = {
  children:any
}

type State={ hasError:boolean;
  message?:string;
  stack?:string;
}

export class ErrorBoundary extends React.Component<Props,State> {
    constructor(props:any) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error:any) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true,
      message:error.message,
      stack: error.error && error.error.stack };
    }
  
    /* eslint-disable-next-line class-methods-use-this */
    componentDidCatch(error:any, errorInfo:any) {
      // You can also log the error to an error reporting service
      alert("Caught error: "+ error.message);
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
        <>
          <h1>Something went wrong.</h1>
          <h3>Message: {this.state.message}</h3>
          <hr/>
          <div style={{overflowWrap:"break-word"}}>Stack trace: {this.state.stack}</div>
          </>
          );
      }
  
      return this.props.children; 
    }
  }