import * as React from 'react';
import { Modal, Alert, Row } from 'react-bootstrap';
import { Localization } from '../managers/Localization';

type State = {
   showWindow: boolean;
   message: string;
   callbackOnCancel: () => void;
   callbackOnCommit: () => void;
   className?: string;
};

type Props = State;

export class ConfirmationDialog extends React.Component<Props, State> {

   constructor(props: Props) {
      super(props);

      this.state = {
         showWindow: props.showWindow,
         message: props.message,
         callbackOnCancel: props.callbackOnCancel,
         callbackOnCommit: props.callbackOnCommit,
         className: props.className,
      };

   }

   componentWillReceiveProps(props: Props) {
      this.setState({
         showWindow: props.showWindow,
         message: props.message,
         callbackOnCancel: props.callbackOnCancel,
         callbackOnCommit: props.callbackOnCommit,
         className: props.className,
      });
   }

   onFormSubmit(e: any) {
      // hide this window
      e && e.preventDefault();
      this.state.callbackOnCommit();
   }

   handleCancel(e: any) {
      // hide this window
      e && e.preventDefault();
      this.state.callbackOnCancel();
   }

   render() {
      if (this.state.showWindow) {
         let commitbutton: JSX.Element | null = null;
         let cancelbutton: JSX.Element | null = null;

         commitbutton = <button type="submit" onClick={(e) => this.onFormSubmit(e)} className="mb-4 btn btn-danger">{Localization.getText("Yes")}</button>;
         cancelbutton = <button type="button" onClick={(e) => this.handleCancel(e)} className="mb-4 btn btn-secondary">{Localization.getText("No")}</button>;

         return (
            this.state.showWindow ? (
               <Modal show={(this.state.message && this.state.message.length > 0) || false} id="mit-confirm">
                  <Modal.Body>
                     <h4 style={{ width: "100%" }}>
                        <Alert variant="warning" style={{ textAlign: "center" }}>
                           {this.state.message}
                        </Alert>
                        <Row style={{ height: "40px", textAlign: "center" }}>
                           <div className="col-3" >{' '}</div>
                           <div className="col-6" >{commitbutton}{' '}{cancelbutton}</div>
                           <div className="col-3" >{' '}</div>
                        </Row>
                     </h4>
                  </Modal.Body>
               </Modal>
            ) : null
         );
      } else {
         return (null);
      }
   }
}
