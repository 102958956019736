import { useCallback, useEffect, useRef, useState } from 'react';
import { Distances, TMouseEventCallbackParams } from './components/Distances';
import { GeoSimple, SearchBar2 } from './components/SearchBar2';
import { FloatMap } from './components/FloatMap'
import { Localization } from '@viamap/viamap2-common';
import "./index.css";

export default function Hvorlangterder() {
  let [, setSearchValue] = useState(undefined as GeoSimple | undefined);
  let [floatMapVal, setFloatMapVal] = useState(undefined as TMouseEventCallbackParams | undefined)
  let [scrollY, setScrollY] = useState(0);

  let mainRef = useRef(null)

  useEffect(() => {
    window.addEventListener("scroll",function (){setScrollY(this.scrollY)})
  })

  let handleDistanceCallback = useCallback((e:any) => {
    setFloatMapVal(e as TMouseEventCallbackParams)
  },[]);

  

  return (
    <div onScroll={(ev) => console.log(ev)} className="App">
      {/* <Button onClick={(ev) => setShowMap(!showMap)} >TEST</Button> */}

      {/* <ConsentForm /> // Kept in case we are going back to old Cookie Banner */}
      {/* {
        showMap ?
        :<></>
      } */}
      <FloatMap params={floatMapVal} boundBox={mainRef} scroll={scrollY > 10} />
      <div className='outerframe'>
      {/* <div className='GreenBar'>
      <a href={contactInfo.website ? "https://"+contactInfo.website : ""} rel="noreferrer" target="_blank">
      <img alt="Viamap logo" src="/viamap-logo-sort.png" style={{width:"200px", float:"left", clear:"both"}} />
      </a>

      <div className='contacts'>
      {contactInfo.email ? <a className='bi bi-envelope-fill contactLink' href={'mailto:'+contactInfo.email}><span>{contactInfo.email}</span></a> : null}
      {contactInfo.phone ? <a className='bi bi-telephone-fill contactLink' href={'tel:'+contactInfo.phone.split(" ").join("")}><span>{contactInfo.phone}</span></a> : null}
      {contactInfo.website ? <a className='bi bi-house-fill contactLink' target="_blank" href={'https://'+contactInfo.website} rel="noreferrer"><span>{contactInfo.website}</span></a> : null}

      </div>
      </div> */}
      <div style={{clear:"both"}}>
      </div>
        <header style={{position:"sticky"}}>
          <div className="dawa-wrap">
            <div className="addressfield">
            {scrollY > 10 ? null: <label htmlFor="dawa-1">{Localization.getText("From")}</label>}
            {SearchBar2(setSearchValue)}
            {scrollY > 10 ? null: <label htmlFor="dawa-1">{Localization.getText("To nearest")}</label>}
            </div>
          </div>
        <div style={{clear:"both"}}>
        </div>
        </header>
      <main ref={mainRef}>
        <Distances callBack={async (e) => handleDistanceCallback(e)}/>
        <footer className="outtro">
        <div className='fillerText' ><i style={{color:"white"}}>{Localization.getText("Footer:Text")}</i></div>
        {/* <div className='externalLinks' >
          <a target="_blank" href={contactInfo.facebook} rel="noreferrer"><i className='bi bi-facebook'></i></a>
          <a target="_blank" href={contactInfo.linkedin} rel="noreferrer"><i className='bi bi-linkedin'></i></a>
          <a target="_blank" href={contactInfo.youtube} rel="noreferrer"><i className='bi bi-youtube'></i></a>
        </div> */}
      </footer>
      </main>
      </div>
    </div>
    );
  }