import * as React from "react";
import { Badge } from "react-bootstrap";
import { Localization } from '../managers/Localization';
import { SettingsManager} from '../managers/SettingsManager';

// import { AccessManager } from "src/common/managers/AccessManager";
// import { Feature, SessionInfo } from "src/common/managers/Types";
// import { Utils } from "src/common/managers/Utils";
import { accessType, FeatureHelpRecord, getAccessType } from "../managers/FeatureHelpManager";
// import { Localization } from "src/managers/Localization";

export type Props = {
   sessionInfo:any,
   help:boolean,
   shownFeature:FeatureHelpRecord,
   maxImageHeight:string
};

export const SingleFeature = (props:any) => {

   const imageLocation = SettingsManager.getSystemSetting("featureHelpImageLocation","https://mapit2-pictures.s3.eu-central-1.amazonaws.com/mapitNewFeaturesImages");

   let language = Localization.getlanguage();
   
   if (!props.help) {
      return (
         <div style={{marginBottom:"0", fontSize:"14px"}}>
       {props.shownFeature[language].description}
     </div>
     );
   }
   
   if (!props.shownFeature) {
      return null;
   }
   
   let access: accessType = getAccessType(props.sessionInfo, props.shownFeature.licenseFeature);
   if (language === "da") {
      language = "dk";
   }
   let featureInSelectedLanguage = props.shownFeature.languageTable[language];
   let description = featureInSelectedLanguage.description ? 
      (Array.isArray(featureInSelectedLanguage.description) ? featureInSelectedLanguage.description.join("") : featureInSelectedLanguage.description)
      : "";

   function ShowLinks() {
     return featureInSelectedLanguage.links && Array.isArray(featureInSelectedLanguage.links) ? (
      <>
         {featureInSelectedLanguage.links.map((lnk:any) => {
            return (
               <div key={lnk.url}>
               {lnk.type === "download" ? (
                  <a href={lnk.url} target="_blank" download={true}>{lnk.title}</a>
               ) : (
                  <a href={lnk.url} target="_blank">{lnk.title}</a>
               )}
               <br/>
               </div>
               );
            })}
      </>
     ) : null;
   }
 
   const premiumMarker = (
      <Badge style={{fontSize:"14px", color:"black", backgroundColor:"silver", paddingBottom:"3px"}}>{Localization.getText("Premium")}</Badge>
   );

   const noAccessPremiumNotice = (
      <div style={{marginTop:"8px", backgroundColor:"silver", fontWeight:"bold", padding:"3px", border:"1px solid silver", borderRadius:"5px"}} >
         {Localization.getText("Contact Viamap to get trial access to this feature")}
      </div>
   );

   return (
     <>
     <h4>{featureInSelectedLanguage.title} {access !== accessType.noSpecialAcccessRequired ? premiumMarker :null}</h4>
     <div style={{textAlign:"center",width:"100%"}}>
     {featureInSelectedLanguage.imageId ? 
       <img 
         style={{maxHeight:props.maxImageHeight,maxWidth:"100%",marginBlock:"2em", marginInline:"auto", textAlign:"center"}} 
         src={imageLocation+"/"+featureInSelectedLanguage.imageId} 
         alt="Her skal være et billede" 
       />
       : null}
       </div>
       <div style={{marginBottom:"0", fontSize:"14px"}}>
       {description}
       <ShowLinks/>
       </div>
       {access === accessType.userHasNoAccess ? noAccessPremiumNotice: null}
     </>
   );
 };
 