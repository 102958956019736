import { Localization } from '../managers/Localization';

import {Alert, Modal} from 'react-bootstrap';
import * as React from 'react';

export const InfoMessage = ({message, callbackOnDismiss}:{message?:string, callbackOnDismiss?:()=>void}) => {

  return (
    <Modal show={(message && message.length > 0) || false} id="mit-info">
      <Modal.Header >
          {Localization.getTextSafeMode("Information")}
      </Modal.Header>
      <Modal.Body>
        <h4 style={{width:"100%"}}>
            <Alert variant="success" onClose={callbackOnDismiss} dismissible>
            {message}
            </Alert>
        </h4>      
      </Modal.Body>
    </Modal>
  );
};

