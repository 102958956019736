import { SessionContext } from './states/sessionState/context';
import { AppMessagesBoundary } from './components/AppMessagesBoundary';
import { AuthenticationBoundary } from './components/AuthenticationBoundary';
import { AuthenticationDialogs } from './components/AuthenticationDialogs';
import { AutomaticUserHelpPopup } from './components/AutomaticUserHelpPopup';
import { ConfirmationDialog } from './components/ConfirmationDialog';
import { EnvironmentConfiguration, EnvironmentLoader } from './components/EnvironmentLoader';
import { ErrorBoundary } from './components/ErrorBoundary';
import { LanguageSelector } from './components/LanguageSelector';
import { LicensingBoundary } from './components/LicensingBoundary';
import { MainApplicationFrame, ViamapMenuData } from './components/MainApplicationFrame';
import { Cookies } from './managers/Cookies';
import { GenericTransactionManager } from './managers/GenericTransactionManager';
import { Localization, SimpleTranslationTable } from './managers/Localization';
import { Logger } from './managers/Logger';
import { SettingsManager } from './managers/SettingsManager';
import * as Types from './managers/Types';
import { Utils } from './managers/Utils';
import { CustomerRef, ObjectRef, PersistenceObjectType, PersistenceScope, UserRef, ViamapPersistenceLayer } from './managers/ViamapPersistenceLayer';
import { actionClearErrorMessage, actionClearInfoMessage, actionClearProgressMessage, actionSetErrorMessage, actionSetInfoMessage, actionSetProgressMessage, AppMessagesActions, AppMessagesContext, AppMessagesReducer, initialAppMessagesState, transactionalAppMessagesReducer } from './states/AppMessagesState';
import { SessionActions } from './states/sessionState/actions';
import { sessionReducer } from './states/sessionState/reducer';
import { transactionalSessionReducer } from './states/sessionState/reducertransactional';
import { initialSessionState } from './states/sessionState/state';
import { initialViamapLicensingState, LicensingContext, shouldFeatureBeAvailable, transactionalViamapLicensingReducer, ViamapLicensingActions, ViamapLicensingReducer, ViamapLicensingState, ViamapProduct } from './states/ViamapLicensingState';

export {
    // Components
    AppMessagesBoundary,
    AuthenticationBoundary,
    AuthenticationDialogs,
    AutomaticUserHelpPopup,
    ConfirmationDialog,
    EnvironmentLoader,
    ErrorBoundary,
    LanguageSelector,
    LicensingBoundary,
    MainApplicationFrame,

    // Managers
    Cookies,
    Localization,
    SimpleTranslationTable,
    Logger,
    SettingsManager,
    Types,
    Utils,
    ViamapPersistenceLayer,
    PersistenceScope,
    PersistenceObjectType,
    UserRef,
    CustomerRef,
    ObjectRef,

    // States
    ViamapLicensingState,
    sessionReducer,
    initialSessionState,
    SessionContext,
    transactionalSessionReducer,
    SessionActions,
    LicensingContext,

    // ---------------
    ViamapMenuData,
    EnvironmentConfiguration,
    GenericTransactionManager,
    ViamapLicensingActions,
    ViamapLicensingReducer,
    initialViamapLicensingState,
    transactionalViamapLicensingReducer,
    ViamapProduct,
    actionClearErrorMessage,
    actionClearInfoMessage,
    actionClearProgressMessage,
    actionSetErrorMessage,
    actionSetInfoMessage,
    actionSetProgressMessage,
    AppMessagesActions,
    AppMessagesContext,
    AppMessagesReducer,
    initialAppMessagesState,
    transactionalAppMessagesReducer,
    shouldFeatureBeAvailable

    // './common/localization/translationLogin.json'
    // './common/localization/translationLicensing.json'
    // './common/localization/translationFeatures.json'
};