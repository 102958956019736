import * as React from "react";
import { SessionState, initialSessionState } from "./state";
import { SessionActions } from "./actions";

export const SessionContext = React.createContext<{
    state: SessionState;
    dispatch: React.Dispatch<SessionActions>;
}>({
    state: initialSessionState,
    dispatch: () => undefined,
});