import { Localization } from '../managers/Localization';
import { SettingsManager} from '../managers/SettingsManager';

import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Image, Alert, Button, Col, Form, Modal, Row, Container, FloatingLabel, Stack } from 'react-bootstrap';
import { SessionContext } from '../states/sessionState/context';
import { actionChangePassword, actionForgotPassword, actionForgotPasswordVerification, actionSignup, actionSignupResend, actionSignupVerification, actionSetLoginDialogMode, 
  actionLogin, actionSetLanguage 
} from '../states/sessionState/actions';

import './AuthenticationDialogs.css';
import { LoginDialogMode, MessageType } from '../states/sessionState/state';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { PasswordPolicyDisplay } from './PasswordPolicyDisplay';
import { AWSAuthenticationInterface } from '../managers/AWSAutenticationInterface';
import { LanguageSelector } from './LanguageSelector';
import { LicenseType, ViamapProduct } from '../states/ViamapLicensingState';
import * as React from "react";

type Params = {
  allowSelfSignup: boolean,
  splashImagePath: string
}

export const AuthenticationDialogs: FunctionComponent<Params> = ({ allowSelfSignup, splashImagePath }) => {

  let { state: sessionState, dispatch: sessionDispatch } = useContext(SessionContext);

  let [userName, setUserName] = useState<string>("");
  let [name, setName] = useState<string>("");
  let [company, setCompany] = useState<string>("");
  let [phone, setPhone] = useState<string>("");
  let [code, setCode] = useState<string>("");
  let [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  let [userPassword, setUserPassword] = useState<string>("");
  let [userOldPassword, setUserOldPassword] = useState<string>("");
  let [userNewPassword, setUserNewPassword] = useState<string>("");
  let [userRepeatNewPassword, setUserRepeatNewPassword] = useState<string>("");
  let [passwordPolicy, setPasswordPolicy] = useState<CognitoIdentityServiceProvider.PasswordPolicyType>();

  let mode = sessionState.loginMode;

  function validateEmailFormat(email: string): boolean {
    let regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regexp.test(email);
  }

  function inputCheckBox(
    key:string,
    label:string, 
    type:string, 
    placeholder:string, 
    valueGetter:()=>boolean, 
    valueSetter:(val:boolean)=>void,
    validator:(val:boolean)=>boolean,
    comment?:any,
    feedback?:string) {
    label = Localization.getText(label);
    placeholder = Localization.getText(placeholder);

    return (
      <div key={key} className="authControl">
        <Stack direction="horizontal" gap={5}>
          <Form.Check label={placeholder} value={valueGetter() ? "true" : "false"} onChange={(e) => valueSetter(e.target.checked)} isInvalid={validator(valueGetter())} >
        </Form.Check>
        {comment ? (<Form.Text className="authControlFormText text-muted">
          {comment}
        </Form.Text>) : null}
        </Stack>
        {feedback ? (<Form.Control.Feedback type="invalid">
          {feedback}
        </Form.Control.Feedback>) : null}
      </div>

    );
    
  }

  function inputField(
    key:string,
    label:string, 
    type:string, 
    placeholder:string, 
    valueGetter:()=>string, 
    valueSetter:(val:string)=>void,
    validator:(val:string)=>boolean,
    comment?:any,
    feedback?:string) {
    label = Localization.getText(label);
    placeholder = Localization.getText(placeholder);

    return (
      <div key={key} className="authControl">
        <Form.Group>
        <FloatingLabel controlId={"floating"+key} label={placeholder}>
          <Form.Control size={"sm"} type={type} placeholder={placeholder} value={valueGetter()} onChange={(e) => valueSetter(e.target.value)} isInvalid={validator(valueGetter())} />
        {comment ? (<Form.Text className="text-muted">
          {comment}
        </Form.Text>) : null}
        </FloatingLabel>
        {feedback ? (<Form.Control.Feedback type="invalid">
          {feedback}
        </Form.Control.Feedback>) : null}
        </Form.Group>
      </div>
    );
    
  }

  useEffect(() => {
    // Reteieve active password policy from Cognito
    !passwordPolicy && AWSAuthenticationInterface.isInterfaceInitialized() && AWSAuthenticationInterface.getPasswordPolicy()
      .then((policy) => {
        setPasswordPolicy(policy);
      });
  }, [sessionState.userSession, sessionState.loginMode]); 


  let fieldUserName = inputField("1", "Email Address", "email","Enter email",()=>userName, (v)=>setUserName(v), ()=>!(hasValue(userName) && validateEmailFormat(userName)));
  let fieldUserNameWMailComment = inputField("2", "Email Address", "email","Enter email",()=>userName, (v)=>setUserName(v), ()=>!(hasValue(userName) && validateEmailFormat(userName)), Localization.getText("A verification code will be sent to this email."));
  let fieldPassword = inputField("3", "Password", "password","Password",()=>userPassword, (v)=>setUserPassword(v), ()=>!hasValue(userPassword));
  let fieldOldPassword = inputField("4", "Old Password", "password","Old Password",()=>userOldPassword, (v)=>setUserOldPassword(v), ()=>!hasValue(userOldPassword));
  let fieldNewPassword = inputField("5", "New Password", "password","New Password",()=>userNewPassword, (v)=>setUserNewPassword(v), ()=>!(userNewPassword && userNewPassword.length > 0), PasswordPolicyDisplay(passwordPolicy, userNewPassword));
  let fieldRepeatNewPassword = inputField("6", "Repeat New Password", "password","Repeat New Password",()=>userRepeatNewPassword, (v)=>setUserRepeatNewPassword(v), ()=>!hasValue(userRepeatNewPassword) || !(userRepeatNewPassword === userNewPassword), undefined, Localization.getText("The passwords are not identical"));
  let fieldChoosePassword = inputField("5b", "Choose Password", "password","Choose Password",()=>userNewPassword, (v)=>setUserNewPassword(v), ()=>!(userNewPassword && userNewPassword.length > 0), PasswordPolicyDisplay(passwordPolicy, userNewPassword));
  let fieldName = inputField("7", "Name", "text","Enter name",()=>name, (v)=>setName(v), ()=>!hasValue(name));
  let fieldCompany = inputField("8", "Company", "text","Enter Company",()=>company, (v)=>setCompany(v), ()=>!hasValue(company));
  let fieldPhone = inputField("9", "Phone", "text","Enter Phone",()=>phone, (v)=>setPhone(v), ()=>!hasValue(phone));
  let fieldCode = inputField("10", "Code", "text","Enter Code received by email",()=>code, (v)=>setCode(v), ()=>!hasValue(code));
  let infoLinkToTerms =  (
    <a href={Localization.getText("SingnUpTerms:LinkURL")} target="_blank">{Localization.getText("SingnUpTerms:LinkText")}</a>
    );
  let fieldAcceptTerms = inputCheckBox("10", "Accept terms and conditions", "checkbox","Accept terms and conditions",()=>acceptTerms, (v)=>setAcceptTerms(v), ()=>!acceptTerms, infoLinkToTerms);

  let formElements: JSX.Element | undefined = undefined;
  let formValidator: () => boolean;
  function hasValue(elm: string): boolean { return Boolean(elm && elm.length > 0); }

  let showWindow = true;

  switch (mode) {
    case LoginDialogMode.NotDisplayed:
      showWindow = false;
      break;

    case LoginDialogMode.Login:
      formValidator = () => { return hasValue(userName) && hasValue(userPassword); };
      formElements = (
        <Form onKeyPress={event => event.key === "Enter" && formValidator() && sessionDispatch(actionLogin(userName, userPassword))}>
          {fieldUserName}
          {fieldPassword}
          <Button variant="primary" disabled={!formValidator()} onClick={() => sessionDispatch(actionLogin(userName, userPassword))}>
            {Localization.getText("Login")}
          </Button>
          <br />
          <span className='fakeA' onClick={() => sessionDispatch(actionSetLoginDialogMode(LoginDialogMode.ForgotPassword))}>{Localization.getText("Forgot your password?")}</span>
          { allowSelfSignup ? (
            <>
            <br />
            <span className='fakeA' onClick={() => sessionDispatch(actionSetLoginDialogMode(LoginDialogMode.Signup))}>{Localization.getText("Signup for an account")}</span>
            </>
          ) : null
        }
        </Form>
      );
      break;

    case LoginDialogMode.Signup:
      formValidator = () => { return hasValue(userName) && hasValue(userNewPassword) && acceptTerms; };
      const createNewUser = () => sessionDispatch(actionSignup(userName, userNewPassword, name, company, phone, licenseType, product, expiration, userGroup));
      // ToDo: Parameterize
      let product="HvorLangtErDer";
      const userGroup="Hvorlangt";
      const licenseType="Trial";
      const expiration = new Date((new Date()).setMonth((new Date()).getMonth() + 1)); // Add one month to the current date (Date manages year wrapping!)

      formElements = (
        <Form onKeyPress={event => event.key === "Enter" && formValidator() && createNewUser()}>
          {fieldUserNameWMailComment}
          {fieldChoosePassword}
          {fieldName}
          {fieldCompany}
          {fieldPhone}
          {fieldAcceptTerms}
          <Button variant="primary" disabled={!formValidator()} onClick={createNewUser}>
            {Localization.getText("Signup")}
          </Button>
          <br />
          <span className='fakeA' onClick={() => sessionDispatch(actionSetLoginDialogMode(LoginDialogMode.Login))}>{Localization.getText("Already have a login?")}</span>
        </Form>
      );
      break;

    case LoginDialogMode.SignupEnterCode:
      formValidator = () => { return hasValue(userName) && hasValue(code); };
      const validateSignUp = () => sessionDispatch(actionSignupVerification(userName, code, clientMetaData));
      const clientMetaData={
        "product": ViamapProduct.HvorLangtErDer.toString(),
        "mailChimp:audienceId": SettingsManager.getSystemSetting("applicationSettings")["mailChimpIntegration"]["audienceId"] || "0bf6e69639",
        "mailChimp:tags": JSON.stringify(["Test", ViamapProduct.HvorLangtErDer, LicenseType.Trial]),
        "infoMail:recipients":JSON.stringify(["kbe@viamap.net","rra@viamap.net"]),
        "infoMail:title":`New user signed up to ${ViamapProduct.HvorLangtErDer.toString()}`,
        "infoMail:body":`New user signed up to ${ViamapProduct.HvorLangtErDer.toString()}. ${userName}, ${name}, ${company}, ${phone}`,
        "infoMail:product":"HvorLangtErDer"
      };
      formElements = (
        <Form onKeyPress={event => event.key === "Enter" && formValidator() && validateSignUp()}>
          {fieldUserName}
          {fieldCode}
          <Button variant="primary" disabled={!formValidator()} onClick={validateSignUp}>
            {Localization.getText("Signup")}
          </Button>
          <br />
          <span className='fakeA' onClick={() => sessionDispatch(actionSignupResend(userName))}>{Localization.getText("Didn't receive an email? Resend!")}</span>
          <br />
          <span className='fakeA' onClick={() => sessionDispatch(actionSetLoginDialogMode(LoginDialogMode.Login))}>{Localization.getText("Cancel? Login instead")}</span>
        </Form>
      );
      break;

    case LoginDialogMode.ChangePassword:
      formValidator = () => {
        return hasValue(userName) && hasValue(userOldPassword) && hasValue(userNewPassword)
          && hasValue(userRepeatNewPassword) && userNewPassword === userRepeatNewPassword;
      };
      formElements = (
        <Form onKeyPress={event => event.key === "Enter" && formValidator() && sessionDispatch(actionChangePassword(userName, userOldPassword, userNewPassword))}>
          {fieldUserName}
          {fieldOldPassword}
          {fieldNewPassword}
          {fieldRepeatNewPassword}
          <Button variant="primary" disabled={!formValidator()} onClick={() => sessionDispatch(actionChangePassword(userName, userOldPassword, userNewPassword))}>
            {Localization.getText("ChangePassword")}
          </Button>
          {' '}
          <Button variant="primary" onClick={() => sessionDispatch(actionSetLoginDialogMode(LoginDialogMode.NotDisplayed))}>
            {Localization.getText("Cancel")}
          </Button>
        </Form>
      );
      break;

    case LoginDialogMode.ForgotPassword:
      formValidator = () => { return hasValue(userName); };
      formElements = (
        <Form onKeyPress={event => event.key === "Enter" && formValidator() && sessionDispatch(actionForgotPassword(userName))}>
          {fieldUserNameWMailComment}
          <Button variant="primary" disabled={!formValidator()} onClick={() => sessionDispatch(actionForgotPassword(userName))}>
            {Localization.getText("Send email with instructions")}
          </Button>
          <br />
          <span className='fakeA' onClick={() => sessionDispatch(actionSetLoginDialogMode(LoginDialogMode.Login))}>{Localization.getText("Cancel? Login instead")}</span>
        </Form>
      );
      break;

    case LoginDialogMode.ForgotPasswordEnterCode:
      formValidator = () => {
        return hasValue(userName) && hasValue(code) && hasValue(userNewPassword)
          && hasValue(userRepeatNewPassword) && userNewPassword === userRepeatNewPassword;
      };
      formElements = (
        <Form onKeyPress={event => event.key === "Enter" && formValidator() && sessionDispatch(actionForgotPasswordVerification(userName, code, userNewPassword))}>
          {fieldUserName}
          {fieldCode}
          {fieldNewPassword}
          {fieldRepeatNewPassword}
          <Button variant="primary" disabled={!formValidator()} onClick={() => sessionDispatch(actionForgotPasswordVerification(userName, code, userNewPassword))}>
            {Localization.getText("ChangePassword")}
          </Button>
          <br />
          <span className='fakeA' onClick={() => sessionDispatch(actionForgotPassword(userName))}>{Localization.getText("Didn't receive an email? Resend!")}</span>
          <br />
          <span className='fakeA' onClick={() => sessionDispatch(actionSetLoginDialogMode(LoginDialogMode.Login))}>{Localization.getText("Cancel? Login instead")}</span>
          </Form>
      );
      break;

      default:
        throw new Error("Unexpected dialog mode: "+mode);
  }

  return (
    <Modal contentClassName='authDialog viamapColors' show={showWindow} id="logindialogs">
      <Modal.Header>
        <Container style={{ border: "solid 2px lightblue" }}>
          <Stack>
              <LanguageSelector style={{position:"absolute", top:"15px", right:"15px"}} />
              <Image src={splashImagePath} style={{padding:"10px", backgroundColor:"lightblue"}} fluid />
          </Stack>
          {/* <Row>
            <Col>
              <div style={{ textAlign: "center" }}>{Utils.productNameAndVersion()}</div>
            </Col>
          </Row> */}
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Alert show={sessionState.message.length > 0} variant={sessionState.messageType === MessageType.Error ? "danger" : "success"}>{sessionState.message}</Alert>
        {/* <Form noValidate validated={false}> */}
          <Stack>
              <h1>{Localization.getTextSafeMode(mode)}</h1>
              {formElements}
          </Stack>
        {/* </Form> */}
      </Modal.Body>
    </Modal>
  );

};