
export class GenericTransactionManager {

    static dispatchMiddleware<ActionTypes>(dispatch: any, transactionalReducer:(action:any,dispatch:any)=> any) {

        return (action: ActionTypes) => {
            transactionalReducer(action, dispatch);
        };
        
    }
}
