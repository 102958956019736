import * as React from "react";
import { SessionState } from "../states/sessionState/state";
import { FeatureViewer } from "./FeatureViewer";

export type Props = {
   sessionInfo:SessionState
};

export function AutomaticUserHelpPopup(props:Props) {

   return (
         <FeatureViewer
            sessionInfo={props.sessionInfo}
         />
   );
}