import { DeploymentMode } from "./Types";

export class Utils {

  private static _timeLogs:{[key:string]:number}={};
  private static _packageJSON:{name:string, version:string};

  static isIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        return true;
    }
    return false;
  }

  static isEmptyObject(obj:{}) {
    return !Object.keys(obj).length;
  }
  
  static capitalizeFirstLetter(s:String) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  static initialize(packageJson:{name:string, version:string}) {
    Utils._packageJSON=packageJson;
  }

  // reads product identification from package.json
  static productNameAndVersion(REACT_APP_ENVIRONMENT?:string) {  
    let deploymentMode=(Utils.getDeploymentMode(REACT_APP_ENVIRONMENT) === DeploymentMode.Production) ? "" : " "+Utils.getDeploymentMode(REACT_APP_ENVIRONMENT).toString();

    let packageJson = Utils._packageJSON;
    return (Utils.capitalizeFirstLetter(packageJson.name) +' v'+ packageJson.version+deploymentMode);
  }

   /**
    * Formats a string by expanding any placeholders
    * example formatString("{name} bought {count} {fruit}", {name:"John", count:"2", fruit:"apples"})
    * @param input The text to be formatted
    * @param placeholders An object of form {placeholder:value, ...}
    * @returns The expanded string
    */ 
  static formatString(input:string, placeholders:object) {
      let s = input;
      for(let propertyName in placeholders) {
        if (placeholders.hasOwnProperty(propertyName)) {
          let re = new RegExp('{' + propertyName + '}', 'gm');
          s = s.replace(re, placeholders[propertyName]);
        }
      }    
      return s;
  }

  // ----------------------------------------- error handling ---------------------------
  static createErrorEventObject(message:string) {
      let error = new ErrorEvent('Error', {
        error : new Error('TheError'),
        message : message,
        });
      return (error);
  }


// ------------------- decimal numbers according to locale  --------------------------

  static formatNumber(n:number) {
//                value={Number(styling.divisions!.list[idx].to).toLocaleString("da-DK", {minimumFractionDigits: 2, maximumFractionDigits: 2})}
    if (n === null || n === undefined || isNaN(n)) {
      return "";
    } else {
//      return n.toLocaleString(undefined, {maximumFractionDigits: 2});
      return n.toString();
    }
  }

  static parseNumber(s:string):number|undefined {
    let result:number = Number.parseFloat(s);
    return result;
  }

  static isValidNumber(val:any):boolean {
    return !isNaN(val);
 }

 // ------------------- date functions --------------------------

  static addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }


 // ensure that the number is returned nummeric (and not string)
 static toN(nbr:string):number { return (Number.parseInt(nbr,10));}

 static getCopyOfArray(array:any[]):any[] {
  let result:any[] = [];
  array && array.map((value, index) => {
    result.push(value);
  } );
  return (result);
}

  // --------------------------------------------------------------------------------------------------

  static toColumnName (num:number):string {
    return (this.toColumnNameOneBased(num));
  }

  // One based conversion
  // converts 1 to 'A', 2 to 'B', 27 to 'Z', 28 to 'AA' etc.
  static toColumnNameOneBased (num:number):string {
    return (this.toColumnNameZeroBased(num-1));
  }

  // Zero Based conversion
  // converts 0 to 'A', 1 to 'B', 26 to 'Z', 27 to 'AA' etc.
  static toColumnNameZeroBased (num:number):string {
    if (num < 0) {
      throw Utils.createErrorEventObject("Unexpected negative num"+num);
    }
    if (num > 25) {
      // Recursively ensure that no more than letter A-Z is needed
      let firstLetterVal:number = Math.floor(num / 26);
      return (this.toColumnNameZeroBased(firstLetterVal-1) + this.toColumnNameZeroBased(num-(firstLetterVal*26)));
    }
    const letters:string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return letters[num];
  }

  // ------------------ encode/decode -------------------
  static encodeValue(value:any):string {
    return JSON.stringify({value:value});
  }

  static decodeValue(coded:string):any {
    let x:any = JSON.parse(coded);
    return x && x.value; 
  }


  // ------------------- deployment mode -------------------------------

  static getDeploymentMode(REACT_APP_ENVIRONMENT?:string): DeploymentMode {
    return this._getDeploymentMode(REACT_APP_ENVIRONMENT, this.isDevelopmentBuild, this.isTestBuild);
  }
 
  static _getDeploymentMode(REACT_APP_ENVIRONMENT:string|undefined, isDevelopmentBuild:()=>boolean, isTestBuild:()=>boolean): DeploymentMode {
    let result:DeploymentMode;
    let e = REACT_APP_ENVIRONMENT;
 
    let env = REACT_APP_ENVIRONMENT ? REACT_APP_ENVIRONMENT : (process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : "was undefined");
    env = env.trim();
    if (isDevelopmentBuild()) {
      result = DeploymentMode.Development;
    } else {
      if (isTestBuild()) {
        result = DeploymentMode.Test;
      } else {
        switch (env) {
          case "staging":
            result = DeploymentMode.Staging;
            break;
          case "production":
            result = DeploymentMode.Production;
            break;
          case "demo":
            result = DeploymentMode.Demo;
            break;
          case "testing":
            result = DeploymentMode.Testing;
            break;
          default:
            result = DeploymentMode.Unknown;
        }
      }
    }

    return result;
  }
  
  // BUILD TYPES
  static isDevelopmentBuild():boolean {

    return process.env.NODE_ENV && process.env.NODE_ENV.trim() === "development" ? true : false;
  }
  static isTestBuild():boolean {

    return process.env.NODE_ENV && process.env.NODE_ENV.trim() === "test" ? true : false;
  }

  /**
   * toggles a className (part) on and off for a HTML element
   * @param queryString  Should uniquely identify a HTML element
   * @param className If 'className' is not set for element then it is set. If it exists it is removed.
   */
  static toggleClassNameOnElement(queryString:string, className:string ) {
    let el:Element|null =document.querySelector(queryString);
    if (el) { 
      // Toggle open class
      let name = className;
      let cl = el.className;
      let arr = cl.split(" ");
      let idx = arr.indexOf(name);
      if (idx === -1) {
        arr.push(name);
      } else {
        arr.splice(idx);
      }
      el.className = arr.join(" ");
    }
  }

  static async asyncFetchJsonFromUrl(url:string) {
    try {
        const response = await fetch(url,{
          mode: "cors", // no-cors, cors, *same-origin
          headers: {
          },
        });
        const data = await response.json();
        return data;
    } catch (err) {
        throw Utils.createErrorEventObject("asyncFetchJsonFromUrl. "+url+" Error"+err.message);
    }
  }

  /* TIMER FUNC FOR DEBUGGING */
  
  static time(key:string) {
    let startTime = Date.now();
    this._timeLogs[key]=startTime;
  }

  static timeEnd(key:string) {
    let startTime = this._timeLogs[key];
    if (startTime) {
      let endTime = Date.now();
      let timeParsed = endTime - startTime;
      console.log("Time("+key+"): "+timeParsed);
    } else {
      console.log("Timer("+key+"): not found");
    }
  }

  /* JSON PARSER PRESERVING DATES */

  static parseJSONPreserveDates(data:string) {
    const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

    function jsonDateReviver(key:any, value:any) {
      if (value instanceof Array) {
        return (value as Array<any>).map((item) => {return jsonDateReviver(undefined, item); } );
      }
      if (typeof value === "string" && dateFormat.test(value)) {
        return new Date(value);
      }
      return value;
    }

    return JSON.parse(data, jsonDateReviver);
  }



}

