import { SettingsManager } from './SettingsManager';

enum CWSSTATE {Initial, Ready, SendingMessages, GettingInitialToken}

var DATA_ACCEPTED = 'DataAlreadyAcceptedException';
var INVALID_TOKEN = 'InvalidSequenceTokenException';

export class AWSCloudWatchLogger {
   private static _cloudwatchlogs:AWS.CloudWatchLogs;
   private static _cloudwatchLogName:string = "MIT";
   private static _cloudwatchLogStream:string;
   private static _nextSequenceToken:string|undefined = undefined;
   private static _queue:AWS.CloudWatchLogs.InputLogEvent[] = [];
   private static _cwState:CWSSTATE = CWSSTATE.Initial;

   static switchOnAWSLogging(cloudwatchlogs:AWS.CloudWatchLogs) {
       AWSCloudWatchLogger._cloudwatchlogs = cloudwatchlogs;
       AWSCloudWatchLogger._cloudwatchLogStream = SettingsManager.getSystemSetting("logStream") || "unknown";
       console.log("Logstream: "+AWSCloudWatchLogger._cloudwatchLogStream);
   }

   static getInitialSequenceTokenAsync():Promise<any> {
       console.assert(AWSCloudWatchLogger._cloudwatchLogStream, "CloudWatchLogger._cloudwatchLogStream required");
       return new Promise((resolve,reject) => {
           let params2;
           params2 = {
           logGroupName: AWSCloudWatchLogger._cloudwatchLogName,
           logStreamNamePrefix: AWSCloudWatchLogger._cloudwatchLogStream
           };
           AWSCloudWatchLogger._cloudwatchlogs.describeLogStreams(params2, function(err2:any, data2:any) {
               if (err2) {
                   console.log(err2, err2.stack); // an error occurred
                   reject(err2);
               } else {
//                   console.log(data2);           // successful response
                   let result;
                   Object.keys(data2 as AWS.CloudWatchLogs.LogStreams).forEach((key:any, idx:number) => {
                       let obj:AWS.CloudWatchLogs.LogStream = data2.logStreams[idx];
                       result = obj.uploadSequenceToken;
                       // result may be null for first logging to the LogStream.
//                       console.log("next sequence token="+CloudWatchLogger._nextSequenceToken);
                   } );
                   resolve(result);
               }
           });
       });
   }

   static putLogAcync(msgs:AWS.CloudWatchLogs.InputLogEvent[]):Promise<any> {
      console.assert(AWSCloudWatchLogger._cloudwatchLogStream, "CloudWatchLogger._cloudwatchLogStream required");
      return new Promise((resolve,reject) => {
           let params = {
               logEvents: msgs,
               logGroupName: AWSCloudWatchLogger._cloudwatchLogName, /* required */
               logStreamName: AWSCloudWatchLogger._cloudwatchLogStream, /* required */
               sequenceToken: AWSCloudWatchLogger._nextSequenceToken
           };
           AWSCloudWatchLogger._cloudwatchlogs.putLogEvents(params, function(err:any, data:any) {
               if (err) {
//                   console.log(err, err.stack); // an error occurred
                   reject(err);
               } else {
//                   console.log(data);           // successful response
                   AWSCloudWatchLogger._nextSequenceToken = data.nextSequenceToken;
                   resolve(data);
               }
           });
       });
   }

   static logInAWS(msg:string) {
        let doCWLogs = SettingsManager.getSystemSetting("logsToCloudWatch", true);
       
        try {
            if(doCWLogs && AWSCloudWatchLogger._cloudwatchlogs) {
                let m:AWS.CloudWatchLogs.InputLogEvent = {
                    message: msg, /* required */
                    timestamp: new Date().getTime() /* required */
                };
                AWSCloudWatchLogger._queue.push(m);
                AWSCloudWatchLogger._logInAWS();
            }
        } catch (error) {
            console.log("Caught error writing logs: "+error);
        }
   }
   
   static _logInAWS(/*msgs:AWS.CloudWatchLogs.InputLogEvent[]*/) {

       if (AWSCloudWatchLogger._queue && AWSCloudWatchLogger._queue.length > 0) {
           if(!AWSCloudWatchLogger._nextSequenceToken && AWSCloudWatchLogger._cwState === CWSSTATE.Initial) {
                   AWSCloudWatchLogger._cwState = CWSSTATE.GettingInitialToken;
                   // First find the sequence token
                   let a = AWSCloudWatchLogger.getInitialSequenceTokenAsync()
                   .then(function (val:any) { 
//                       console.log("After get initial token" +val); 
                       AWSCloudWatchLogger._nextSequenceToken = val;
                       AWSCloudWatchLogger._cwState = CWSSTATE.Ready;
                       // Now send the messages
                       AWSCloudWatchLogger._logInAWS();
                   })
                   .catch(function (err:any) {
//                       console.log('error in get initial token ', err.message); 
                        // first time there is no initial token
                        AWSCloudWatchLogger._nextSequenceToken = undefined;
                        AWSCloudWatchLogger._cwState = CWSSTATE.Ready;
                        // Now send the messages
                        AWSCloudWatchLogger._logInAWS();
                    });
                           
           } else {
               if (AWSCloudWatchLogger._cwState === CWSSTATE.Ready) {
                   AWSCloudWatchLogger._cwState = CWSSTATE.SendingMessages;

                   // Clone the queue
                   let x = AWSCloudWatchLogger._queue.slice(0);
                   // Clear the queue
                   AWSCloudWatchLogger._queue=[];
                   let b = AWSCloudWatchLogger.putLogAcync(x)
                   .then(function (val:any) { 
//                       console.log("After put" +val); 
                       // See if there are more to send
                       AWSCloudWatchLogger._cwState = CWSSTATE.Ready;
                       AWSCloudWatchLogger._logInAWS();
                   })
                   .catch(function (err:any) {
                       if(err.code ===  DATA_ACCEPTED || err.code === INVALID_TOKEN) {
                           var nextToken = err.message.split(': ')[1];
                           console.log("Next token mismatch. Try new nexttoken:"+nextToken);
                           // re-queue failed messages (in the right order - before any new messages)
                           let y = AWSCloudWatchLogger._queue.slice(0);
                           y.forEach((msg) => x.push(msg));
                           AWSCloudWatchLogger._queue = x;
                           AWSCloudWatchLogger._nextSequenceToken = nextToken;
                           AWSCloudWatchLogger._cwState = CWSSTATE.Ready;
                           AWSCloudWatchLogger._logInAWS();
                       } else {
                           console.log('error in put', err.message,' sending' + JSON.stringify(x));
                           AWSCloudWatchLogger._cwState = CWSSTATE.Ready;
                       }
                   }) ;           
               }
           }
       }
   }
}
