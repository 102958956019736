// import {PoiCategory} from './state';

import { CognitoUserSession } from "amazon-cognito-identity-js";
import { LoginDialogMode } from "./state";


export enum SessionActionType {

    TxStarted,
    TxCompleted,
    TxFailed,

    Login,
    Logoff,
    Signup,
    SignupVerification,
    SignupResend,
    ChangePassword,
    ForgotPassword,
    ForgotPasswordVerification,

    SetLoginDialogMode,
    SetUserSession,

    SetLanguage
}

export interface TxStarted {
    type: SessionActionType.TxStarted;
    payload: { guid:string, action: SessionActions };
}

export interface TxCompleted {
    type: SessionActionType.TxCompleted;
    payload: { guid:string, action: SessionActions };
}

export interface TxFailed {
    type: SessionActionType.TxFailed;
    payload: { guid:string, action: SessionActions, error:any };
}

export interface Login {
    type: SessionActionType.Login;
    payload: { userName:string, password: string };
    results?: { userSession:CognitoUserSession };
}

export interface Logoff {
    type: SessionActionType.Logoff;
    payload: { userName:string};
}

export interface Signup {
    type: SessionActionType.Signup;
    payload: {userName: string, password:string, name:string, company:string, phone:string, licenseType:string, product: string, expiration?:Date, userGroup?:string}
}

export interface SignupVerification {
    type: SessionActionType.SignupVerification;
    payload: {userName: string, code:string, clientMetaData?: any}
}

export interface SignupResend {
    type: SessionActionType.SignupResend;
    payload: {userName: string}
}

export interface ChangePassword {
    type: SessionActionType.ChangePassword;
    payload: {userName: string, oldPassword:string, newPassword:string}
}

export interface ForgotPassword {
    type: SessionActionType.ForgotPassword;
    payload: {userName: string}
}

export interface ForgotPasswordVerification {
    type: SessionActionType.ForgotPasswordVerification;
    payload: {userName: string, code:string, newPassword:string}
}

export interface SetLoginDialogMode {
    type: SessionActionType.SetLoginDialogMode;
    payload: {mode:LoginDialogMode}
}

export interface SetUserSession {
    type: SessionActionType.SetUserSession;
    payload: {session:CognitoUserSession}
}

export interface SetLanguage {
    type: SessionActionType.SetLanguage;
    payload: {languageCode:string}
}


export type SessionActionsTransActional = 
Login | Logoff 
| TxStarted | TxCompleted | TxFailed 
| Signup 
| SignupVerification | SignupResend 
| ChangePassword 
| ForgotPassword | ForgotPasswordVerification;

export type SessionActions = 
SessionActionsTransActional | SetLoginDialogMode | SetUserSession | SetLanguage;

// -------------------- utility functions to create an Action object ---------------------------------

export const actionTxStarted = (guid: string, action: SessionActions): TxStarted => ({
    type: SessionActionType.TxStarted,
    payload: { guid, action }
});

export const actionTxCompleted = (guid: string, action: SessionActions): TxCompleted => ({
    type: SessionActionType.TxCompleted,
    payload: { guid, action }
});

export const actionTxFailed = (guid: string, action: SessionActions, error:any): TxFailed => ({
    type: SessionActionType.TxFailed,
    payload: { guid, action, error }
});

export const actionLogin = (userName: string, password:string): Login => ({
    type: SessionActionType.Login,
    payload: {userName, password }
});

export const actionLogoff = (userName: string): Logoff => ({
    type: SessionActionType.Logoff,
    payload: {userName }
});

export const actionSignup = (userName: string, password:string, name:string, company:string, phone:string, licenseType:string, product: string, expiration?:Date, userGroup?:string): Signup => ({
    type: SessionActionType.Signup,
    payload: {userName, password, name, company, phone, licenseType, product, expiration, userGroup}
});

export const actionSignupVerification = (userName: string, code:string, clientMetaData?: any): SignupVerification => ({
    type: SessionActionType.SignupVerification,
    payload: {userName, code, clientMetaData }
});

export const actionChangePassword = (userName: string, oldPassword:string, newPassword:string): ChangePassword => ({
    type: SessionActionType.ChangePassword,
    payload: { userName, oldPassword, newPassword }
});

export const actionSignupResend = (userName: string): SignupResend => ({
    type: SessionActionType.SignupResend,
    payload: {userName }
});

export const actionForgotPassword = (userName: string): ForgotPassword => ({
    type: SessionActionType.ForgotPassword,
    payload: { userName }
});

export const actionForgotPasswordVerification = (userName: string, code:string, newPassword:string): ForgotPasswordVerification => ({
    type: SessionActionType.ForgotPasswordVerification,
    payload: { userName, code, newPassword }
});

export const actionSetLoginDialogMode = (mode: LoginDialogMode): SetLoginDialogMode => ({
    type: SessionActionType.SetLoginDialogMode,
    payload: {mode }
});

export const actionSetUserSession = (session: CognitoUserSession): SetUserSession => ({
    type: SessionActionType.SetUserSession,
    payload: {session }
});

export const actionSetLanguage = (languageCode: string): SetLanguage => ({
    type: SessionActionType.SetLanguage,
    payload: { languageCode }
});
