import * as React from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';

/**
 * Component for displaying status when loading data files.
 */
type Props = {
    message?:string;
    percentComplete?:number;
};

export const LoadingScreen = (props:Props) => {

    return props.message ? (
        <Modal show={(props.message && props.message.length > 0) || false} id="mit-loading">
            <Modal.Header>
                {props.message}                  
            </Modal.Header>
            <Modal.Body>
                {RenderProgressBar(props.percentComplete)}
            </Modal.Body>
        </Modal>
    ) : null;

    function RenderProgressBar(percentComplete?:number ) {
        const now = percentComplete || 0;
        return now && now > 0 ? (
            <ProgressBar striped={true} now={now} label={`${now}%`}/>
        ) : null;
    }
}