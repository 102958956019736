import { FunctionComponent, useContext, useEffect, useState } from 'react';
import * as React from "react";
import { Alert, Button } from 'react-bootstrap';
import { SessionContext } from '../states/sessionState/context';
import { actionLogoff, actionSetLoginDialogMode, actionSetUserSession } from '../states/sessionState/actions';
import { AuthenticationDialogs } from './AuthenticationDialogs';
import { Localization } from '../managers/Localization';
import { SettingsManager } from '../managers/SettingsManager';
import { AWSAuthenticationInterface } from '../managers/AWSAutenticationInterface';
import { LoginDialogMode } from '../states/sessionState/state';

type Params = {
  requiredAccessRole?: string,
  default2signup?:boolean,
  splashImagePath: string,
  blockSite?: boolean
  children:any,
  background?:any
}

export const AuthenticationBoundary: FunctionComponent<Params> = ({ requiredAccessRole, splashImagePath,background,blockSite=true, default2signup, children }) => {
  let { state: sessionState, dispatch: sessionDispatch } = useContext(SessionContext);
  let [authData, setAuthData] = useState();

  useEffect(() => {
    if (!authData) {
      // synchronously get configuration
      let authData = SettingsManager.getSystemSetting("authData");
      AWSAuthenticationInterface.initialize(authData);
      setAuthData(authData);

      const initialUnauthDialogMode = default2signup ? LoginDialogMode.Signup : LoginDialogMode.Login;

      // Get session if authenticated
      if (AWSAuthenticationInterface.getCurrentUser()) {
        AWSAuthenticationInterface.getCurrentUserSession()
          .then(session => {
            AWSAuthenticationInterface.InitAuth(session).then(() => {
              sessionDispatch(actionSetUserSession(session));
            }).catch(err => {
              throw err;
            })
          })
          .catch(error => {
            AWSAuthenticationInterface.UnAuth();
            console.log("Error getting session " + error.message);
            sessionDispatch(actionSetLoginDialogMode(initialUnauthDialogMode));
          });
      } else {
        AWSAuthenticationInterface.UnAuth();
        sessionDispatch(actionSetLoginDialogMode(initialUnauthDialogMode));
      }
    }
   /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  let hasSufficientRole:boolean = !Boolean(requiredAccessRole);
  if (sessionState.userSession && requiredAccessRole) {
    let groups = sessionState.userRoles;
    hasSufficientRole = groups && Boolean(Object.values(groups).find((elem) => elem === requiredAccessRole));
    if (!hasSufficientRole) {
      return (
        <>
          <Alert variant={"danger"}>{Localization.getText("We are sorry. Special access rights are required to access this feature")}</Alert>
          <Button onClick={() => sessionDispatch(actionLogoff(sessionState.userRef))}>{Localization.getText("Logout")}</Button>
        </>
      );
    }
  }

  return (
    <>
    <AuthenticationDialogs allowSelfSignup={SettingsManager.getSystemSetting("allowUserSelfSignup", false)} splashImagePath={splashImagePath}/>
    { !blockSite || sessionState && sessionState.userSession && hasSufficientRole ? (
      <>{children}</>
    ) : 
      background ? <>{background}</>:<h1>{Localization.getText("Welcome, please log in")}</h1>
    }
    </>
  );

};