import * as QueryString from 'query-string';
import { ColorRange, ColorList, LayerType, MitDataType, LayerInfo, DataDivision, DataDivisionList, DataDivisionType } from "./MapitTypes";
import {Utils} from './Utils';

export class MapitUtils {
    

  // static async getWhitelistedUserSettings(): Promise<WhitelistedUserSettings> {
  //   let serviceHost = SettingsManager.getSystemSettingByEnvironment("mapitServicesHost");
  //   let configBucket = SettingsManager.getSystemSettingByEnvironment("mapitS3Bucket");
  //   let apiKey = SettingsManager.getSystemSettingByEnvironment("mapitServicesAPIKey");
  //   let whitelistedUserSettings = await MapitServicesInterface.getJSONFromS3(serviceHost, apiKey, configBucket, "customerConfig/whitelistedSettings.json");
  //   if (whitelistedUserSettings !== undefined) {
  //     return whitelistedUserSettings;
  //   } else {
  //     Utils.dispatchToErrorHandler("Get whitelisted settings from server failed");
  //     return JSON.parse("{}");
  //   }
  // }

  static isEmbeddedmode(): boolean {

    let embeddedMode = false;

    let options = QueryString.parse(location.search);

    if (options && options.state) {
      const redirectedSearch = decodeURIComponent(options.state.toString());
      console.log("Decoded State: "+redirectedSearch);
      options = QueryString.parse(redirectedSearch);
    }

    if (options && options.embeddedmode) {
      embeddedMode = (options.embeddedmode !== "0" && options.embeddedmode !== "false"); 
    }

    return embeddedMode;
  }

  // ---------------------- error handling --------------------------------------------
  static dispatchToErrorHandler(message:string) {
    // close info window if it is shown
    MapitUtils.closeInfoHandler();
    let event = new CustomEvent('mit-error-handler', { detail: message });
    document.body.dispatchEvent(event);
  }

  static dispatchToInfoHandler(message:string) {
    let event = new CustomEvent('mit-info-handler', { detail: message });
    document.body.dispatchEvent(event);
  }

  static closeInfoHandler() {
    MapitUtils.dispatchToInfoHandler("");
  }


  
  // ---------------------- sorting util ----------------------------------------------
  static sortByLayerId(l1:LayerInfo, l2:LayerInfo):number {
    return l1.layerId > l2.layerId ? 1 : l1.layerId === l2.layerId ? 0 : -1;
  }

   /**
  * Calculates divisions for descrete strings.
  * Only the first 'numberOfDivs' unique elements will get their own bucket.
  * Bucket names will be sorted alphabatically.
  * @param columnDataInput a single array of strings
  * @param numberOfDivs number of divisions/buckets to create
  */
 static calculateDataDivisionsDiscrete(columnDataInput:string[], numberOfDivs:number):DataDivisionList {
    console.assert(columnDataInput,"Data must be supplied");
    if (!numberOfDivs) { numberOfDivs=4; }
  
    let uniqueValues:string[] = [];
    let wasActivelyStopped = columnDataInput.find((val) => {
      // Collect the string if not already there
      if (uniqueValues.findIndex((val2, idx) => { return val2 === val; } ) === -1) {
        if (uniqueValues.length >= numberOfDivs) {
          // too many unique values. Ignore the last one.
          // stop the loop
          return(true);
        } 
        uniqueValues.push(val);
      }
      return(false);
    });
  
    // sort the list Ascending
    uniqueValues.sort();
  
    let result:DataDivisionList = {
      type:DataDivisionType.Discrete, 
      list:[], 
      hasOverflow:wasActivelyStopped? true : false
    };
    // Create DataDivisionElements for each unique value
    uniqueValues.forEach((val,idx) => {
      let r:DataDivision = {from:null, to:null, value:val};
      result.list.push(r);
    });
  
    return(result);
  }
  
    // ------------------- division calculations --------------------------
 
 static sortNumber(a:number ,b:number):number {
    return a - b;
   }
  
   /**
    * Calculates divisions such that each division/bucket contains the approx the same number of data elements
    * Each division has a 'from' value (exclusive - or null for the first division)
    * and a 'to' value (inclusive - or null for the last division)
    * @param columnDataInput a single array of numbers
    * @param numberOfDivs number of divisions/buckets to create
    */
   static calculateDataDivisions(columnDataInput:any[], numberOfDivs:number):DataDivisionList {
      console.assert(columnDataInput,"Data must be supplied");
      if (!numberOfDivs) { numberOfDivs=4; }
  
      // Sort the array numerically;
      let columnData:any[] = Utils.getCopyOfArray(columnDataInput);
      columnData.sort(this.sortNumber);
  
      let result:DataDivisionList = {type:DataDivisionType.Continous, list:[]};
      let count = 0;
      let minVal=Number.MAX_VALUE;
      let maxVal=Number.MIN_VALUE;
      columnData.forEach((val) => {
          // ToDo: Better error handling on non-nummeric data
          // if (!isNaN(val)) {
          //   throw Utils.createErrorEventObject(Localization.getText("Dataelement is not nummeric. Cannot style"));
          // }
          console.assert(!isNaN(val), "Array element is not a number");
          count++;
          val = Utils.toN(val);
          if (val>maxVal) {maxVal=val;}
          if (val<minVal) {minVal=val;}
      });
  
      if (count === 1) {
        result.list.push({from:null, to:Utils.toN(columnData[0]), value:null});
      } else {
        if (count < numberOfDivs) { numberOfDivs = count; }
        let obsPerDiv = count/numberOfDivs; // This must not be an integer division
        let div;
        for (div=0; div < numberOfDivs; div++) {
          let lastIndexBeforeDiv = Math.floor(div*obsPerDiv)-1;
          let firstIndexforDiv = Math.floor(div*obsPerDiv);
          let lastIndexforDiv = Math.floor((div+1)*obsPerDiv)-1;
          let firstIndexAfterDiv = Math.floor((div+1)*obsPerDiv);
          let divfrom:number|null;  
  
          if (lastIndexBeforeDiv < 0) {
            divfrom=null;
          } else {
            divfrom= (Utils.toN(columnData[lastIndexBeforeDiv])+Utils.toN(columnData[firstIndexforDiv]))/2;
          }
          let divto:number|null;
          if (firstIndexAfterDiv >= count) {
            divto=null;
          } else {
            divto= (Utils.toN(columnData[lastIndexforDiv])+Utils.toN(columnData[firstIndexAfterDiv]))/2; 
          }
          let newDiv:DataDivision = {from:divfrom, to:divto, value:null};
          result.list.push(newDiv);
        }
      }
      return(result);
    }
  
    /**
     * Finds division for number. Returns -1 if not found.
     * @param divisions 
     * @param value 
     * @returns index or -1 if not found
     */
    static getDivisionId(divisions:DataDivisionList, value:number) {
      let divIdx = -1;
      console.assert(divisions && divisions.list.length, "getDivisionId, Unexpected empty divisions");
  
      for (let i=0; i<divisions.list.length; i++) {
        if (
          (divisions.list[i].from === null && value <= divisions.list[i].to!)
          || (value > divisions.list[i].from! && value <= divisions.list[i].to!)
          || (value > divisions.list[i].from! && divisions.list[i].to === null )
        ) {
        divIdx = i;
        break;
      }
      }
      return (divIdx);
    }      
  
    /**
     * Finds division for string. Returns -1 if not found.
     * @param divisions 
     * @param value 
     * @returns index or -1 if not found
     */
    static getDivisionIdDiscrete(divisions:DataDivisionList, value:string) {
      let divIdx = -1;
      console.assert(divisions && divisions.list.length, "getDivisionIdDiscrete, Unexpected empty divisions");
  
      divIdx = divisions.list.findIndex((val,idx) => { return val.value === value; });
  
      return (divIdx);
    }      
    
    // --------------------------------------------------------------------------------------------------
    static getMenuButtonColor() { return "#e8eff7"; /*"rgb(232,239,247)";*/ }
  
  // ------------------ map and data types ------------------

  static isNummericMitDatatype(myType:MitDataType): boolean {
    return [MitDataType.Number, MitDataType.AdmReg_DK_MunicipalityId, MitDataType.AdmReg_DK_RegionId, MitDataType.AdmReg_DK_ZipCodeId, 
        MitDataType.Coord_UTM32_X, MitDataType.Coord_UTM32_Y, MitDataType.Coord_WGS84_Lat, 
        MitDataType.Coord_WGS84_Lon].indexOf(myType) !== -1;
  }

  static isAreaMap(myType:LayerType):boolean {
    return ([
        LayerType.Area,
        LayerType.AreaMunicipality,
        LayerType.AreaRegion,
        LayerType.AreaZipcodes,
        LayerType.AreaMunicipalityId,
        LayerType.AreaRegionId,
        LayerType.AreaZipcodesId,    
        LayerType.AreaMunicipalityName,
        LayerType.AreaRegionName,
        LayerType.AreaZipcodesName,
        LayerType.AreaParishName,    
        LayerType.AreaParishId,    
        LayerType.AreaCountry,    
        LayerType.AreaCountryId,    
        LayerType.AreaCountryName,    
        LayerType.AreaMunicipalityId_RO,
        LayerType.AreaMunicipalityName_RO
    ].find((obj,idx) => { return obj === myType;})) !== undefined;
  }
    
    /**
   * Compares the order of two cadasters. Function to be used when sorting cadasters.
   * @param cadNr1 Cadaster number 1 as string.
   * @param cadNr2 Cadaster number 1 as string.
   * @returns 1 if cadNr1 comes before cadNr2 in order, 0 if they are equal, -1 otherwise.
   */
     static compareCadasterOrder(cadNr1: string, cadNr2: string): number {

        // Start digit comparison
        let digitPattern = /^[0-9]+/g;
        let letterPattern = /[a-zA-ZæøåÆØÅ]/g;
    
        let matchDigit1: any = cadNr1.match(digitPattern);
        let matchDigit2: any = cadNr2.match(digitPattern);
    
        if (matchDigit1 === null) { matchDigit1 = []; }
        if (matchDigit2 === null) { matchDigit2 = []; }
    
        // MatchDigits are now lists
        // tslint:disable-next-line: radix
        let digitsCad1 = parseInt(matchDigit1.join(''));
        // tslint:disable-next-line: radix
        let digitsCad2 = parseInt(matchDigit2.join(''));
    
        // Compare cadaster digits
        if (digitsCad1 > digitsCad2) {
          return 1;
        } else if (digitsCad1 < digitsCad2) {
          return -1;
        } else {
    
          // If digits are equal, start comparing letters
          let matchLetter1: any = cadNr1.match(letterPattern);
          let matchLetter2: any = cadNr2.match(letterPattern);
    
          if (matchLetter1 === null) { matchLetter1 = []; }
          if (matchLetter2 === null) { matchLetter2 = []; }
    
          let lettersCad1 = matchLetter1.join('');
          let lettersCad2 = matchLetter2.join('');
    
          if (lettersCad1 > lettersCad2) {
            return 1;
          } else if (lettersCad1 < lettersCad2) {
            return -1;
          } else {
            return 0;
          }
        }
      }
    
      /**
       * Creates a ColorRange from the colors used in a DataDivision
       */
      static extractColorRangeFromDivision(divisions:DataDivisionList):ColorRange {
        let colors:ColorList=[];
        divisions.list.forEach((obj,idx) => {
            console.assert(obj.color,"Expected color");
            colors.push(obj.color!);
        });
        let result:ColorRange = {
            name:"Constructed from custom divisions",
            order:1,
            colors:colors,
            isCustom:true,
        };
        return result;
      }
      
}