import {MapitServicesInterface} from './MapitServicesInterface';
import {Utils} from './Utils';
import { AccessManager } from './AccessManager';
import { UserSession } from "./Types";
import {Logger} from './Logger';
import { MapitUtils } from './MapitUtils';

// Todo: find the definition and decide where it makes sense to be defined
export type WhitelistedUserSettings = any;

export class SettingsManager {
    private static _mitSettings = null;
    private static _mitCustomerSettings = null;
    private static _mitEnvironmentSettings = null;
    private static _mitCustomUserSettings: any;

    private static _loadedCustomerSettingsFromServer:boolean=false;
    private static _loadedCustomUserSettingsFromServer:boolean=false;
    private static _userSession:UserSession;
  
    static async getWhitelistedUserSettings(): Promise<WhitelistedUserSettings> {
    let serviceHost = SettingsManager.getSystemSettingByEnvironment("mapitServicesHost");
    let configBucket = SettingsManager.getSystemSettingByEnvironment("mapitS3Bucket");
    let apiKey = SettingsManager.getSystemSettingByEnvironment("mapitServicesAPIKey");
    let whitelistedUserSettings = await MapitServicesInterface.getJSONFromS3(serviceHost, apiKey, configBucket, "customerConfig/whitelistedSettings.json");
    if (whitelistedUserSettings !== undefined) {
      return whitelistedUserSettings;
    } else {
      MapitUtils.dispatchToErrorHandler("Get whitelisted settings from server failed");
      return JSON.parse("{}");
    }
  }

    static initialize(mitSettings:any, mitCustomerSettings:any, mitEnvironmentSettings:any) {
        SettingsManager._mitSettings = mitSettings;
        SettingsManager._mitCustomerSettings = mitCustomerSettings;
        SettingsManager._mitEnvironmentSettings = mitEnvironmentSettings;
    }

    static setSessionInfo(us:UserSession) {
        this._userSession = us;
    }

    static async InitializeConfigurationSettings() {
        try {
          let getFromServer = SettingsManager.getSystemSettingByEnvironment("loadConfigurationsFromServer", false);
          if (getFromServer && !this._loadedCustomerSettingsFromServer) {
            let serviceHost = SettingsManager.getSystemSettingByEnvironment("mapitServicesHost");
            let configBucket = SettingsManager.getSystemSettingByEnvironment("mapitS3Bucket");
            let apiKey = SettingsManager.getSystemSettingByEnvironment("mapitServicesAPIKey");
            let configData = await MapitServicesInterface.getJSONFromS3(serviceHost, apiKey, configBucket, "configuration/mit-customer-settings.json");
            if (configData) {
              this._mitCustomerSettings = configData;
              this._loadedCustomerSettingsFromServer = true;
            } else {
              MapitUtils.dispatchToErrorHandler("Get customer settings from server failed");
            }
          }
        } catch (error) {
          MapitUtils.dispatchToErrorHandler("Get customer settings from server failed "+error.message);
        }
      }
    
      static async getCustomUserSettings(userEmail: string) {
        if (!this._loadedCustomUserSettingsFromServer) {
          let serviceHost = SettingsManager.getSystemSettingByEnvironment("mapitServicesHost");
          let configBucket = SettingsManager.getSystemSettingByEnvironment("mapitS3Bucket");
          let apiKey = SettingsManager.getSystemSettingByEnvironment("mapitServicesAPIKey");
          let domain = AccessManager.getUserDomain(userEmail);
          let path = "customerConfig/" + domain + "/mit-custom-user-settings.json";
          let customUserSettings = await MapitServicesInterface.getJSONFromS3(serviceHost, apiKey, configBucket, path);
          if (customUserSettings !== undefined) {
            this._mitCustomUserSettings = customUserSettings;
            this._loadedCustomUserSettingsFromServer = true;
          } else {
            MapitUtils.dispatchToErrorHandler("Get custom settings for " + userEmail + " from server failed");
          }
        }
        return this._mitCustomUserSettings;
      }
    
      
  static setCustomUserSettingsLocally(settings: {[key: string]: any}) {
    this._mitCustomUserSettings = settings;
  }

  // ------------------------------------ system settings ---------------------------------------

  static getCustomerProfileByEmailOrDomain(email:string) {
    // first check if there is a profile for the user (email)
    let profile = email && this.getCustomerProfile(email);
    if (!profile) {
      // if not successful check if there is a profile for the customer (email domain)
      let splitEmail = email && email.split('@');
      let domainName = splitEmail && (splitEmail.length > 1 ? splitEmail[1] : null);
      profile = domainName && this.getCustomerProfile(domainName);
    }
    return profile;
  }

  static getCustomerProfile(profileCode:string) {
    let key = profileCode && profileCode.toLowerCase();
    return key && SettingsManager._mitCustomerSettings && SettingsManager._mitCustomerSettings[key];
  }

  private static getSystemSettingByCustomer(us:UserSession, tag:string, defaultValue?:any):any {
    let result = SettingsManager._getSystemSettingByCustomer(us, tag, defaultValue);
    if (result === undefined) {
      result = SettingsManager._getSystemSettingByEnvironment(tag, defaultValue);
    }
    if (result===undefined) { 
      result = this._getSystemSetting(tag, defaultValue); 
    }
    return result;
  }

  private static _getSystemSettingByCustomer(us:UserSession, tag:string, defaultValue?:any):any {
    let result;
    let profile = us && us.email && this.getCustomerProfileByEmailOrDomain(us.email);
    if (profile) {
      result = profile[tag];
    }
    return result;
  }

  static getEnvironmentProfile(deploymentModeStr:string) {
    return deploymentModeStr && SettingsManager._mitEnvironmentSettings && SettingsManager._mitEnvironmentSettings[deploymentModeStr];
  }

  private static getSystemSettingByEnvironment(tag:string, defaultValue?:any):any {
    let result;
    result = SettingsManager._getSystemSettingByEnvironment(tag, defaultValue);
    if (result===undefined) { 
      result = this._getSystemSetting(tag, defaultValue); 
    }
    return result;
  }

  private static _getSystemSettingByEnvironment(tag:string, defaultValue?:any):any {
    let env = Utils.getDeploymentMode();
    let result;
    let settings = env && this.getEnvironmentProfile(env.toString());
    if (settings) {
      result = settings[tag];
    }
    return result;
  }

  static getSystemSetting(tag:string, defaultValue?:any):any {
    let result;
    if (SettingsManager._userSession) {
      result = SettingsManager._getCustomSystemSettingByUser(tag);
    }
    if (result === undefined) {
      result = SettingsManager._getSystemSettingByCustomer(SettingsManager._userSession, tag, defaultValue);
    }
    if (result === undefined) {
      result = SettingsManager._getSystemSettingByEnvironment(tag, defaultValue);
    }
    if (result === undefined) {
      result = SettingsManager._getSystemSetting(tag, defaultValue);
    }
    return result;
  }

  private static _getSystemSetting(tag:string, defaultValue?:any):any {
    let result;
    result = SettingsManager._mitSettings && SettingsManager._mitSettings[tag];
    if (result===undefined) {
      if (defaultValue || defaultValue === false) { 
        result = defaultValue; 
        let warning = Utils.formatString("System setting '{tag}' not found. Using default: {default}", {tag:tag, default:defaultValue});
              
        Logger.logWarning("Utils","getSystemSetting",warning);
      } else {
        let error = Utils.formatString("System setting '{tag}' not found. No default provided", {tag:tag});
        Logger.logError("Utils","getSystemSetting",error);
      }
    }
    return result;
  }

  private static _getCustomSystemSettingByUser(tag: string) {
    return this._mitCustomUserSettings && (this._mitCustomUserSettings[tag] !== undefined) ? this._mitCustomUserSettings[tag] : undefined;
  }

}