import { FunctionComponent, useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { Cookies } from '../managers/Cookies';
import { SimpleTranslationTable } from '../managers/Localization';
import { Localization } from '../managers/Localization';
import { SettingsManager} from '../managers/SettingsManager';
import { Utils } from '../managers/Utils';
import * as React from 'react';

export type EnvironmentConfiguration = {
   contentsOfPackageJson: { [key: string]: any };
   globalSettings: { [key: string]: any };
   customerSettings: { [key: string]: any };
   environmentSettings: { [key: string]: any };
   translationTables: SimpleTranslationTable[];
}
type Props = {
   config: EnvironmentConfiguration,
   children: any
}

function combineTranslationTables(tables: SimpleTranslationTable[]): SimpleTranslationTable {
   function addTranslation(lang:string, key:string, translation:string) {
      if (!result[lang]) {
         result[lang]={};
      }
      if (result[lang][key]) {
         throw new Error(`${lang} ${key} translation is duplicated`)
      }
      result[lang][key]=translation;
   }

   let result: SimpleTranslationTable = {};
   tables.forEach((table) => {
      Object.keys(table).forEach((lang) => {
         Object.keys(table[lang]).forEach((key) => {
            let translation=table[lang][key];
            addTranslation(lang, key, translation);
         });
      });
   });
   return result;
}

export const EnvironmentLoader: FunctionComponent<Props> = (props) => {
   let config = props.config;
   let [initialized, setInitialized] = useState<boolean>(false);

   useEffect(() => {
      try {
         let environment: string = process.env.NODE_ENV || "";
         const pj = config.contentsOfPackageJson;
         const globalSettings = config.globalSettings;
         const customerSettings = config.customerSettings;
         const environmentSettings = config.environmentSettings;
         Utils.initialize({ name: props.config.contentsOfPackageJson.name, version: props.config.contentsOfPackageJson.version });
         SettingsManager.initialize(globalSettings, customerSettings, environmentSettings);
         // Logger.initialize();
         // Set default language - if any
         var defaultLanguage = Cookies.getCookie("DefaultLanguage") || SettingsManager.getSystemSetting("defaultLanguage", "da")
         let translationTable = combineTranslationTables(config.translationTables);
         Localization.initialize(translationTable, defaultLanguage, (missingTextMessage: string) => {
            // NOTE. Logger is not functional!!
            // Logger.logWarning("Localization", "getText", missingTextMessage);
            console.log("Missing text warning", missingTextMessage);
         });
         setInitialized(true);
      } catch (error) {
         throw new Error("Got error initializing" + error);
      }
   }, []);

   if (!initialized) {
      return (
         <Alert>Loading...</Alert>
      );
   }
   return (
      <>{props.children}</>
   );
};