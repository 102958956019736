import { useReducer } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import queryString from 'query-string';
import Hvorlangterder from './hvorlangterder/Hvorlangterder';
import { Image } from 'react-bootstrap';
import { AppMessagesBoundary } from '@viamap/viamap2-common';
import { AuthenticationBoundary } from '@viamap/viamap2-common';
import { ErrorBoundary } from '@viamap/viamap2-common';
import { LicensingBoundary } from '@viamap/viamap2-common';
import { MainApplicationFrame } from '@viamap/viamap2-common';
import { GenericTransactionManager } from '@viamap/viamap2-common';
import { AppMessagesReducer, initialAppMessagesState, AppMessagesContext, AppMessagesActions, transactionalAppMessagesReducer } from '@viamap/viamap2-common';
import { ViamapLicensingReducer, initialViamapLicensingState, LicensingContext, ViamapLicensingActions, transactionalViamapLicensingReducer, ViamapProduct } from '@viamap/viamap2-common';
import { SessionActions } from '@viamap/viamap2-common';
import { SessionContext } from '@viamap/viamap2-common';
import { sessionReducer } from '@viamap/viamap2-common';
import { transactionalSessionReducer } from '@viamap/viamap2-common';
import { initialSessionState } from '@viamap/viamap2-common';
import { EnvironmentConfiguration, EnvironmentLoader } from '@viamap/viamap2-common';

function App() {

  let params = queryString.parse(window.location.search);
  let hash = queryString.parse(window.location.hash);

  // Routing
  if (params) {
    if (params.signup) {
      // Route app to Signup PAge
      // ToDo.
    }
    if (params.language) {
      // Set initial languagecode.
    }
  }

  if (hash) {
    // Hash contains an address to parse.
    // ToDo.
  }

  const [sessionState, sessionDispatch] = useReducer(sessionReducer, initialSessionState);
  const [licencingState, licencingDispatch] = useReducer(ViamapLicensingReducer, initialViamapLicensingState());
  const [appMessagesState, appMessagesDispatch] = useReducer(AppMessagesReducer, initialAppMessagesState());

  const econfig: EnvironmentConfiguration = {
    contentsOfPackageJson: require('../package.json'),
    globalSettings: require('./template-settings.json'),
    customerSettings: {},
    environmentSettings: require('./template-environment-settings.json'),
    translationTables: [
      require('./localization/translationLogin.json'),
      require('./localization/translationLicensing.json'),
      require('./localization/translationFeatures.json'),
      require('./hvorlangterder/translation.json')
    ]
  };

  // Globals
  const requiredAccessRole = undefined;


  const ProductTeaser =  (
      <>
      <h3>Hvorlangt er der</h3>
      <div style={{display:"flex",justifyContent:"center"}}>
      <div style={{maxWidth:"625px", textAlign:"left"}}>
        På hvorlangterder.dk kan du finde vej til nærmeste interessepunkt. <br/>
        I den gratis prøveperiode får du adgang til alt. <br/>
        Hvis du vil fortsætte efter de 14 dage, kan du vælge mellem BASIS og PRO – læs mere om forskellen her, og find ud af, hvad der passer til dig og dit behov.<br/>
      </div>
      </div>
      <Image src={"HvorlangtTeaserNoDistance.png"} style={{padding:"10px", backgroundColor:"white"}} />
      </>
    );

  return (
    <div className="App">

      <ErrorBoundary>
        <AppMessagesContext.Provider
          value={{
            state: appMessagesState,
            dispatch: GenericTransactionManager.dispatchMiddleware<AppMessagesActions>(appMessagesDispatch, transactionalAppMessagesReducer)
          }}
        >
          <AppMessagesBoundary>
            <EnvironmentLoader config={econfig}>
              <SessionContext.Provider value={{ state: sessionState, dispatch: GenericTransactionManager.dispatchMiddleware<SessionActions>(sessionDispatch, transactionalSessionReducer) }}>
                <AuthenticationBoundary requiredAccessRole={requiredAccessRole} splashImagePath={"LoginSplashHvorLangt.png"} background={<img src='BGIMG.png' alt={"Meaning Full text"} />}>
                  <LicensingContext.Provider
                    value={{
                      state: licencingState,
                      dispatch: GenericTransactionManager.dispatchMiddleware<ViamapLicensingActions>(licencingDispatch, transactionalViamapLicensingReducer)
                    }}
                  >
                    <LicensingBoundary product={ViamapProduct.HvorLangtErDer} productTeaser={ProductTeaser}>
                      <MainApplicationFrame
                        applicationName='Hvorlangterder'
                        productNameAndVersion='hvorlangterder-v1.0.5'
                        releaseInformation={require('./mit-help-new-features.json')}
                        newUserInformation={require('./mit-help-new-user.json')}
                      >
                        <Hvorlangterder />
                      </MainApplicationFrame>
                    </LicensingBoundary>
                  </LicensingContext.Provider>
                </AuthenticationBoundary>
              </SessionContext.Provider>
            </EnvironmentLoader>
          </AppMessagesBoundary>
        </AppMessagesContext.Provider>
      </ErrorBoundary>
      
    </div>
  );
}

export default App;
