import { useEffect, useRef, useState } from 'react';
import { DawaQuality } from '../managers/AddressInterface';
import { PoiManager } from '../managers/PoiManager';
import { ViamapApi } from '../managers/ViamapApiManager';
import { TbGps } from 'react-icons/tb';
import { IoClose } from 'react-icons/io5'
import { Localization } from '@viamap/viamap2-common';



export type GeoSimple = {
  quality: DawaQuality
  lat: number,
  lng: number,
}


export function SearchBar2(dispatcher: React.Dispatch<GeoSimple>) {
  let [value, setValue] = useState("");
  let DawaRef = useRef(undefined as any);

    useEffect(() => {
        initDawaSearchbar();
        if (window.location.hash) {
          fetch(`https://dawa.aws.dk/autocomplete?q=${window.location.hash.replace("#","")}`).then((response) => {
            return response.json()
          }).then((body) => {
            if (body.length > 0) {
              callbackOnSelectedAddress(body[0])
            }
          })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , []);

    function geoToSimpleReturn(geo:GeolocationPosition):GeoSimple {
      return ({
        quality: DawaQuality.dawaMatched,
        lat: geo.coords.latitude,
        lng: geo.coords.longitude
      })
    }

    function dawaToSimpleReturn(dawa:any):GeoSimple {
      if (dawa.data && dawa.data.x && dawa.data.y && dawa.data.darstatus) {
        return ({
          quality: dawa.data.darstatus,
          lat: dawa.data.y,
          lng: dawa.data.x
        })
      }
      return errorToSimpleReturn()
    }

    function errorToSimpleReturn():GeoSimple {
      setValue("Error")
      return ({
        quality: DawaQuality.dawaError,
        lat: 0,
        lng: 0,
      })
    }

    function handleUseLocationBtn() {
      navigator.geolocation.getCurrentPosition(
        (pos:GeolocationPosition) => {
          let simple = geoToSimpleReturn(pos)
          dispatcher(simple);
          ViamapApi.callReverseGeoCode(simple.lat,simple.lng).then((succes) => {
            let result = succes.result;
            window.location.hash = `${result.roadname} ${result.houseno}, ${result.postalcode} ${result.postalname}`;
            setValue(`${result.roadname} ${result.houseno}, ${result.postalcode} ${result.postalname}`)
            PoiManager.instance().callNearest(simple.lat,simple.lng);
          })
        },
        () => dispatcher(errorToSimpleReturn())
      )
    }

    function initDawaSearchbar() {
      let dawaAutocomplete2 = require('dawa-autocomplete2');
      let inputElement = document.getElementById('dawa-autocomplete-input');
      // if (inputElement === undefined) {
      //   return;
      // }
      dawaAutocomplete2.dawaAutocomplete(inputElement, {
          select: (selected: any) => {
              callbackOnSelectedAddress(selected);
          },
          adgangsadresserOnly: true,
      });
    }

    async function callbackOnSelectedAddress(address: any): Promise<void> {
      setValue(address.tekst);
      if (address === "Error") {
        PoiManager.instance().callNearest(0,0);
        return
      }
      let searchResult = address
      let simple = dawaToSimpleReturn(searchResult);
      if (simple.lat === 0) {
        return
      }
      window.location.hash = address.tekst;
      dispatcher(simple);
      PoiManager.instance().callNearest(simple.lat,simple.lng);
    }

    function handleClearBtn() {
      setValue("");
      DawaRef.current.focus();
      window.location.hash = ""
      // PoiManager.instance().callNearest(0,0);
    }

    function Render():any {
        return (
            <div id="mit-searchbar-wrapper">
                <div className="autocomplete-container">
                    <button onClick={handleClearBtn} className="SB2_Btn clear_btn bi bi-x-circle" style={{opacity: value? 1:0}} title={Localization.getText("Clear input field")} ><IoClose /></button>
                    <button onClick={handleUseLocationBtn} className="SB2_Btn uselocation_btn bi bi-geo-alt" title={Localization.getText("Lookup current nearest address")} ><TbGps /></button>
                    <input ref={DawaRef} onChange={e => setValue(e.target.value)} placeholder={Localization.getText("Search Address")} id="dawa-autocomplete-input" value={value} />
                </div>
            </div>
        );
    }
    return Render();
}


