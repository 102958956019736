import { Chance } from "chance";
import { Utils } from "./Utils";

export enum PersistenceScope {
  User = "User",
  Customer = "Customer",
  Internal = "Internal",
}

export enum PersistenceObjectType {
  Icon = "Icon",
  Image = "Image",
  License = "License",
  ColorRange = "ColorRange",
  SavedMaps = "SavedMaps",
  CustomLayers = "CustomLayers",
  Template = "Template",
  Settings = "Settings",
}

const ObjectTypeToFolder = {
  [PersistenceObjectType.Icon]: "icons",
  [PersistenceObjectType.Image]: "images",
  [PersistenceObjectType.License]: "licenses",
  [PersistenceObjectType.ColorRange]: "colorRanges",
  [PersistenceObjectType.SavedMaps]: "savedMaps",
  [PersistenceObjectType.CustomLayers]: "customer-settings",
  [PersistenceObjectType.Template]: "templates",
  [PersistenceObjectType.Settings]: "settings",
}

const ScopeTypeToFolder = {
  [PersistenceScope.Customer]: "customerConfig/{customer}/{objectTypeFolder}",
  [PersistenceScope.Internal]: "systemConfig/{objectTypeFolder}",
  [PersistenceScope.User]: "customerConfig/{customer}/{user}/{objectTypeFolder}",
}

/**
 * Full email of the user
 * */
export type UserRef = string;

/**
 * Domain of the user's email
 * */
export type CustomerRef = string;

/**
 * Name of the associated object (refers to its filename).
 */
export type ObjectRef = string;

export class ViamapPersistenceLayer {

  private bucket: string;
  private s3: AWS.S3;

  constructor(bucket: string, s3: AWS.S3) {
    this.bucket = bucket;
    this.s3 = s3;
  }

  public extractCustomerRef(userRef: UserRef): CustomerRef | undefined {
    if (userRef) {
      let splitEmail = userRef.split('@');
      let domainName = (splitEmail.length > 1) && splitEmail[1];
      return domainName ? domainName : undefined;
    } else {
      return undefined;
    }
  }

  public createPrefix(scope: PersistenceScope, objectTypeFolder: PersistenceObjectType, customer?: CustomerRef, user?: UserRef): string {
    return Utils.formatString(ScopeTypeToFolder[scope], { customer, user, objectTypeFolder: ObjectTypeToFolder[objectTypeFolder] });
  }

  public getListing(scope: PersistenceScope, objectType: PersistenceObjectType, customerRef: CustomerRef, userRef: UserRef): Promise<any> {
    let prefix = this.createPrefix(scope, objectType, customerRef, userRef);
    return this.getListingS3(this.s3, prefix);
  }

  /**
   * Optional parameter extension, excluding the period.
   */
  public generateUniqueObjectRef(extension?: string): string {
    return new Chance().guid() + (extension ? "." + extension : "");
  }

  public async getObject(scope: PersistenceScope, objectType: PersistenceObjectType, customerRef: CustomerRef, userRef: UserRef,
    objectRef: ObjectRef) {

    return this.getObjectS3(this.createPrefix(scope, objectType, customerRef, userRef) + "/" + objectRef);
  }

  public async headObject(scope: PersistenceScope, objectType: PersistenceObjectType, customerRef: CustomerRef, userRef: UserRef,
    objectRef: ObjectRef) {

    return this.headObjectS3(this.createPrefix(scope, objectType, customerRef, userRef) + "/" + objectRef);
  }

  public async uploadObject(scope: PersistenceScope, objectType: PersistenceObjectType, customerRef: CustomerRef, userRef: UserRef,
    data: any, objectRef?: ObjectRef, metadata?: { [key: string]: string }, makePublic: boolean = false) {

    objectRef = objectRef ? objectRef : this.generateUniqueObjectRef();
    let prefix = this.createPrefix(scope, objectType, customerRef, userRef) + "/" + objectRef;
    return this.uploadObjectS3(prefix, data, metadata, makePublic);
  }

  public async deleteObject(scope: PersistenceScope, objectType: PersistenceObjectType, customerRef: CustomerRef, userRef: UserRef,
    objectRef: ObjectRef) {
    let prefix = this.createPrefix(scope, objectType, customerRef, userRef) + "/" + objectRef;
    return this.deleteObjectS3(prefix);
  }


  public getListingS3(s3: AWS.S3, prefix: string) {
    return new Promise((resolve, reject) => {
      try {
        let params = {
          Bucket: this.bucket,
          MaxKeys: 1000,
          Prefix: prefix,
          Delimiter: prefix,
          ContinuationToken: undefined
        };
        const allContent: any[] = [];
        listAllKeys();
        function listAllKeys() {
          s3.listObjectsV2(params, function (err: any, data: any) {
            if (err) {
              reject(err)
            } else {
              var contents = data.Contents;
              contents.length && contents[0].Key.endsWith("/") && contents.shift();
              contents.forEach(function (content: any) {
                allContent.push(content);
              });

              if (data.IsTruncated) {
                params.ContinuationToken = data.NextContinuationToken;
                console.log("get further list...");
                listAllKeys();
              } else {
                resolve(allContent);
              }
            }
          });
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  public async getObjectS3(key: string): Promise<any> {
    var params = {
      Bucket: this.bucket,
      Key: key,
    };
    return new Promise<any>((resolve, reject) => {
      this.s3.getObject(params, (err, response) => {
        if (err) {
          reject("Could not read: " + err);
        } else {
          response.Metadata && Object.keys(response.Metadata).forEach(key => {
            response.Metadata![key] = decodeURIComponent(response.Metadata![key]);
          });
          let result2 = {
            Body: response.Body as Buffer,
            Metadata: response.Metadata,
            ContentType: response.ContentType,
            LastModified: response.LastModified
          };
          resolve(result2);
        }
      });
    });
  }

  public async headObjectS3(key: string): Promise<any> {
    var params = {
      Bucket: this.bucket,
      Key: key,
    };
    return new Promise<any>((resolve, reject) => {
      this.s3.headObject(params, (err, response) => {
        if (err) {
          reject("Could not read: " + err);
        }
        resolve(response);
      });
    });
  }

  public async uploadObjectS3(key: string, data: any, metadata?: { [key: string]: string }, makePublic: boolean = false) {
    var params: AWS.S3.PutObjectRequest = {
      Bucket: this.bucket,
      Key: key,
      Body: data,
      Metadata: {
        ...metadata,
        timestamp: Date.now().toString(),
      }
    };
    if (makePublic) {
      params.ACL = "public-read";
    }

    const fileNameSplit = key.split(".");
    const fileExtension = fileNameSplit.length > 1 ? fileNameSplit[fileNameSplit.length - 1] : "";
    let contentType = data.type || this.mapExtensionToContentType(fileExtension);

    if (contentType) {
      params.ContentType = contentType;
    }

    return new Promise<{ data: {}, timestamp: any }>((resolve, reject) => {
      this.s3.upload(params, (err: any, response: any) => {
        if (err) {
          reject("Could not write " + key + " err:" + err);
        } else {
          resolve(response);
        }
      });
    });
  }

  public async deleteObjectS3(key: string): Promise<AWS.S3.DeleteObjectOutput> {
    var params: AWS.S3.DeleteObjectRequest = {
      Bucket: this.bucket,
      Key: key,
    };
    return new Promise((resolve, reject) => {
      this.s3.deleteObject(params, (err, data) => {
        if (err) {
          reject(err);
        }
        resolve(data);
      });
    });
  }

  private mapExtensionToContentType(extension: string) {
    switch (extension) {
      case "png":
        return "image/png";
      case "jpg":
        return "image/jpeg";
      case "jpeg":
        return "image/jpeg";
      case "gif":
        return "image/gif";
      case "bmp":
        return "image/bmp";
      case "tiff":
        return "image/tiff";
      case "tif":
        return "image/tiff";
      case "svg":
        return "image/svg+xml";
      case "xml":
        return "image/svg+xml";
      case "txt":
        return "text/plain";
      case "html":
        return "text/html";
      default:
        return undefined;
    }
  }
}
