import { useContext } from "react";
import { Button } from "react-bootstrap";
import { actionSetLanguage } from "../states/sessionState/actions";
import { SessionContext } from "../states/sessionState/context";

import * as React from 'react';

export const LanguageSelector = (props:any) => {
   let { state: sessionState, dispatch: sessionDispatch } = useContext(SessionContext);

   function handleLanguage(language: string) {
      sessionDispatch(actionSetLanguage(language));
   }

   // Todo: move to Localization common function
   function getLanguages():any {
      return {
         'da':'Dansk',
         'en':'English'
      }
   }

   var languageControls = (
      <div className="language-control" style={props.style}>
         {Object.keys(getLanguages()).map((key) => {
            return (
               <Button key={key} size="sm" onClick={() => handleLanguage(key)} style={{ float: "left", border:"2px solid white", borderRadius:"20px" }} active={sessionState.languageCode !== key} variant={sessionState.languageCode !== key ? "secondary" : "primary"}>{key}</Button>
            );
         })}
      </div>
   );

   return languageControls;
}