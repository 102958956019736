import {Utils} from './Utils';

import {UserSession} from './Types';
import { AccessManager } from './AccessManager';
import {AWSCloudWatchLogger} from './ASWCloudWatchLogger';
import { SettingsManager } from './SettingsManager';

export class Logger {

    static logError(location:string, context:string, message:any) {
        Logger._logger("MIT ERROR", location, context, message);
    }

    static logInfo(location:string, context:string, message:any) {
        Logger._logger("MIT info", location, context, message);
    }

    static logWarning(location:string, context:string, message:any) {
        Logger._logger("MIT warning", location, context, message);
    }

    static logAction(location:string, context:string, message:any) {
        let doActionLogs = SettingsManager.getSystemSetting("doLogsOfActions", true);
        if (doActionLogs) {
            Logger._logger("MIT action", location, context, message);
        }
    }

    static logLegalAction(location:string, context:string, message:any) {
        // Todo: This should to to a different log stream - to separate from other messages.
        let doActionLogs = SettingsManager.getSystemSetting("doLogsOfActions", true);
        if (doActionLogs) {
            Logger._logger("MIT legal action", location, context, message);
        }
    }

    static _logger(type:string, location:string, context:string, message:any) {
        let us:UserSession|null= null; 
        try {
            us = AccessManager.getUserSession();
        } catch (e) {
            if (Utils.isTestBuild() || Utils.isDevelopmentBuild()) {
                // User does not have a session. Ignore during unit test
            } else {
                throw Utils.createErrorEventObject(e.message);
            }
        }
        let logObj = {
            type: type, 
            user: us && us.name || "unknown",
            userId: us && us.userName || "unknown",  
            location:location, 
            context:context, 
            message:message};
        Logger._log(JSON.stringify(logObj));
    }

    static _log(msg:string) {
        if (Utils.isDevelopmentBuild() || Utils.isTestBuild()) {
            /* tslint:disable-next-line */
            console.log(msg);
        } else {
            AWSCloudWatchLogger.logInAWS(msg);
        }
    }

}

