import { useContext, useEffect } from "react";
import { actionClearErrorMessage, actionClearInfoMessage, AppMessagesContext } from "../states/AppMessagesState";
import { ErrorMessage } from "./ErrorMessage";
import { InfoMessage } from "./InfoMessage";
import { LoadingScreen } from "./LoadingScreen";
import * as React from "react";

type Props = {
   children: any
}

export function AppMessagesBoundary(props:Props) {
   let { state: appMessagesState, dispatch: appMessagesDispatch} = useContext(AppMessagesContext);
   
   return (
      <>
         { props.children }
         <LoadingScreen
            message={appMessagesState.progressMessage}
            percentComplete={appMessagesState.progressPercentComplete}
         />
         <ErrorMessage
            message={appMessagesState.errorMessage!}
            callbackOnDismiss={()=>appMessagesDispatch(actionClearErrorMessage())} 
         />
         <InfoMessage
            message={appMessagesState.infoMessage!}
            callbackOnDismiss={()=>appMessagesDispatch(actionClearInfoMessage())} 
         />
      </>
   );
}