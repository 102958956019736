import {Utils} from '../managers/Utils';

export type SimpleTranslationTable = {[languageCode:string]:{[englishText:string]:string}};

export class Localization {
    private static _currentLanguage;
    private static _simpleTranslationTable:SimpleTranslationTable;
    private static _callbackOnMissingTextOrTranslation:(string)=>void;

    /**
     * Required before using the Localization Service
     * @param table SimpleTranslationTable
     * @param defaultLanguage Default language
     * @param callbackOnMissingTextOrTranslation Callback to receive an english warning message (to be logged). The same message will be returned as the translation. 
     */
    static initialize(table:SimpleTranslationTable, defaultLanguage:string, callbackOnMissingTextOrTranslation:(string)=>void) {
        this.setTranslationTable(table);
        defaultLanguage && this.setLanguage(defaultLanguage);
        this._callbackOnMissingTextOrTranslation = callbackOnMissingTextOrTranslation;
    }
    
    private static checkPreConditions() {
        if (this._currentLanguage && this._simpleTranslationTable) {
            return;
        } else {
            throw Utils.createErrorEventObject("Localization manager not properly initialized");
        }
    }

    static convertPluralToSingular(text: string): string {
        const simpleTranslationTable = {
            "Liste af ejendomsvurderinger": "Vurderingsår",
        };

        let a = simpleTranslationTable[text] ? simpleTranslationTable[text] : text;
        return a;
    }

    static setTranslationTable(simpleTranslationTable:SimpleTranslationTable) {
        this._simpleTranslationTable = simpleTranslationTable;
    }
    
    static setLanguage(language:string) {
        if (!this._simpleTranslationTable) {
            throw Utils.createErrorEventObject("Translationtable not set");
        }
        if (!this._simpleTranslationTable || !this._simpleTranslationTable[language]) {
            throw Utils.createErrorEventObject("Unsupported translation language:"+language);
        }
        this._currentLanguage = language;
    }

    /** @deprecated use getLanguage() */
    static getlanguage():string {
        return this.getLanguage();
    }

    static getLanguage():string {
        return this._currentLanguage;
    }

    static getText(englishText:string):string {
        this.checkPreConditions();
        return this.getTextSpecificLanguage(Localization._currentLanguage, englishText);
    }

    /**
     *  For use (in error handling) where a text must be returned even if Localization is not properly initialized
     * @param englishText 
     * @returns 
     */
    static getTextSafeMode(englishText:string):string {
        try { 
            this.checkPreConditions();
        } catch (error) {
            return englishText;
        }
        return this.getTextSpecificLanguage(Localization._currentLanguage, englishText);
    }

    static getTextSpecificTable(englishText:string, translationTable:SimpleTranslationTable):string {
        this.checkPreConditions();
        return this.getTextSpecificLanguageAndTable(Localization._currentLanguage, englishText, translationTable);
    }

    /**
     * Translates a text and expands any placeholders
     * Utils.formatString is used for the expansion
     * @param englishText The text to be translated
     * @param values An object of form {placeholder:value, ...}
     * @returns The translated and expanded string
     */
    static getFormattedText(englishText:string, values:object):string {
        let x:string=this.getText(englishText);
        return Utils.formatString(x,values);
    }

    static getTextSpecificLanguage(languageCode:string, englishText:string):string {
        this.checkPreConditions();
        return this.getTextSpecificLanguageAndTable(languageCode, englishText, this._simpleTranslationTable);
    }

    static getTextSpecificLanguageAndTable(languageCode:string, englishText:string, translationTable:SimpleTranslationTable):string {
        // Comment: No need to check precondition here. All needed parameters are supplied.
 
        if (!translationTable[languageCode]) {
            throw Utils.createErrorEventObject("Unsupported translation language:"+languageCode);
        } 
        var result = translationTable[languageCode][englishText];
        if (!result) {
            result = Utils.formatString("No translation found for ({text})", {text:englishText});
            this._callbackOnMissingTextOrTranslation && this._callbackOnMissingTextOrTranslation(result);            
        } 
        return result;
    }

}