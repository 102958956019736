import { SettingsManager} from './SettingsManager';

import { ViamapLicenseRegistry, ViamapProduct, LicenseType, ViamapLicense } from "../states/ViamapLicensingState";
import { MapitServicesInterface } from "./MapitServicesInterface";


export class LicenseManager {

   private load():ViamapLicenseRegistry {
      // Get from Cognito
      return [      ];
   }

   // static makeS3Path(userEmail: string) : string {

   // }

   static async getUserLicenses(userEmail: string):Promise<any> {
      try {
         let serviceHost = SettingsManager.getSystemSetting("mapitServicesHost");
         let configBucket = SettingsManager.getSystemSetting("viamapStoreS3Bucket");
         let apiKey = SettingsManager.getSystemSetting("mapitServicesAPIKey");
         let path = "userConfig/" + userEmail + "/licenses.json";
         let data = await MapitServicesInterface.getJSONFromS3(serviceHost, apiKey, configBucket, path);
         if (data) {
            return data;
         } else {
            throw new Error("Get customer licenses from server failed");
         }
       } catch (error:any) {
         throw new Error("Get customer license from server failed "+ (error.message || error));
       }

    }

   static async persistUserLicenses(userEmail: string, data:any):Promise<void> {
   try {
         let serviceHost = SettingsManager.getSystemSetting("mapitServicesHost");
         let configBucket = SettingsManager.getSystemSetting("viamapStoreS3Bucket");
         let apiKey = SettingsManager.getSystemSetting("mapitServicesAPIKey");
         let path = "userConfig/" + userEmail + "/licenses.json";
         await MapitServicesInterface.putJSONToS3(serviceHost, apiKey, configBucket, path, data);
      } catch (error:any) {
         throw new Error("Write customer license to server failed "+ (error.message || error));
      }
   }

   private persist() {
      // Store as custom variable in Cognito      
   }

   static extractCurrentLicense(product:ViamapProduct, data:ViamapLicenseRegistry):ViamapLicense {
      this.validateRegistry(data);
      return data.filter((l) => l.active && l.product === product)[0];
   }

   static validateLicense(lic:ViamapLicense) {
      if (
         typeof lic === 'object' &&
         !Array.isArray(lic) &&
         lic !== null
      ) {
         // todo:check for license parameters
      } else {
         throw new Error("is not object");
      }
   }

   static validateRegistry(data:ViamapLicenseRegistry) {
      if (!Array.isArray(data)) {
         throw new Error("is not array");
      }
      if (data.length > 0) {
         data.forEach((lic) => {this.validateLicense(lic);});
      }
   }

   // todo: split parameter into individual parts
   putLicense(license:ViamapLicense) {
   }

}